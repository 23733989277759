import { grey2, grey4 } from "../../../styles/theme";
import { IconProps } from "./";

export const CheckIcon = ({ size = 16, isChecked = false }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="16"
        height="16"
        rx="8"
        fill={isChecked ? "#3DC400" : grey2}
      />
      {isChecked && (
        <path
          d="M11.75 5C8 7.25 7.625 10.4375 6.875 11C6.125 11.5625 5 9.125 4.25 8"
          stroke={isChecked ? "white" : grey4}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      )}
    </svg>
  );
};
