import { Skeleton } from "@mui/material";
import classNames from "classnames";
import useTranslation from "../../hooks/useTranslation";
import { SurveyChapterInsight } from "../../types/workplaceCulture";
import { InsightsSlide } from "../InsightsSlide/InsightsSlide";
import { mockInsightsWidgetData } from "../InsightsWidget/InsightsWidget.data";
import { NoDataState } from "../NoDataState/NoDataState";
import Carousel from "../ui/Carousel/Carousel";
import "./InsightsCarousel.scss";

interface InsightsCarouselProps {
  className?: string;
  insightsData?: SurveyChapterInsight[];
  error?: boolean;
  isLoading?: boolean;
  noInsights?: boolean;
  preview?: boolean;
  surveyClosed?: boolean;
  dispatchId: string;
}

export const InsightsCarousel = ({
  className,
  insightsData,
  error = false,
  isLoading = true,
  noInsights = false,
  preview = false,
  surveyClosed = false,
  dispatchId,
}: InsightsCarouselProps) => {
  const { t } = useTranslation("workplaceCulturePage");

  let insights = undefined;
  if (insightsData && !preview) {
    insights = insightsData;
  } else if (preview) {
    insights = mockInsightsWidgetData;
  }

  const renderErrorState = () => {
    let title = t("insights.noInsights.title");
    let info = t("insights.noInsights.error");

    if (noInsights && surveyClosed) {
      info = t("insights.noInsights.notEnoughData");
    } else if (noInsights && !surveyClosed) {
      info = t("insights.noInsights.notAvailable");
    }

    return <NoDataState title={title} info={info} />;
  };

  const classes = classNames("InsightsCarousel", className);
  return (
    <>
      {Array.isArray(insights) && !noInsights && (
        <div className={classes}>
          <Carousel>
            {insights.map((insight: SurveyChapterInsight) => (
              <InsightsSlide
                insight={insight}
                key={insight.id}
                needsTranslation={preview}
                dispatchId={dispatchId}
              />
            ))}
          </Carousel>
        </div>
      )}
      {isLoading && !preview && <Skeleton height={100} />}
      {!preview &&
        (error || !Array.isArray(insights) || noInsights) &&
        !isLoading &&
        renderErrorState()}
    </>
  );
};
