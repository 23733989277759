import useTranslation from "../../hooks/useTranslation";
import { RecommendationIllustration } from "../../styles/illustrations/RecommendationIllustration";
import { SurveyChapterInsight } from "../../types/workplaceCulture";
import { InsightsCarousel } from "../InsightsCarousel/InsightsCarousel";
import { DashboardWidget } from "../ui/DashboardWidget/DashboardWidget";

interface InsightsWidgetProps {
  insightsData?: SurveyChapterInsight[];
  insightsError?: boolean;
  insightsLoading?: boolean;
  noInsights?: boolean;
  preview?: boolean;
  surveyClosed?: boolean;
  dispatchId: string;
}

export const InsightsWidget = ({
  insightsData,
  insightsError: error = false,
  insightsLoading: isLoading = true,
  noInsights = false,
  preview = false,
  surveyClosed = false,
  dispatchId,
}: InsightsWidgetProps) => {
  const { t } = useTranslation("dashboardPage");
  return (
    <DashboardWidget
      title={t("insightsWidget.title")}
      icon={RecommendationIllustration}
      preview={preview}
    >
      <InsightsCarousel
        insightsData={insightsData}
        error={error}
        isLoading={isLoading}
        noInsights={noInsights}
        preview={preview}
        surveyClosed={surveyClosed}
        dispatchId={dispatchId}
      />
    </DashboardWidget>
  );
};
