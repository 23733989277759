import LanguageIcon from "@mui/icons-material/Language";
import { Grid } from "@mui/material";
import { TFunction } from "i18next";
import TrainingModule from "../../../models/trainingModule";
import { TouchpointCard } from "../../TouchpointCard/TouchpointCard";
import { Chip } from "../../ui/Chip/Chip";
import { Typography } from "../../ui/Typography/Typography";
import { IconTypes } from "../../ui/icons";
import { Icon } from "../../ui/icons/Icon";
import "./TrainingModuleContent.scss";

interface TrainingModuleContentProps {
  capitalClasses: string;
  t: TFunction<"trainingPage", undefined>;
  trainingModule: TrainingModule;
}

export const TrainingModuleContent = ({
  capitalClasses,
  t,
  trainingModule,
}: TrainingModuleContentProps) => (
  <div className="TrainingModuleContent">
    <Grid container>
      <Grid item sm={12} md={12}>
        <Typography tagVariant="h1" desktop="h1" className={capitalClasses}>
          {trainingModule.title}
        </Typography>

        <Grid item sm={12} md={12}>
          <Typography desktop="body2" color="secondary">
            {trainingModule.description}
          </Typography>
          <div className="TrainingModuleContent__tags">
            {trainingModule.tags.map((tag) => (
              <Chip key={tag} size="small" label={tag} />
            ))}
          </div>
        </Grid>
      </Grid>
      <Grid item sm={12} md={6}>
        <div className="TrainingModuleContent__info">
          <Icon type={IconTypes.Audience} color="secondary" />
          <Typography desktop="body2" color="secondary">
            {trainingModule.audience}
          </Typography>
        </div>
        <div className="TrainingModuleContent__info">
          <Icon type={IconTypes.Touchpoint} color="secondary" />
          <Typography desktop="body2" color="secondary">
            {t(`modules.touchpoints.info`, {
              count: trainingModule.touchPoints.length,
              duration: trainingModule.duration,
            })}
          </Typography>
        </div>
      </Grid>
      <Grid item sm={12} md={6}>
        <div className="TrainingModuleContent__languages">
          <LanguageIcon className="TrainingModuleContent__languages__icon" />
          <div className="TrainingModuleContent__languages__list">
            {trainingModule.languages.map((language, index) => (
              <Typography
                desktop="body2"
                color="secondary"
                className="TrainingModuleContent__languages__text"
                key={language}
              >
                {index !== 0 && "  • "}
                {language}
              </Typography>
            ))}
          </div>
        </div>
      </Grid>

      {trainingModule.touchPoints
        .sort((a, b) => a.order - b.order)
        .map((touchpoint) => (
          <Grid item sm={12} key={touchpoint.order}>
            <TouchpointCard
              touchpoint={touchpoint}
              order={touchpoint.order}
              canDownload={trainingModule.canDownload}
            />
          </Grid>
        ))}
    </Grid>
  </div>
);
