import { Checkbox, Grid, Skeleton } from "@mui/material";
import { Fragment, useState } from "react";
import useTranslation from "../../hooks/useTranslation";
import { GetSurveyJobLevels, JobRole } from "../../types/survey";
import {
  getJobRolesNotInDispatch,
  sortJobRolesByLevel,
} from "../../utils/jobRoles.utils";
import { Button } from "../ui/Button/Button";
import { Modal } from "../ui/Modal/Modal";
import { Paper } from "../ui/Paper/Paper";
import { Typography } from "../ui/Typography/Typography";

interface SurveyLaunchJobRolesModalProps {
  open: boolean;
  onClose: () => void;
  onSave: (jobRoles: JobRole[]) => Promise<void>;
  tenantJobRoles?: JobRole[];
  dispatchJobRoles?: JobRole[];
  dispatchId?: string;
  error?: boolean;
  isLoading?: boolean;
  jobLevels?: GetSurveyJobLevels;
}

export const SurveyLaunchJobRolesModal = ({
  open,
  onClose,
  onSave,
  tenantJobRoles,
  dispatchJobRoles,
  dispatchId,
  error,
  isLoading,
  jobLevels,
}: SurveyLaunchJobRolesModalProps) => {
  const { t } = useTranslation("surveyLaunchPage");
  const [checkedJobRoles, setCheckedJobRoles] = useState<JobRole[]>([]);
  const [saving, setSaving] = useState(false);
  const [saveError, setSaveError] = useState<string>();

  const handleCheckboxChange = (jobRole: JobRole) => {
    setCheckedJobRoles((prev) => {
      const isAlreadyChecked = prev.some((jr) => jr.id === jobRole.id);
      if (isAlreadyChecked) {
        return prev.filter((jr) => jr.id !== jobRole.id);
      } else {
        const sortedRoles = sortJobRolesByLevel(jobLevels, [...prev, jobRole]);

        // Update the order field after sorting.
        return sortedRoles.map((jr, index) => ({
          ...jr,
          order: index,
        }));
      }
    });
  };

  const handleSave = async () => {
    setSaving(true);
    setSaveError(undefined);
    try {
      await onSave(checkedJobRoles);
      onClose();
    } catch (err) {
      console.error("Error saving job roles:", err);
      setSaveError(t("jobRoles.errors.submit") as string);
    } finally {
      setSaving(false);
    }
  };

  const filteredTenantJobRoles = tenantJobRoles
    ? getJobRolesNotInDispatch(tenantJobRoles, dispatchId)
    : [];

  return (
    <Modal
      title={t("jobRoles.modalTitle")}
      onClose={onClose}
      open={open}
      containerClassName="SurveyLaunchJobRolesModal"
    >
      <Paper className="SurveyLaunchJobRolesModal__paper" secondary>
        <Typography desktop="body2">
          {t("jobRoles.tenantJobRolesInfo")}
        </Typography>
      </Paper>

      {isLoading && <Skeleton variant="rectangular" height={200} />}

      {tenantJobRoles && (
        <Grid container spacing={1} data-testid="tenant-job-roles-modal">
          <Grid
            container
            item
            xs={12}
            spacing={1}
            className="SurveyLaunchJobRolesModal__table"
            alignItems="center"
          >
            <Grid
              item
              xs={6}
              style={{ fontWeight: "bold" }}
              className="SurveyLaunchJobRolesModal__table__header"
            >
              {t("jobRoles.table.name")}
            </Grid>
            <Grid
              item
              xs={3}
              style={{ fontWeight: "bold" }}
              className="SurveyLaunchJobRolesModal__table__header"
            >
              {t("jobRoles.table.jobRole")}
            </Grid>
            <Grid
              item
              xs={3}
              style={{ fontWeight: "bold" }}
              className="SurveyLaunchJobRolesModal__table__header"
            >
              {t("jobRoles.table.reuse")}
            </Grid>

            {sortJobRolesByLevel(jobLevels, filteredTenantJobRoles).map(
              (jobRole) => {
                const isChecked = checkedJobRoles.some(
                  (jr) => jr.id === jobRole.id
                );
                const isInDispatch = dispatchJobRoles?.some(
                  (jr) => jr.id === jobRole.id
                );
                const jobLevelTranslation = jobLevels?.find(
                  (jl) => jl.job_level === jobRole.jobLevel
                )?.name;
                return (
                  <Fragment key={jobRole.id}>
                    <Grid
                      item
                      xs={6}
                      className="SurveyLaunchJobRolesModal__table__row"
                      data-testid={`tenant-job-role-row-name-${jobRole.id}`}
                    >
                      {jobRole.name}
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      className="SurveyLaunchJobRolesModal__table__row"
                      data-testid={`tenant-job-role-row-level-${jobRole.id}`}
                    >
                      {jobLevelTranslation
                        ? jobLevelTranslation
                        : jobRole.jobLevel}
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      className="SurveyLaunchJobRolesModal__table__row"
                      data-testid={`tenant-job-role-row-reuse-${jobRole.id}`}
                    >
                      <Checkbox
                        data-testid={`tenant-job-role-checkbox-${jobRole.id}`}
                        checked={isChecked || isInDispatch}
                        onChange={() => handleCheckboxChange(jobRole)}
                        disabled={isInDispatch}
                      />
                      {isInDispatch && (
                        <Typography desktop="caption">
                          {t("jobRoles.table.inDispatch")}
                        </Typography>
                      )}
                    </Grid>
                  </Fragment>
                );
              }
            )}
          </Grid>
        </Grid>
      )}

      {(saveError || error) && (
        <Typography
          desktop="body1"
          color="error"
          data-testid="tenant-job-roles-modal-error"
        >
          {saveError ? saveError : t("jobRoles.errors.tenantError")}
        </Typography>
      )}

      <Button
        data-testid="tenant-job-roles-modal-save-button"
        variant="contained"
        loading={saving}
        onClick={handleSave}
      >
        {t("actions.save")}
      </Button>
    </Modal>
  );
};
