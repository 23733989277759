import { IllustrationProps } from "../../types/illustration";
import { darkStoneBlue } from "../theme";

export const TrainingIllustration = ({
  className,
  size = 56,
}: IllustrationProps) => {
  return (
    <svg
      width={size}
      height={size}
      className={className}
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M47 24.5544C47 24.5544 45.8127 29.8001 47 36M47 24.5544C47 24.5544 33.6998 14.54 28 15.0165C22.3002 15.4929 9 24.5544 9 24.5544C9 24.5544 23.7248 34.0926 28 32.6617C32.2752 31.2308 47 24.5544 47 24.5544Z"
        stroke={darkStoneBlue}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 29.708C17.6875 31.8321 17 37.4402 17 37.4402C21.125 41.4523 33.5 42.8684 39 37.4402C39 37.4402 39 31.3321 39 28.5"
        stroke={darkStoneBlue}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
