import { SubscriptionType } from "../types/company";
import {
  AvailablePreviewActions,
  AvailableStandardActions,
  IUser,
  SubscriptionActions,
  UserResponse,
} from "../types/user";

class User {
  name: string;
  email: string;
  language: string;
  company?: {
    name: string;
    subscriptions: SubscriptionType[]; //TO BE deprecated
    subscription_access_level: SubscriptionType[];
  };
  availableActions: SubscriptionActions[] = [];

  constructor(parameters: UserResponse & IUser) {
    this.name = parameters.name;
    this.email = parameters.email;
    this.language = parameters.language;
    this.company = {
      name: parameters.company?.name,
      subscriptions: parameters.company?.subscriptions, //TO BE deprecated
      subscription_access_level: parameters.company?.subscription_access_level,
    };
    const accessLevel = parameters.company?.subscription_access_level
      ? parameters.company?.subscription_access_level
      : parameters.company?.subscriptions; // remove once subscriptions have been deprecated
    this.availableActions =
      accessLevel.reduce((actions, subscription) => {
        if (subscription === SubscriptionType.PREVIEW) {
          return [...actions, ...AvailablePreviewActions];
        }
        if (
          subscription === SubscriptionType.STANDARD ||
          subscription === SubscriptionType.TRIAL
        ) {
          return [...actions, ...AvailableStandardActions];
        }
        return actions; // If the subscription doesn't match, return the current accumulated actions
      }, [] as SubscriptionActions[]) || [];
  }

  getCompanyName = () => {
    return this.company?.name;
  };

  hasAccess = (role: SubscriptionActions) => {
    return this.availableActions.includes(role);
  };
}

export default User;
