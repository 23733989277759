import { AvailableLanguages, TAvailableSurveyLanguages } from "./locale";
import { SurveyQuestionResponse } from "./question";

export enum SurveyDispatchStatus {
  "InProgress" = "InProgress",
  "Ready" = "Ready",
  "Active" = "Active",
  "Latest" = "Latest",
  "Past" = "Past",
}

export interface SurveyDispatchReminderResponse {
  id: number;
  date: string;
}

export interface SurveyDispatchReminder {
  id: number | null;
  tempId?: number; // Used to identify the new reminder in the UI
  date?: Date;
}

export interface GetSurveyDispatchesResponse {
  created_at: string;
  due_date: string | null;
  engagement: number;
  has_job_roles: boolean;
  id: string;
  is_public: boolean;
  launch_date: string | null;
  name: string;
  recipients_count: number;
  reminders: SurveyDispatchReminderResponse[];
  survey_id: string;
  total_score: number | null;
}

export interface GetSurveyDispatchResponse extends GetSurveyDispatchesResponse {
  recipients: string[];
}

export interface CreateSurveyDispatchResponse {
  invalid_emails: string[];
  survey_dispatch: GetSurveyDispatchResponse;
}

export interface GetSurveyResponse {
  reference_id: string;
  chapters: SurveyChapterResponse[];
  due_date: string;
  is_public?: boolean;
}

export interface PreviewSurveyResponse {
  reference_id: string;
  chapters: SurveyChapterResponse[];
  due_date: string;
}

export interface SurveyAnswer {
  question: string;
  options: string[];
}

export interface SubmitSurveyBody {
  answers: SurveyAnswer[] | undefined;
}

export interface SurveyChapterResponse {
  reference_id: string;
  title: string;
  order: number;
  description?: string | undefined;
  trigger_warning?: string | undefined;
  questions: SurveyQuestionResponse[];
  image?: string | undefined;
  hidden: boolean;
}

export interface AddressBookResponse {
  emails: string[];
}

export interface CustomQuestionAddBody {
  text: string;
  label: string;
  options: ICustomQuestionOption[];
}

export interface CustomQuestionUpdateBody {
  text: string;
  label: string;
  options: ICustomQuestionOption[];
}

export interface CustomQuestion {
  id: string;
  text: string;
  label: string;
  dispatch_ids: string[];
  options: ICustomQuestionOption[];
}

export interface ICustomQuestionOption {
  id: string | null;
  text: string;
  label: string;
}

export type GetSurveyJobLevels = Array<{
  name: string;
  job_level: string;
  order: number;
}>;

export type GetJobRolesResponse = Array<{
  id: string;
  job_level: string;
  name: string;
  order: number;
  dispatch_ids: string[];
}>;

export interface UpdateJobRoleBody {
  id?: string;
  job_level: string;
  name: string;
  order?: number;
}

export interface JobRole {
  id?: string;
  tempId?: string;
  jobLevel: string;
  name: string;
  order: number;
  dispatchIds?: string[];
}

export enum SurveyErrorCodes {
  CLOSED = "SURVEY_CLOSED",
  INVALID = "INVITE_TOKEN_INVALID",
  ALREADY_USED = "TOKEN_ALREADY_USED",
}

export interface SurveyEmailLanguage {
  html_body: string;
  language_code: AvailableLanguages;
  name: string;
  native_name: string;
  subject: string;
}

export interface SurveyEmailContent {
  html_body: string;
  language_code: TAvailableSurveyLanguages;
  order: number;
  subject: string;
  text_body: string;
}

export interface SurveyEmailResponse {
  content: SurveyEmailContent[];
  custom_content: string;
  dispatch_id: string;
  reminder_id: string;
  survey_email_id: string;
  type: string;
}

// Body in post request for updating survey email
export interface SurveyEmailBody {
  content: {
    language_code: AvailableLanguages;
    order?: number;
  }[];
  custom_content?: string;
  survey_email_id: string;
}
