import { Grid } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SurveyService } from "../api/SurveyService";
import { SurveyDispatchCard } from "../components/SurveyDispatchCard/SurveyDispatchCard";
import { SurveyLaunchSteps } from "../components/SurveyLaunchSteps/SurveyLaunchSteps";
import { SurveyNoticeCard } from "../components/SurveyNoticeCard/SurveyNoticeCard";
import { LoadingIndicator } from "../components/ui/LoadingIndicator/LoadingIndicator";
import { Typography } from "../components/ui/Typography/Typography";
import useTranslation from "../hooks/useTranslation";
import SurveyDispatch from "../models/surveyDispatch";
import { GetSurveyDispatchResponse } from "../types/survey";
import "./SurveyLaunchPage.scss";

export const SurveyLaunchPage = () => {
  const surveyService = new SurveyService();
  const [surveyDispatch, setSurveyDispatch] = useState<SurveyDispatch>();
  const { t } = useTranslation("surveyLaunchPage");
  const dispatchIsEditable = surveyDispatch && surveyDispatch.hasNotLaunched();

  const { dispatchId } = useParams();
  const navigate = useNavigate();

  const {
    data: surveyDispatchData,
    isInitialLoading: isLoadingSurveyDispatch,
    error: surveyDispatchError,
    refetch: handleSurveyDispatchRefetch,
  } = useQuery<GetSurveyDispatchResponse, AxiosError>(
    [`surveyDispatch-${dispatchId}`],
    () => surveyService.getDispatch(dispatchId || ""),
    {
      enabled: !!dispatchId,
    }
  );

  const errorLoadingSurveyDispatch = () => {
    if (surveyDispatchError && surveyDispatchError.response?.status !== 404) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (surveyDispatchData && !surveyDispatch) {
      const surveyModel = new SurveyDispatch(surveyDispatchData);

      setSurveyDispatch(surveyModel);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surveyDispatchData]);

  useEffect(() => {
    if (surveyDispatchData && surveyDispatch) {
      const updatedSurvey = { ...surveyDispatch };
      updatedSurvey.recipients = surveyDispatchData.recipients;
      setSurveyDispatch(updatedSurvey);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surveyDispatchData?.recipients]);

  const handleClose = () => {
    handleSurveyDispatchRefetch();
  };

  if (surveyDispatchError && surveyDispatchError.response?.status === 404) {
    navigate("/404");
  }

  return (
    <div className="SurveyLaunchPage">
      <div className="SurveyLaunchPage__survey">
        <Grid container spacing={1} marginBottom={3}>
          <Grid item md={1} />
          <Grid item md={10}>
            <Typography
              desktop="h1"
              mobile="body1"
              tagVariant="h1"
              className="SurveyLaunchPage__survey__heading"
            >
              {t("title")}
            </Typography>

            {(!surveyDispatch || !surveyDispatch?.isActive()) && (
              <SurveyNoticeCard />
            )}

            {isLoadingSurveyDispatch && !errorLoadingSurveyDispatch() && (
              <div className="SurveyLaunchPage__survey__loading">
                <LoadingIndicator />
              </div>
            )}
            {errorLoadingSurveyDispatch() && (
              <Typography
                desktop="h3"
                color="error"
                className="SurveyLaunchPage__survey__action"
              >
                {t("surveyDispatchError")}
              </Typography>
            )}
          </Grid>
          <Grid item md={1} />
        </Grid>
        {!dispatchIsEditable && (
          <SurveyDispatchCard
            surveyDispatch={surveyDispatch}
            isLoading={false}
            error={false}
            onSurveyUpdate={handleSurveyDispatchRefetch}
          />
        )}
        {surveyDispatch && surveyDispatch.id && dispatchIsEditable && (
          <SurveyLaunchSteps
            onClose={handleClose}
            surveyDispatch={surveyDispatch}
            onSurveyUpdate={handleSurveyDispatchRefetch}
          />
        )}
      </div>
    </div>
  );
};
