import { Grid } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import classnames from "classnames";
import { ChangeEvent, FormEvent, useState } from "react";
import { SurveyService } from "../../api/SurveyService";
import useTranslation from "../../hooks/useTranslation";
import { CustomQuestion, ICustomQuestionOption } from "../../types/survey";
import { isDuplicateOptions } from "../../utils/customQuestions.utils";
import { Button } from "../ui/Button/Button";
import { Card } from "../ui/Card/Card";
import { Input } from "../ui/Input/Input";
import { Tooltip } from "../ui/Tooltip/Tooltip";
import { Typography } from "../ui/Typography/Typography";
import { TrashIcon } from "../ui/icons/TrashIcon";
import "./CustomQuestionForm.scss";

export interface CustomQuestionFormProps {
  closeForm?: () => void;
  disableForm?: boolean;
  dispatchId?: string;
  isInAccordion?: boolean;
  nrOfQuestions?: number;
  questionData?: CustomQuestion;
  questionIndex?: number;
  refetchQuestions?: () => void;
  setSuccessMsg: (msg: string) => void;
}
export const CustomQuestionForm = ({
  closeForm,
  disableForm = false,
  dispatchId,
  isInAccordion = false,
  nrOfQuestions = 0,
  questionData,
  questionIndex,
  refetchQuestions,
  setSuccessMsg,
}: CustomQuestionFormProps) => {
  const { t } = useTranslation("surveyLaunchPage");
  const surveyService = new SurveyService();
  const [question, setQuestion] = useState<string>(questionData?.text || "");
  const [label, setLabel] = useState<string>(questionData?.label || "");
  const [options, setOptions] = useState<ICustomQuestionOption[]>(
    questionData?.options || []
  );
  const [errorMsg, setErrorMsg] = useState<string>("");

  const addQuestionMutation = useMutation({
    mutationFn: () =>
      surveyService.addCustomQuestion(dispatchId || "", {
        text: question,
        label: label,
        options: options,
      }),
  });

  const updateQuestionMutation = useMutation({
    mutationFn: () =>
      surveyService.updateCustomQuestion(
        dispatchId || "",
        questionData?.id || "",
        {
          text: question,
          label: label,
          options: options,
        }
      ),
  });

  const deleteQuestionMutation = useMutation({
    mutationFn: () =>
      surveyService.deleteCustomQuestion(
        questionData?.id || "",
        dispatchId || ""
      ),
  });

  const isValidOptions = () => {
    if (isDuplicateOptions(options)) {
      setErrorMsg(t("customQuestions.errors.duplicate") || "");
      return false;
    } else if (options.length === 0) {
      setErrorMsg(t("customQuestions.errors.noOptions") || "");
      return false;
    }
    return true;
  };

  const handleQuestionChange = (e: ChangeEvent<HTMLInputElement>) => {
    setErrorMsg("");
    setSuccessMsg("");
    setQuestion(e.target.value);
  };

  const handleLabelChange = (e: ChangeEvent<HTMLInputElement>) => {
    setErrorMsg("");
    setSuccessMsg("");
    setLabel(e.target.value);
  };

  const handleOptionLabelChange = (newValue: string, index: number) => {
    setErrorMsg("");
    setSuccessMsg("");
    const newOptions = [...options];
    newOptions[index].label = newValue;
    setOptions(newOptions);
  };

  const handleOptionChange = (newValue: string, index: number) => {
    setErrorMsg("");
    setSuccessMsg("");
    const newOptions = [...options];
    newOptions[index].text = newValue;
    setOptions(newOptions);
  };

  const handleAddOption = () => {
    const newOptions = [...options];
    newOptions.push({ id: null, text: "", label: "" });
    setOptions(newOptions);
  };

  const handleKeyDown = (event: {
    key: string;
    preventDefault: () => void;
  }) => {
    if (event.key === "Tab" || event.key === "Enter") {
      event.preventDefault();
      handleAddOption();
    }
  };

  const handleDeleteOption = (optionIndex: number) => {
    const newOptions = [...options];
    newOptions.splice(optionIndex, 1);
    setOptions(newOptions);
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (isValidOptions()) {
      questionData ? handleUpdateQuestion(e) : handleAddQuestion(e);
    }
  };

  const handleAddQuestion = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    await addQuestionMutation.mutateAsync(undefined, {
      onSuccess: () => {
        setQuestion("");
        setLabel("");
        setOptions([]);
        refetchQuestions?.();
        closeForm?.();
        setSuccessMsg(t("customQuestions.success") || "");
      },
      onError: () => {
        setErrorMsg(t("customQuestions.errors.submit") || "");
      },
    });
  };

  const handleUpdateQuestion = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await updateQuestionMutation.mutateAsync(undefined, {
      onSuccess: () => {
        refetchQuestions?.();
        setSuccessMsg(t("customQuestions.updateSuccess") || "");
      },
      onError: () => {
        setErrorMsg(t("customQuestions.errors.submit") || "");
      },
    });
  };

  const handleDeleteQuestion = async () => {
    await deleteQuestionMutation.mutateAsync(undefined, {
      onSuccess: () => {
        refetchQuestions?.();
        setSuccessMsg(t("customQuestions.deleteSuccess") || "");
      },
      onError: () => {
        setErrorMsg(t("customQuestions.errors.delete") || "");
      },
    });
  };

  const handleCloseForm = () => {
    closeForm?.();
  };

  const itemClasses = classnames("CustomQuestionForm__item", {
    "CustomQuestionForm__item--accordion": isInAccordion,
  });

  return (
    <form
      onSubmit={handleSubmit}
      className="CustomQuestionForm"
      data-testid="cq-form"
    >
      <div className={itemClasses}>
        <div
          className="CustomQuestionForm__item__title"
          data-testid="cq-form-title"
        >
          {!isInAccordion && (
            <Typography tagVariant="h1" desktop="h2">
              {t("customQuestions.question.title") +
                " " +
                (questionData ? (questionIndex ?? 0) + 1 : nrOfQuestions + 1)}
            </Typography>
          )}
          <Tooltip title={t("actions.deleteQuestion")} placement="top">
            <Button
              className="CustomQuestionForm__item__title__action"
              icon
              variant="contained"
              color="secondary"
              aria-label={t("actions.deleteQuestion")}
              data-testid={`cq-delete-question-btn-${questionIndex}`}
              onClick={questionData ? handleDeleteQuestion : handleCloseForm}
            >
              <TrashIcon />
            </Button>
          </Tooltip>
        </div>

        <Grid container spacing={1} marginBottom={6}>
          <Grid item md={9}>
            <Tooltip
              title={t("customQuestions.warning.reuse")}
              placement="top"
              disableHoverListener={!disableForm}
              disableFocusListener={!disableForm}
              disableInteractive={!disableForm}
              disableTouchListener={!disableForm}
            >
              <Input
                data-testid={`cq-text-input-${questionIndex}`}
                id="question"
                value={question}
                onChange={handleQuestionChange}
                className="CustomQuestionForm__item__question"
                placeholder={t("customQuestions.question.placeholder")}
                multiline
                fullWidth
                label={t("customQuestions.question.label")}
                required
                disabled={disableForm}
              />
            </Tooltip>
          </Grid>
          <Grid item md={3}>
            <Tooltip
              title={t("customQuestions.warning.reuse")}
              placement="top"
              disableHoverListener={!disableForm}
              disableFocusListener={!disableForm}
              disableInteractive={!disableForm}
              disableTouchListener={!disableForm}
            >
              <Input
                data-testid={`cq-label-input-${questionIndex}`}
                id="question-label"
                value={label}
                onChange={handleLabelChange}
                onKeyDown={handleKeyDown}
                fullWidth
                placeholder={t("customQuestions.questionLabel.placeholder")}
                label={t("customQuestions.questionLabel.title")}
                required
                disabled={disableForm}
              />
            </Tooltip>
          </Grid>
        </Grid>

        <div
          className="CustomQuestionForm__options"
          data-testid={`cq-options-list-${questionIndex}`}
        >
          <Typography
            tagVariant="h2"
            desktop="h3"
            className="CustomQuestionForm__item__title"
          >
            {t("customQuestions.options.title")}
          </Typography>
          {options.map((option, index) => (
            <div
              key={`option-label-${index}`}
              data-testid={`cq-option-${index}`}
            >
              <Grid container spacing={1} marginBottom={3}>
                <Grid item md={8}>
                  <Tooltip
                    title={t("customQuestions.warning.reuse")}
                    placement="top"
                    disableHoverListener={!disableForm}
                    disableFocusListener={!disableForm}
                    disableInteractive={!disableForm}
                    disableTouchListener={!disableForm}
                  >
                    <Input
                      data-testid={`cq-option-text-${index}`}
                      id={`option-label-${index}`}
                      value={option.text}
                      onChange={(e) =>
                        handleOptionChange(e.target.value, index)
                      }
                      fullWidth
                      label={
                        t("customQuestions.options.label") + " " + (index + 1)
                      }
                      disabled={disableForm}
                    />
                  </Tooltip>
                </Grid>
                <Grid
                  item
                  md={4}
                  className="CustomQuestionForm__item__withButton"
                >
                  <Tooltip
                    title={t("customQuestions.warning.reuse")}
                    placement="top"
                    disableHoverListener={!disableForm}
                    disableFocusListener={!disableForm}
                    disableInteractive={!disableForm}
                    disableTouchListener={!disableForm}
                  >
                    <Input
                      data-testid={`cq-option-label-${index}`}
                      id={`optionLabel-${index}`}
                      value={option.label}
                      onChange={(e) =>
                        handleOptionLabelChange(e.target.value, index)
                      }
                      onKeyDown={handleKeyDown}
                      required
                      label={
                        t("customQuestions.label.title") + " " + (index + 1)
                      }
                      disabled={disableForm}
                    />
                  </Tooltip>
                  <Button
                    icon
                    variant="contained"
                    color="secondary"
                    aria-label={t("actions.deleteOption")}
                    data-testid={`cq-delete-option-btn-${index}`}
                    onClick={() => handleDeleteOption(index)}
                    disabled={disableForm}
                  >
                    <TrashIcon />
                  </Button>
                </Grid>
              </Grid>
            </div>
          ))}
        </div>

        <Tooltip
          title={t("customQuestions.warning.reuse")}
          placement="top"
          disableHoverListener={!disableForm}
          disableFocusListener={!disableForm}
          disableInteractive={!disableForm}
          disableTouchListener={!disableForm}
        >
          <Card
            data-testid="cq-add-option-card"
            className="CustomQuestionForm__options__action"
            inactive
            onClick={handleAddOption}
            aria-label={t("actions.addOption")}
            disabled={disableForm}
          >
            + {t("customQuestions.options.add")}
          </Card>
        </Tooltip>

        <div
          className="CustomQuestionForm__button"
          data-testid="cq-form-actions"
        >
          <Button
            data-testid={`cq-submit-btn-${questionIndex}`}
            variant="contained"
            type="submit"
            disabled={disableForm}
          >
            {questionData
              ? t("actions.updateQuestion")
              : t("actions.saveQuestion")}
          </Button>

          {errorMsg && (
            <Typography
              desktop="caption"
              color="error"
              data-testid="cq-form-error"
            >
              {errorMsg}
            </Typography>
          )}
        </div>
      </div>
    </form>
  );
};
