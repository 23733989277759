import MUICard, { CardProps as MUICardProps } from "@mui/material/Card";
import classNames from "classnames";
import { ForwardedRef, forwardRef } from "react";
import "./Card.scss";

interface CardProps extends MUICardProps {
  clickable?: boolean;
  containerClassName?: string;
  disabled?: boolean;
  inactive?: boolean;
}
export const Card = forwardRef<HTMLDivElement, CardProps>(
  (
    {
      className,
      clickable = false,
      containerClassName,
      disabled = false,
      inactive = false,
      onClick,
      square,
      variant,
      ...props
    }: CardProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const classes = classNames("Card", className, {
      "Card--clickable": !!onClick || clickable,
      "Card--disabled": disabled,
      "Card--inactive": inactive,
      "Card--outlined": variant === "outlined",
      "Card--square": square,
    });

    return (
      <div
        className={containerClassName}
        onClick={disabled ? undefined : (event) => onClick?.(event)}
        ref={ref}
      >
        <MUICard className={classes} {...props}>
          {props.children}
        </MUICard>
      </div>
    );
  }
);
