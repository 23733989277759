import { Skeleton } from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { SurveyService } from "../../api/SurveyService";
import useTranslation from "../../hooks/useTranslation";
import { CustomQuestion } from "../../types/survey";
import { getCustomQuestionsNotInDispatch } from "../../utils/customQuestions.utils";
import { CustomQuestionForm } from "../CustomQuestionForm/CustomQuestionForm";
import { CustomQuestionsList } from "../CustomQuestionsList/CustomQuestionsList";
import { CustomQuestionsModal } from "../CustomQuestionsModal/CustomQuestionsModal";
import { Button } from "../ui/Button/Button";
import { Snackbar } from "../ui/Snackbar/Snackbar";
import { Typography } from "../ui/Typography/Typography";
import "./CustomQuestionsOverview.scss";

interface CustomQuestionsOverviewProps {
  dispatchId: string;
}

export const CustomQuestionsOverview = ({
  dispatchId,
}: CustomQuestionsOverviewProps) => {
  const { t } = useTranslation("surveyLaunchPage");
  const surveyService = new SurveyService();
  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [addNewQuestion, setAddNewQuestion] = useState(false);
  const [showTenantQuestions, setShowTenantQuestions] = useState(false);
  const [hasReusedTenantQuestion, setHasReusedTenantQuestion] = useState(false);
  const [hasMultipleDispatches, setHasMultipleDispatches] = useState(false);

  // Query: dispatch-specific custom questions
  const {
    data: questionsData,
    refetch: refetchQuestions,
    isLoading: dispatchLoading,
    error: dispatchError,
  } = useQuery<CustomQuestion[], AxiosError>(
    [`customQuestions-${dispatchId}`],
    () => surveyService.getDispatchCustomQuestions(dispatchId),
    {
      enabled: !!dispatchId,
    }
  );

  // Query: tenant-level custom questions
  const {
    data: tenantQuestionsData,
    isLoading: tenantQuestionsLoading,
    error: tenantQuestionsError,
    refetch: refetchTenantQuestions,
  } = useQuery<CustomQuestion[], AxiosError>(
    ["tenantCustomQuestions"],
    () => surveyService.getAllCustomQuestions(),
    {
      enabled: !!dispatchId,
    }
  );

  useEffect(() => {
    if (tenantQuestionsData) {
      const filteredTenantQuestions = getCustomQuestionsNotInDispatch(
        tenantQuestionsData,
        dispatchId
      );
      // If any question in the filtered set references this dispatch => we have reused it
      if (
        filteredTenantQuestions.some((cq) =>
          cq.dispatch_ids?.includes(dispatchId || "")
        )
      ) {
        setHasReusedTenantQuestion(true);
      }
      // We've filtered out all questions not in this dispatch if any of those
      // has 1 or more dispatch IDs => multiple dispatches exist
      if (filteredTenantQuestions.some((cq) => cq.dispatch_ids.length >= 1)) {
        setHasMultipleDispatches(true);
      }
    }
  }, [tenantQuestionsData, dispatchId, questionsData]);

  const addOrUpdateCustomQuestions = async (questions: CustomQuestion[]) => {
    const apiCalls = questions.map((question) => {
      if (question.id) {
        return surveyService.updateCustomQuestion(dispatchId, question.id, {
          text: question.text,
          label: question.label,
          options: question.options,
        });
      } else {
        return surveyService.addCustomQuestion(dispatchId, {
          text: question.text,
          label: question.label,
          options: question.options,
        });
      }
    });
    const results = await Promise.allSettled(apiCalls);
    const anyRejected = results.some((r) => r.status === "rejected");

    // If any of the updates failed, throw an error that axios will catch and handle
    if (anyRejected) {
      throw new Error("Some custom question updates failed.");
    }
    return results;
  };

  const addOrUpdateCustomQuestionsMutation = useMutation<
    PromiseSettledResult<CustomQuestion>[],
    AxiosError,
    CustomQuestion[]
  >((customQuestions) => addOrUpdateCustomQuestions(customQuestions), {
    onSuccess: () => {
      refetchQuestions?.();
      setSuccessMsg(
        t("customQuestions.success") || "Successfully saved custom questions."
      );
      setErrorMsg("");
    },
    onError: () => {
      setErrorMsg(
        t("customQuestions.errors.submit") || "Failed to save custom questions."
      );
    },
  });

  const handleAddTenantQuestions = async (questions: CustomQuestion[]) => {
    return addOrUpdateCustomQuestionsMutation
      .mutateAsync(questions)
      .then(() => {
        refetchTenantQuestions();
      });
  };

  const buttonClasses = classNames("SurveyLaunchJobRoles__tenantJobRoles", {
    "SurveyLaunchJobRoles__tenantJobRoles--big":
      !hasReusedTenantQuestion && !(questionsData?.length || 0),
  });

  return (
    <div
      className="CustomQuestionsOverview"
      data-testid="custom-questions-overview"
    >
      <Typography
        tagVariant="p"
        desktop="body1"
        className="CustomQuestionsOverview__subtitle"
        data-testid="cq-subtitle"
      >
        {t("customQuestions.subtitle")}
      </Typography>

      {hasMultipleDispatches && !dispatchError && (
        <div
          className={buttonClasses}
          data-testid="tenant-question-btn-container"
        >
          <Button
            data-testid="open-tenant-questions-btn"
            variant={hasReusedTenantQuestion ? "outlined" : "contained"}
            onClick={() => setShowTenantQuestions(true)}
            color={
              !hasReusedTenantQuestion && !questionsData?.length
                ? "info"
                : "primary"
            }
            small={hasReusedTenantQuestion || (questionsData?.length ?? 0) > 0}
          >
            {t("customQuestions.openTenantQuestions")}
          </Button>
        </div>
      )}

      {questionsData && questionsData.length > 0 && (
        <Typography
          tagVariant="h3"
          desktop="h2"
          className="CustomQuestionsOverview__title"
          data-testid="cq-added-questions-title"
        >
          {t("customQuestions.addedQuestions.title")}
        </Typography>
      )}

      {dispatchLoading && (
        <Skeleton
          data-testid="cq-dispatch-loading"
          variant="rectangular"
          width="100%"
          height={100}
        />
      )}

      {dispatchError && (
        <Typography desktop="h3" data-testid="cq-dispatch-error">
          {t("customQuestions.errors.noData")}
        </Typography>
      )}

      <CustomQuestionsList
        questions={questionsData}
        refetchQuestions={refetchQuestions}
        setSuccessMsg={setSuccessMsg}
        dispatchId={dispatchId}
      />

      {addNewQuestion && (
        <div data-testid="cq-add-form-container">
          <CustomQuestionForm
            nrOfQuestions={questionsData ? questionsData.length : 0}
            refetchQuestions={refetchQuestions}
            setSuccessMsg={setSuccessMsg}
            closeForm={() => setAddNewQuestion(false)}
            dispatchId={dispatchId}
          />
        </div>
      )}

      {!dispatchError && !dispatchLoading && (
        <Button
          data-testid="add-question-btn"
          variant="contained"
          color="secondary"
          fullWidth
          onClick={() => setAddNewQuestion(true)}
          className="CustomQuestionsOverview__action"
        >
          {t("actions.addQuestion")}
        </Button>
      )}

      <Snackbar
        open={!!successMsg}
        message={successMsg}
        onClose={() => setSuccessMsg("")}
        data-testid="cq-success-snackbar"
      />
      <Snackbar
        open={!!errorMsg}
        message={errorMsg}
        onClose={() => setErrorMsg("")}
        data-testid="cq-error-snackbar"
      />

      {showTenantQuestions && (
        <CustomQuestionsModal
          open={showTenantQuestions}
          onClose={() => setShowTenantQuestions(false)}
          onSave={handleAddTenantQuestions}
          tenantQuestions={tenantQuestionsData}
          dispatchQuestions={questionsData}
          dispatchId={dispatchId}
          isLoading={tenantQuestionsLoading}
          error={!!tenantQuestionsError}
        />
      )}
    </div>
  );
};
