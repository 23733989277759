import { CircularProgress } from "@mui/material";
import MUIButton, { ButtonProps as MUIButtonProps } from "@mui/material/Button";
import classnames from "classnames";
import { MouseEventHandler, ReactNode, forwardRef } from "react";
import { useLanguages } from "../../../hooks/useLanguages";
import "./Button.scss";

export type ButtonColors =
  | "primary"
  | "secondary"
  | "success"
  | "inherit"
  | "warning"
  | "info"
  | "error";

export interface ButtonProps extends MUIButtonProps {
  children?: ReactNode;
  variant?: "text" | "contained" | "outlined";
  sx?: any;
  small?: boolean;
  className?: string;
  color?: ButtonColors;
  icon?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  disableRipple?: boolean;
  href?: string;
  loading?: boolean;
  type?: "submit" | "reset" | "button" | undefined;
  component?: any;
  title?: string;
  xSmall?: boolean;
}

export const Button = forwardRef(
  (
    {
      children,
      variant = "text",
      color = "primary",
      small = false,
      xSmall = false,
      className,
      icon = false,
      onClick,
      disabled,
      disableRipple = false,
      loading = false,
      component,
      ...props
    }: ButtonProps,
    ref
  ) => {
    const { isPrimaryFontSupported } = useLanguages();

    const classes = classnames("Button", className, {
      "Button--primary": color === "primary",
      "Button--secondary": color === "secondary",
      "Button--info": color === "info",
      "Button--outlined": variant !== "contained",
      "Button--contained": variant === "contained",
      "Button--text": variant === "text",
      "Button--small": small,
      "Button--xsmall": xSmall,
      "Button--icon": icon,
      "Button--disabled": disabled,
      "Button--backupFont": !isPrimaryFontSupported(),
    });

    return (
      <MUIButton
        className={classes}
        variant={variant}
        color={color}
        onClick={onClick}
        disabled={disabled}
        disableRipple={disableRipple}
        component={component}
        ref={ref}
        {...props}
      >
        {loading && (
          <div className="Button__loader">
            <CircularProgress
              size={"1rem"}
              color={variant === "contained" ? "borderGrey" : "primary"}
            ></CircularProgress>
          </div>
        )}
        {children}
      </MUIButton>
    );
  }
);
