import { VisibilityOutlined as VisbilityIcon } from "@mui/icons-material";
import classNames from "classnames";
import useTranslation from "../../hooks/useTranslation";
import ChecklistIllustration from "../../styles/illustrations/ChecklistIllustration.png";
import { SurveyRoutes } from "../../utils/routes.utils";
import { Button } from "../ui/Button/Button";
import { Card } from "../ui/Card/Card";
import { Typography } from "../ui/Typography/Typography";
import "./SurveyPreviewCard.scss";

export interface SurveyPreviewCardProps {
  className?: string;
  isLoading: boolean;
  isSetup?: boolean;
  firstPreview?: boolean;
  dispatchId?: string;
}

export const SurveyPreviewCard = ({
  className,
  isLoading,
  isSetup = false,
  firstPreview = false,
  dispatchId,
}: SurveyPreviewCardProps) => {
  const { t } = useTranslation("surveyLaunchPage");
  const classes = classNames("SurveyPreviewCard", className);

  const openNewWindow = () => {
    // Opening preview in a new window when we are in the Survey setup
    const url = `${SurveyRoutes.PREVIEW_SURVEY}/${dispatchId}#showAction`;

    window.open(url);
  };

  return (
    <div className={classes}>
      <Card className="SurveyPreviewCard__card">
        <div className="SurveyPreviewCard__content">
          <Typography desktop="body2">
            {t(firstPreview ? "preview.info1" : "preview.info2")}
          </Typography>
          <img src={ChecklistIllustration} alt="" />
          {isSetup ? (
            <Button
              className="SurveyPreviewCard__button"
              variant="contained"
              color="primary"
              onClick={openNewWindow}
              disabled={isLoading}
            >
              <VisbilityIcon sx={{ mr: 0.5 }} /> {t("preview.button")}
            </Button>
          ) : (
            <Button
              className="SurveyPreviewCard__button"
              variant="contained"
              color="primary"
              href={`${SurveyRoutes.PREVIEW_SURVEY}/${dispatchId}`}
              disabled={isLoading}
            >
              <VisbilityIcon sx={{ mr: 0.5 }} /> {t("preview.button")}
            </Button>
          )}
        </div>
      </Card>
    </div>
  );
};
