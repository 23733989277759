import { Grid, SelectChangeEvent } from "@mui/material";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Fragment, useState } from "react";
import useTranslation from "../../hooks/useTranslation";
import { DiversityRatioBreakdownFilter } from "../../types/diversityRatio";
import { Button } from "../ui/Button/Button";
import { Select } from "../ui/Select/Select";
import { Typography } from "../ui/Typography/Typography";
import { BoxIcon } from "../ui/icons/BoxIcon";
import { TrashIcon } from "../ui/icons/TrashIcon";
import {
  getDiversityBreakdownChartData,
  getThreeMostPopulatedGroups,
} from "./DiversityRatioBreakdown.utils";
import "./DiversityRatioBreakdownComparison.scss";

interface DiversityRatioBreakdownComparisonProps {
  breakdownLabel: string;
  breakdownFilters: DiversityRatioBreakdownFilter[];
  diversityBreakdownGroupColors?: { [key: string]: string };
}

export const DiversityRatioBreakdownComparison = ({
  breakdownLabel,
  breakdownFilters,
  diversityBreakdownGroupColors,
}: DiversityRatioBreakdownComparisonProps) => {
  const { t } = useTranslation("diversityRatioPage");
  const sortedBreakdownFilterLabels = breakdownFilters
    .map((filter) => filter.label)
    .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));

  const [shownFilters, setShownFilters] = useState<
    DiversityRatioBreakdownFilter[]
  >(getThreeMostPopulatedGroups(breakdownFilters));

  const addToComparison = (event: SelectChangeEvent<unknown>) => {
    const selectedFilters = breakdownFilters
      .filter((filter) =>
        (event.target.value as string[]).includes(filter.label)
      )
      .sort((a, b) =>
        a.label.toLowerCase().localeCompare(b.label.toLowerCase())
      );
    setShownFilters(selectedFilters);
  };

  const handleRemoveComparison = (
    filterToRemove: DiversityRatioBreakdownFilter
  ) => {
    setShownFilters(
      shownFilters.filter((sFilter) => sFilter.label !== filterToRemove.label)
    );
  };

  const renderBreakdownChart = (filter: DiversityRatioBreakdownFilter) => {
    const chartData = getDiversityBreakdownChartData(
      filter.label,
      filter.groups,
      diversityBreakdownGroupColors
    );

    return (
      <Fragment key={filter.label}>
        <Grid
          item
          xs={11}
          className="DiversityRatioBreakdownComparison__chart"
          data-testid={`diversity-ratio-comparison-chart-${breakdownLabel}`}
        >
          <Typography
            className="DiversityRatioBreakdownComparison__chart__count"
            color="secondary"
            desktop="caption"
          >
            {t("breakdown.numberEmployees", { count: filter.count })}
          </Typography>
          <HighchartsReact highcharts={Highcharts} options={chartData} />
        </Grid>
        <Grid
          item
          xs={1}
          className="DiversityRatioBreakdownComparison__chart__action"
          data-testid={`diversity-ratio-comparison-remove-${filter.label.replaceAll(
            " ",
            ""
          )}`}
        >
          <Button
            variant="contained"
            color="primary"
            icon={true}
            onClick={() => handleRemoveComparison(filter)}
            aria-label={t("breakdown.removeComparison")}
            title={t("breakdown.removeComparison")}
          >
            <TrashIcon color="white" />
          </Button>
        </Grid>
      </Fragment>
    );
  };

  return (
    <Grid container spacing={1} className="DiversityRatioBreakdownComparison">
      <Grid item md={4} sm={12} xs={12}>
        <Select
          labelId={`diversity-ratio-comparison-${breakdownLabel}`}
          id={`diversity-ratio-comparison-${breakdownLabel}`}
          value={shownFilters.map((filter) => filter.label)}
          label={t(`breakdown.${breakdownLabel}.selectLabel`)}
          onChange={addToComparison}
          data-testid={`diversity-ratio-comparison-${breakdownLabel}`}
          items={sortedBreakdownFilterLabels}
          multiple
          className="DiversityRatioBreakdownComparison__selector"
        />
      </Grid>

      <Grid
        item
        md={8}
        sm={12}
        xs={12}
        className="DiversityRatioBreakdownComparison__legend"
      >
        {diversityBreakdownGroupColors &&
          shownFilters.length > 0 &&
          Object.keys(diversityBreakdownGroupColors).map((groupLabel) => {
            return (
              <div
                className="DiversityRatioBreakdownComparison__legend__item"
                key={groupLabel}
              >
                <BoxIcon color={diversityBreakdownGroupColors[groupLabel]} />
                <Typography desktop="caption">{groupLabel}</Typography>
              </div>
            );
          })}
      </Grid>
      {shownFilters.map((sFilter) => renderBreakdownChart(sFilter))}
    </Grid>
  );
};
