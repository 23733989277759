import { Box, Typography, alpha } from "@mui/material";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { grey1, keyLimeColor } from "../../../styles/theme";
import "./ProgressBar.scss";

interface ProgressBarProps {
  className?: string;
  progress: number;
  vertical?: boolean;
  length?: string;
  labelPosition?: "progress" | "center";
  color?: string;
}

export const ProgressBar = ({
  progress,
  className,
  vertical,
  length,
  labelPosition = "progress",
  color,
}: ProgressBarProps) => {
  const [invertedProgress, setInvertedProgress] = useState(100);
  const [percentageTransform, setPercentageTransform] = useState<string>();

  useEffect(() => {
    setInvertedProgress(() => {
      return 100 - progress;
    });
    setPercentageTransform(() => {
      if (vertical) {
        return `translateY(${progress - 2 < 0 ? 0 : progress - 2}%) !important`;
      }
      return `translateX(${progress - 6 < 0 ? 0 : progress - 6}%) !important`;
    });
  }, [progress, vertical]);

  const classes = classNames("ProgressBar", className, {
    "ProgressBar--vertical": vertical,
    "ProgressBar--center": labelPosition === "center",
  });
  return (
    <Box sx={{ position: "relative" }} className={classes}>
      <Typography
        className="ProgressBar__info"
        sx={
          labelPosition === "progress"
            ? { transform: percentageTransform }
            : undefined
        }
      >
        {Math.round(progress)}%
      </Typography>
      <LinearProgress
        className="ProgressBar__bar"
        variant="determinate"
        sx={{
          ...(vertical ? { minHeight: length } : { height: 6 }),
          ...(vertical ? { width: 6 } : { width: length }),
          ...(labelPosition === "center" && { height: 20 }),
          borderRadius: 10,
          [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: color ? alpha(color, 0.4) : grey1,
          },
          [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 10,
            backgroundColor: color ? color : keyLimeColor,
            ...(vertical && {
              transform: `translateY(-${invertedProgress}%) !important`,
            }),
          },
        }}
        value={progress}
      />
    </Box>
  );
};
