import {
  Tooltip as MUITooltip,
  TooltipProps as MUITooltipProps,
  styled,
  tooltipClasses,
} from "@mui/material";
import { Card } from "../Card/Card";

export interface TooltipProps extends MUITooltipProps {
  cardContent?: React.ReactNode;
  placement?: "top" | "bottom" | "left" | "right";
}

const CustomisedTooltip = styled(
  ({ className, cardContent, placement, ...props }: TooltipProps) => (
    <MUITooltip
      {...props}
      classes={{ popper: className }}
      placement={placement}
    />
  )
)(({ cardContent, theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    borderRadius: 12,
    fontSize: "0.8rem",
    padding: cardContent ? "0" : "0.5rem",
  },
  [`& .${tooltipClasses.arrow}`]: {
    ...(cardContent && {
      color: theme.palette.common.white,
      "&:before": {
        border: `1px solid ${theme.palette.common.borderGrey}`,
        borderRadius: "4px",
      },
    }),
  },
}));

export const Tooltip = ({
  children,
  title,
  cardContent = null,
  placement = "bottom",
  ...rest
}: TooltipProps) => {
  return (
    <CustomisedTooltip
      {...rest}
      placement={placement}
      cardContent={cardContent}
      title={
        cardContent ? <Card variant="outlined">{cardContent}</Card> : title
      }
    >
      {children}
    </CustomisedTooltip>
  );
};
