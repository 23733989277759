import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { ChangeEvent, useState } from "react";
import useTranslation from "../../hooks/useTranslation";
import { CustomQuestion } from "../../types/survey";
import { CustomQuestionForm } from "../CustomQuestionForm/CustomQuestionForm";
import { Button } from "../ui/Button/Button";
import { Typography } from "../ui/Typography/Typography";
import { IconTypes } from "../ui/icons";
import { Icon } from "../ui/icons/Icon";
import "./CustomQuestionsList.scss";

interface CustomQuestionsListProps {
  dispatchId?: string;
  questions?: CustomQuestion[];
  refetchQuestions?: () => void;
  setSuccessMsg: (msg: string) => void;
}

export const CustomQuestionsList = ({
  dispatchId,
  questions,
  refetchQuestions,
  setSuccessMsg,
}: CustomQuestionsListProps) => {
  const { t } = useTranslation("surveyLaunchPage");
  const [expandedPanel, setExpandedPanel] = useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: ChangeEvent<{}>, isExpanded: boolean) => {
      setExpandedPanel(isExpanded ? panel : false);
    };

  return (
    <div className="CustomQuestionsList" data-testid="custom-questions-list">
      {questions?.map((question, index) => {
        const panelId = `panel-${index}`;
        const isExpanded = expandedPanel === panelId;

        return (
          <Accordion
            key={question.text}
            expanded={isExpanded}
            onChange={handleChange(panelId)}
            data-testid={`cq-accordion-${index}`}
          >
            <AccordionSummary
              expandIcon={
                <Button
                  icon
                  color="secondary"
                  data-testid={`expand-quest-btn-${index}`}
                >
                  <Icon type={IconTypes.NavArrow} />
                </Button>
              }
              aria-controls={`question${index}-content`}
              id={`question${index}-header`}
            >
              <div className="CustomQuestionsList__header">
                <Typography
                  desktop="body1"
                  weight="bold"
                  data-testid={`cq-title-${index}`}
                >
                  {t("customQuestions.question.title")} {index + 1}
                  {!isExpanded && ":"}
                </Typography>
                {!isExpanded && (
                  <Typography desktop="body1" data-testid={`cq-text-${index}`}>
                    {question.text}
                  </Typography>
                )}
                {question.dispatch_ids?.length > 1 && (
                  <Typography
                    desktop="body2"
                    color="secondary"
                    fontStyle="italic"
                    data-testid={`cq-reuse-${index}`}
                  >
                    {isExpanded
                      ? t("customQuestions.warning.reuse")
                      : t("customQuestions.reusedQuestion")}
                  </Typography>
                )}
              </div>
            </AccordionSummary>

            <AccordionDetails data-testid={`cq-details-${index}`}>
              <CustomQuestionForm
                nrOfQuestions={questions.length}
                questionData={question}
                questionIndex={index}
                refetchQuestions={refetchQuestions}
                isInAccordion
                setSuccessMsg={setSuccessMsg}
                dispatchId={dispatchId}
                disableForm={question.dispatch_ids?.length > 1}
              />
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
};
