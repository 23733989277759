import { Grid, Skeleton } from "@mui/material";
import classNames from "classnames";
import { useAuth } from "../../hooks/useAuth";
import useTranslation from "../../hooks/useTranslation";
import Login from "../../styles/illustrations/Login.gif";
import InProgress from "../../styles/illustrations/StaircaseAndPencil.png";
import { SurveyDispatchStatus } from "../../types/survey";
import { SubscriptionActions } from "../../types/user";
import {
  BENCHMARKING_SCORE,
  SurveyChapterScoring,
} from "../../types/workplaceCulture";
import { getFormattedDate } from "../../utils/formatters";
import { ProtectedRoutes } from "../../utils/routes.utils";
import { NoDataState } from "../NoDataState/NoDataState";
import { ScoreArc } from "../ScoreArc/ScoreArc";
import { Button } from "../ui/Button/Button";
import { Card } from "../ui/Card/Card";
import { Paper } from "../ui/Paper/Paper";
import { Typography } from "../ui/Typography/Typography";
import "./SurveyScoreCard.scss";

interface SurveyScoreCardProps {
  chapterScoringError?: boolean;
  chapters?: SurveyChapterScoring[];
  className?: string;
  isLoading?: boolean;
  score?: number;
  scoringError?: boolean;
  surveyDueDate?: Date;
  surveyLaunchDate?: Date;
  surveyStatus: SurveyDispatchStatus;
  hasOlderDispatches?: boolean;
  dispatchId?: string;
}

export const SurveyScoreCard = ({
  chapters,
  chapterScoringError = false,
  className,
  dispatchId,
  hasOlderDispatches = false,
  isLoading = true,
  score,
  scoringError = false,
  surveyDueDate,
  surveyLaunchDate,
  surveyStatus,
}: SurveyScoreCardProps) => {
  const { t, i18n } = useTranslation("dashboardPage");
  const { authInfo } = useAuth();

  const renderScore = () => {
    if (scoringError) {
      return (
        <NoDataState
          info={t("workplaceCultureWidget.scoreCard.scoreError")}
          light
        />
      );
    }

    if (!score) {
      return (
        <NoDataState
          info={t("workplaceCultureWidget.scoreCard.notEnoughAnswers")}
          light
        />
      );
    }

    return (
      <ScoreArc
        score={score}
        label={t("workplaceCultureWidget.scoreCard.benchmarking", {
          score: BENCHMARKING_SCORE,
        })}
        size="small"
      />
    );
  };

  const renderChapters = () => {
    if (chapterScoringError || !chapters) {
      return (
        <NoDataState
          info={t("workplaceCultureWidget.scoreCard.chapterError")}
          light
        />
      );
    }

    return chapters?.map(
      (chapter) =>
        chapter.demographic.scores.total_score && (
          <div
            className="SurveyScoreCard__results__chapters__item"
            key={chapter.id}
          >
            <ScoreArc
              key={chapter.id}
              score={chapter.demographic.scores.total_score}
              label={chapter.name}
              size="xSmall"
            />
            <img
              className="SurveyScoreCard__results__chapters__item__icon"
              src={chapter.image}
              alt=""
            />
          </div>
        )
    );
  };

  const classes = classNames("SurveyScoreCard", className);

  return (
    <Paper className={classes}>
      <Typography desktop="h3" className="SurveyScoreCard__title">
        {t("workplaceCultureWidget.scoreCard.title", {
          companyName: authInfo?.user?.company?.name,
        })}
      </Typography>
      {isLoading && (
        <div className="SurveyScoreCard__results">
          <Skeleton height={100} />
          <Skeleton height={100} />
        </div>
      )}
      {!isLoading && surveyStatus === SurveyDispatchStatus.Latest && (
        <Grid container className="SurveyScoreCard__results">
          <Grid
            item
            lg={12}
            md={12}
            sm={6}
            xs={12}
            className="SurveyScoreCard__results__score"
          >
            {renderScore()}
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            sm={6}
            sx={{ display: { xs: "none", sm: "flex" } }}
            className="SurveyScoreCard__results__chapters"
          >
            {renderChapters()}
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            className="SurveyScoreCard__results__score"
          >
            {dispatchId &&
              !chapterScoringError &&
              !scoringError &&
              !isLoading &&
              authInfo.user?.hasAccess(SubscriptionActions.LAUNCH_SURVEY) && (
                <Button
                  variant="contained"
                  href={`${ProtectedRoutes.WORKPLACE_CULTURE}/${dispatchId}`}
                  small
                  color="secondary"
                >
                  {t("workplaceCulturePage:actions.details")}
                </Button>
              )}
          </Grid>
        </Grid>
      )}
      {!isLoading && surveyStatus === SurveyDispatchStatus.Active && (
        <Card
          className="SurveyScoreCard__pendingResults"
          containerClassName="SurveyScoreCard__pendingResults__container"
        >
          <img
            className="SurveyScoreCard__pendingResults__img"
            src={Login}
            alt=""
          />
          <Typography desktop="body2">
            {t("workplaceCultureWidget.scoreCard.active", {
              dueDate: surveyDueDate
                ? getFormattedDate(surveyDueDate, t, i18n.language)
                : "the due date",
            })}
          </Typography>
          {hasOlderDispatches && (
            <Button
              variant="outlined"
              className="SurveyScoreCard__pendingResults__link"
              href={ProtectedRoutes.WORKPLACE_CULTURE}
              small
            >
              {t("workplaceCultureWidget.scoreCard.viewOlderSurveys")}
            </Button>
          )}
        </Card>
      )}
      {!isLoading &&
        (surveyStatus === SurveyDispatchStatus.InProgress ||
          surveyStatus === SurveyDispatchStatus.Ready) && (
          <Card
            className="SurveyScoreCard__pendingResults"
            containerClassName="SurveyScoreCard__pendingResults__container"
          >
            <img
              className="SurveyScoreCard__pendingResults__img"
              src={InProgress}
              alt=""
            />
            <Typography desktop="body2" fontStyle="italic">
              {t("workplaceCultureWidget.scoreCard.pending")}
            </Typography>
            {surveyLaunchDate && (
              <div className="SurveyScoreCard__pendingResults__date">
                <Typography desktop="body2">
                  {t("workplaceCultureWidget.scoreCard.pendingLaunchDate")}
                </Typography>
                <Typography desktop="body2" weight="bold">
                  {surveyLaunchDate
                    ? getFormattedDate(surveyLaunchDate, t, i18n.language)
                    : ""}
                </Typography>
              </div>
            )}
            {hasOlderDispatches && (
              <Button
                variant="outlined"
                className="SurveyScoreCard__pendingResults__link"
                href={ProtectedRoutes.WORKPLACE_CULTURE}
                small
              >
                {t("workplaceCultureWidget.scoreCard.viewOlderSurveys")}
              </Button>
            )}
          </Card>
        )}
    </Paper>
  );
};
