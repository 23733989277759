import { Grid } from "@mui/material";
import classnames from "classnames";
import { useEffect, useState } from "react";
import useTranslation from "../../hooks/useTranslation";
import { JobRole } from "../../types/survey";
import { getRolesByLevel } from "../../utils/jobRoles.utils";
import { Button } from "../ui/Button/Button";
import { Card } from "../ui/Card/Card";
import { Input } from "../ui/Input/Input";
import { Tooltip } from "../ui/Tooltip/Tooltip";
import { Typography } from "../ui/Typography/Typography";
import { TrashIcon } from "../ui/icons/TrashIcon";
import "./SurveyLaunchJobRoles.scss";

interface SurveyLaunchJobRolesItemProps {
  jobRoles: JobRole[];
  jobLevel: string;
  jobLevelName: string;
  optionMappingIndex: number;
  onAddOption: (jobLevel: string) => void;
  onChangeOption: (value: string, id: string) => void;
  onDeleteOption: (jobRole: JobRole) => void;
  disabled?: boolean;
  deleteInProgress?: boolean;
}

export const SurveyLaunchJobRolesItem = ({
  jobRoles,
  jobLevel,
  jobLevelName,
  onAddOption,
  onChangeOption,
  onDeleteOption,
  disabled,
  deleteInProgress,
}: SurveyLaunchJobRolesItemProps) => {
  const { t } = useTranslation("surveyLaunchPage");
  const [deletingId, setDeletingId] = useState<string>();

  useEffect(() => {
    if (deletingId && !deleteInProgress) {
      setDeletingId(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteInProgress]);

  const handleDelete = (jobRole: JobRole) => {
    setDeletingId(jobRole.id);
    onDeleteOption(jobRole);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      onAddOption(jobLevel);
    }
  };

  const classes = classnames("SurveyLaunchJobRolesItem", {
    "SurveyLaunchJobRolesItem--disabled": disabled,
  });

  return (
    <Grid
      container
      spacing={2}
      className={classes}
      data-testid={`survey-launch-job-roles-item-${jobLevel}`}
    >
      <Grid item xs={12} sm={6}>
        <Typography
          className="SurveyLaunchJobRolesItem__title"
          desktop="body1"
          weight="bold"
        >
          {jobLevelName}
        </Typography>
        <Typography desktop="caption">
          {t(`jobRoles.${jobLevel.toLocaleLowerCase()}.info`)}
        </Typography>
      </Grid>

      <Grid item xs={12} sm={6} className="SurveyLaunchJobRolesItem__options">
        <Typography desktop="caption" color="secondary">
          {t(`jobRoles.${jobLevel.toLocaleLowerCase()}.placeholder`)}
        </Typography>

        {getRolesByLevel(jobRoles, jobLevel).map((jobRole, i) => {
          const roleIdOrTempId = jobRole.id ?? jobRole.tempId ?? `temp-${i}`;
          return (
            <div
              className="SurveyLaunchJobRolesItem__options__inputContainer"
              key={`job-role-option${i}`}
            >
              <Tooltip
                title={
                  (jobRole.dispatchIds?.length || 0) > 1
                    ? t("jobRoles.warning.reuse")
                    : ""
                }
                disableHoverListener={!((jobRole.dispatchIds?.length || 0) > 1)}
                arrow
              >
                <div>
                  <Input
                    data-testid={`job-role-input-${jobLevel}-${roleIdOrTempId}`}
                    placeholder={t("jobRoles.placeholder")}
                    value={jobRole.name}
                    fullWidth
                    onChange={(e) => {
                      onChangeOption(e.target.value, roleIdOrTempId);
                    }}
                    onKeyDown={handleKeyDown}
                    disabled={(jobRole.dispatchIds?.length || 0) > 1}
                  />
                </div>
              </Tooltip>
              <Button
                data-testid={`delete-role-btn-${roleIdOrTempId}`}
                variant="contained"
                color="secondary"
                icon
                onClick={() => handleDelete(jobRole)}
                disabled={deleteInProgress && deletingId === jobRole.id}
              >
                <TrashIcon />
              </Button>
            </div>
          );
        })}

        <Card
          data-testid={`add-job-role-card-${jobLevel}`}
          className="SurveyLaunchJobRolesItem__options__card"
          inactive
          onClick={() => onAddOption(jobLevel)}
        >
          + {t("jobRoles.addOption")}
        </Card>
      </Grid>
    </Grid>
  );
};
