import { Grid } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { ActionPlanService } from "../api/ActionPlanService";
import { DiversityRatioService } from "../api/DiversityRatioService";
import { PipelineService } from "../api/PipelineService";
import { SurveyService } from "../api/SurveyService";
import { WorkplaceCultureService } from "../api/WorkplaceCultureService";
import { ActionPlanTodoWidget } from "../components/ActionPlanTodoWidget/ActionPlanTodoWidget";
import { ActionPlanWidget } from "../components/ActionPlanWidget/ActionPlanWidget";
import { DiversityRatioWidget } from "../components/DiversityRatioWidget/DiversityRatioWidget";
import { InsightsWidget } from "../components/InsightsWidget/InsightsWidget";
import { PipelineWidget } from "../components/PipelineWidget/PipelineWidget";
import { WorkplaceCultureWidget } from "../components/WorkplaceCultureWidget/WorkplaceCultureWidget";
import { Typography } from "../components/ui/Typography/Typography";
import { useAuth } from "../hooks/useAuth";
import useTranslation from "../hooks/useTranslation";
import SurveyDispatch from "../models/surveyDispatch";
import SurveyDispatchManager from "../models/surveyDispatchManager";
import { ActionPlanResponse } from "../types/actionPlan";
import {
  ErrorCodes as DiversityRatioErrorCodes,
  GetDiversityRatioResponse,
} from "../types/diversityRatio";
import { ErrorResponse } from "../types/error";
import {
  ErrorCodes as PipelineErrorCodes,
  PipelineResponse,
} from "../types/pipeline";
import { GetSurveyDispatchesResponse } from "../types/survey";
import { SubscriptionActions } from "../types/user";
import {
  GetSurveyChapterInsightResponse,
  GetSurveyChapterScoringResponse,
  GetSurveyScoringDemographicResponse,
} from "../types/workplaceCulture";
import "./DashboardPage.scss";

export const DashboardPage = () => {
  const { t } = useTranslation("dashboardPage");
  const { t: tWorkplaceCulture } = useTranslation("workplaceCulturePage");

  const { authInfo } = useAuth();

  const [latestDispatch, setLatestSurveyDispatch] = useState<SurveyDispatch>();
  const [pipelineErrorCode, setPipelineErrorCode] = useState<string>("");
  const [diversityRatioErrorCode, setDiversityRatioErrorCode] =
    useState<string>("");

  const actionPlanService = new ActionPlanService();
  const surveyService = new SurveyService();
  const workplaceCultureService = new WorkplaceCultureService();
  const diversityRatioService = new DiversityRatioService();
  const pipelineService = new PipelineService();

  const {
    data: actionPlanData,
    isLoading: actionPlanLoading,
    error: actionPlanError,
    refetch: handleActionPlanRefetch,
  } = useQuery<ActionPlanResponse, AxiosError>(["actionPlan"], () =>
    actionPlanService.getActionPlan()
  );

  const {
    data: surveyDispatchesData,
    isLoading: surveyDispatchesLoading,
    error: surveyDispatchesError,
    refetch: handleSurveyDispatchRefresh,
  } = useQuery<GetSurveyDispatchesResponse[], AxiosError>(
    ["surveyDispatches"],
    () => surveyService.getDispatches()
  );

  const {
    data: surveyScoringData,
    isLoading: surveyScoringLoading,
    error: surveyScoringError,
  } = useQuery<GetSurveyScoringDemographicResponse, AxiosError>(
    [`surveyScoring-${latestDispatch?.id}`],
    () => workplaceCultureService.getSurveyScoring(latestDispatch?.id || ""),
    {
      enabled: !!latestDispatch?.id,
    }
  );

  const {
    data: surveyChapterScoringData,
    isLoading: surveyChapterScoringLoading,
    error: surveyChapterScoringError,
  } = useQuery<GetSurveyChapterScoringResponse, AxiosError>(
    [`surveyChapterScoring-${latestDispatch?.id}`],
    () =>
      workplaceCultureService.getSurveyChapterScoring(latestDispatch?.id || ""),
    {
      enabled: !!latestDispatch?.id,
    }
  );

  const {
    data: surveyChapterInsightsData,
    isLoading: surveyChapterInsightsLoading,
    error: surveyChapterInsightsError,
  } = useQuery<GetSurveyChapterInsightResponse, AxiosError>(
    [`surveyChapterInsights-${latestDispatch?.id}`],
    () =>
      workplaceCultureService.getSurveyChapterInsights(
        latestDispatch?.id || ""
      ),
    {
      enabled: !!latestDispatch?.id,
    }
  );

  const {
    data: diversityRatioData,
    isLoading: diversityRatioLoading,
    error: diversityRatioError,
  } = useQuery<GetDiversityRatioResponse, AxiosError>(
    ["diversityRatio"],
    () => diversityRatioService.getDiversityRatio(),
    { cacheTime: 60 * 60 * 1000 } // increase cache time to 60 minutes
  );

  const {
    data: pipelineData,
    isLoading: pipelineDataLoading,
    error: pipelineDataError,
  } = useQuery<PipelineResponse, AxiosError>(["pipeline"], () =>
    pipelineService.getPipeline()
  );

  useEffect(() => {
    const errorData = pipelineDataError?.response
      ?.data as ErrorResponse<PipelineErrorCodes>;
    setPipelineErrorCode(errorData?.extra?.code || "");
  }, [pipelineDataError]);

  useEffect(() => {
    const errorData = diversityRatioError?.response
      ?.data as ErrorResponse<DiversityRatioErrorCodes>;
    setDiversityRatioErrorCode(errorData?.extra?.code || "");
  }, [diversityRatioError]);

  useEffect(() => {
    if (surveyDispatchesData) {
      const manager = new SurveyDispatchManager(
        surveyDispatchesData,
        tWorkplaceCulture
      );
      setLatestSurveyDispatch(manager.getLatest());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surveyDispatchesData]);

  return (
    <div className="DashboardPage">
      <Typography
        className="DashboardPage__title"
        tagVariant="h1"
        desktop="h1"
        data-testid="dashboard-title"
      >
        {t("title")}
        <Typography tagVariant="span" desktop="handwrittenH1" color="error">
          {authInfo.user?.getCompanyName()}?
        </Typography>
      </Typography>
      <Grid container spacing={3} marginBottom={3}>
        {process.env.REACT_APP_FEATURE_ACTION_PLAN === "true" && (
          <Grid
            item
            md={process.env.REACT_APP_FEATURE_TODO === "true" ? 8 : 12}
            className="DashboardPage__widget"
          >
            <ActionPlanWidget
              surveyDispatch={latestDispatch}
              eventsData={actionPlanData?.events}
              error={!!actionPlanError}
              isLoading={actionPlanLoading}
              onActionPlanUpdate={handleActionPlanRefetch}
            />
          </Grid>
        )}
        {process.env.REACT_APP_FEATURE_TODO === "true" && (
          <Grid item md={4} className="DashboardPage__widget">
            <ActionPlanTodoWidget />
          </Grid>
        )}
        <Grid item lg={8} className="DashboardPage__widget">
          <WorkplaceCultureWidget
            surveyDispatch={latestDispatch}
            surveyDispatchError={!!surveyDispatchesError}
            surveyDispatchLoading={surveyDispatchesLoading}
            onSurveyUpdate={handleSurveyDispatchRefresh}
            scoringData={surveyScoringData}
            scoringError={!!surveyScoringError || !!surveyChapterScoringError}
            scoringIsLoading={
              surveyScoringLoading || surveyChapterScoringLoading
            }
            preview={
              !authInfo.user?.hasAccess(SubscriptionActions.LAUNCH_SURVEY) &&
              surveyDispatchesData?.length === 0
            }
            chapters={surveyChapterScoringData?.chapters}
            hasDispatch={
              !!surveyDispatchesData ? surveyDispatchesData?.length > 0 : false
            }
            hasOlderDispatches={
              !!surveyDispatchesData ? surveyDispatchesData.length > 1 : false
            }
          />
        </Grid>
        <Grid item sm={12} md={6} lg={4} className="DashboardPage__widget">
          <InsightsWidget
            insightsData={surveyChapterInsightsData}
            insightsLoading={
              (surveyChapterInsightsLoading && !!latestDispatch?.id) ||
              surveyDispatchesLoading
            }
            insightsError={!!surveyChapterInsightsError}
            noInsights={
              surveyChapterInsightsData?.length === 0 ||
              (!latestDispatch?.id && !surveyDispatchesLoading)
            }
            preview={
              !authInfo.user?.hasAccess(SubscriptionActions.LAUNCH_SURVEY)
            }
            surveyClosed={latestDispatch?.isClosed()}
            dispatchId={latestDispatch?.id || ""}
          />
        </Grid>
        <Grid item sm={12} md={6} lg={4} className="DashboardPage__widget">
          <DiversityRatioWidget
            diversityData={diversityRatioData}
            diversityIsLoading={diversityRatioLoading}
            diversityError={!!diversityRatioError}
            noIntegration={
              diversityRatioErrorCode ===
              DiversityRatioErrorCodes.IntegrationNotFound
            }
            preview={diversityRatioError?.response?.status === 403}
          />
        </Grid>
        <Grid item sm={12} md={12} lg={8} className="DashboardPage__widget">
          <PipelineWidget
            pipelineData={pipelineData}
            pipelineDataLoading={pipelineDataLoading}
            pipelineDataError={!!pipelineDataError}
            noIntegration={
              pipelineErrorCode === PipelineErrorCodes.IntegrationNotFound
            }
            noJobLevel={pipelineErrorCode === PipelineErrorCodes.NoJobLevel}
            preview={pipelineDataError?.response?.status === 403}
          />
        </Grid>
      </Grid>
    </div>
  );
};
