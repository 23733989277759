import { v4 as uuidv4 } from "uuid";
import {
  GetJobRolesResponse,
  GetSurveyJobLevels,
  JobRole,
} from "../types/survey";

export const transformResponseToJobRoles = (
  response?: GetJobRolesResponse
): JobRole[] => {
  if (!response) {
    return [];
  }

  return response.map((r) => ({
    id: r.id,
    jobLevel: r.job_level,
    name: r.name,
    order: r.order,
    dispatchIds: r.dispatch_ids,
  }));
};

export const sortJobRolesByOrder = (jobRoles: JobRole[]): JobRole[] => {
  return [...jobRoles].sort((a, b) => (a.order ?? 0) - (b.order ?? 0));
};

export const sortJobRolesByLevel = (
  jobLevels: GetSurveyJobLevels | undefined,
  jobRoles: JobRole[]
): JobRole[] => {
  if (!jobLevels) {
    return sortJobRolesByOrder(jobRoles);
  }

  return jobLevels
    .sort((a, b) => (a.order - b.order ? 1 : -1))
    .reduce((acc, level) => {
      const rolesInLevel = jobRoles.filter(
        (r) => r.jobLevel === level.job_level
      );
      return acc.concat(sortJobRolesByOrder(rolesInLevel));
    }, [] as JobRole[]);
};

export const addJobRole = (
  jobRoles: JobRole[],
  jobLevel: string,
  allJobLevels: GetSurveyJobLevels | undefined
): JobRole[] => {
  const copy = [...jobRoles];
  const rolesInLevel = copy.filter((r) => r.jobLevel === jobLevel);
  const maxOrderInLevel = rolesInLevel.reduce(
    (max, r) => (r.order !== undefined && r.order > max ? r.order : max),
    -1
  );

  copy.push({
    tempId: uuidv4(),
    name: "",
    jobLevel: jobLevel,
    order: maxOrderInLevel + 1,
  });

  const sortedRoles = allJobLevels
    ? sortJobRolesByLevel(allJobLevels, copy)
    : sortJobRolesByOrder(copy);

  // Update the order field after sorting.
  return sortedRoles.map((jr, index) => ({
    ...jr,
    order: index,
  }));
};

export const deleteJobRole = (jobRoles: JobRole[], id: string): JobRole[] => {
  return jobRoles.filter((r) => (r.id ?? r.tempId) !== id);
};

export const setJobRoleName = (
  jobRoles: JobRole[],
  id: string,
  newName: string
): JobRole[] => {
  return jobRoles.map((r) => {
    const roleId = r.id ?? r.tempId;
    if (roleId === id) {
      return { ...r, name: newName };
    }
    return r;
  });
};

export const hasDuplicateJobRoles = (jobRoles: JobRole[]): boolean => {
  const trimmed = jobRoles.map((j) => j.name.trim());
  return new Set(trimmed).size !== trimmed.length;
};

export const getRolesByLevel = (
  jobRoles: JobRole[],
  level: string
): JobRole[] => {
  return jobRoles.filter((r) => r.jobLevel === level);
};

export const getValidJobRoles = (jobRoles: JobRole[]): JobRole[] => {
  return jobRoles.filter((r) => r.name.trim());
};

export const hasJobRoleChanges = (
  jobRoles: JobRole[],
  dispatchJobRoles?: GetJobRolesResponse
): boolean => {
  const normalizeCurrent = jobRoles.map((jr) => ({
    id: jr.id ?? "",
    name: jr.name.trim(),
    jobLevel: jr.jobLevel,
    order: jr.order ?? 0,
  }));

  const normalizeOriginal =
    dispatchJobRoles?.map((jr) => ({
      id: jr.id ?? "",
      name: jr.name.trim(),
      jobLevel: jr.job_level,
      order: jr.order ?? 0,
    })) || [];

  const sortById = (
    a: typeof normalizeCurrent[number],
    b: typeof normalizeCurrent[number]
  ) => a.id.localeCompare(b.id);

  normalizeCurrent.sort(sortById);
  normalizeOriginal.sort(sortById);

  return JSON.stringify(normalizeCurrent) !== JSON.stringify(normalizeOriginal);
};

export const getJobRolesNotInDispatch = (
  jobRoles: JobRole[],
  dispatchId: string | undefined
): JobRole[] => {
  return (
    jobRoles.filter((jr) => {
      if (!dispatchId) return true;

      // Otherwise, exclude any job roles with exactly one dispatchId equal to the dispatchId.
      return !(
        jr.dispatchIds?.length === 1 && jr.dispatchIds.includes(dispatchId)
      );
    }) || []
  );
};
