export enum SubscriptionType {
  PREVIEW = "PREVIEW",
  STANDARD = "STANDARD",
  TRIAL = "TRIAL",
}
export interface ICompany {
  name: string;
  industry: string;
  numberOfEmployees: number;
  subscriptions: SubscriptionType[];
  subscriptionAccessLevel: SubscriptionType[];
  subscriptionExpiresAt?: string;
}

export interface CompanyResponse {
  name: string;
  industry: string;
  number_of_employees: number;
  preferred_currency: string;
  subscriptions: SubscriptionType[];
  subscription_access_level: SubscriptionType[];
  subscription_expires_at?: string;
}

export type UpdateCompanyDetailsRequest = {
  name: string;
  industry: string;
  numberOfEmployees: number;
  preferredCurrency: string;
};

export interface TeamMember {
  email: string;
  has_logged_in: boolean;
}

export interface TeamMembersResponse {
  members: TeamMember[];
}

export enum TeamErrorCodes {
  EMAIL_ALREADY_EXISTS = "EMAIL_ALREADY_EXISTS",
}
