import { IconProps } from "./";

export const AudienceIcon = ({
  className,
  onClick,
  color,
  size = 20,
}: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={(event) => onClick?.(event)}
      stroke={color}
    >
      <path
        d="M2.5 17.4481C2.55584 15.2446 3.50511 12.6738 6.85548 12.6738C10.2059 12.6738 11.7135 14.7549 12.0486 16.7136"
        fill="transparent"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M12.5 11.875C15.8504 11.875 17.358 13.9561 17.6931 15.9148"
        fill="transparent"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M10.1041 6.12099C10.1041 4.34221 7.73346 3.75 6.94323 3.75C6.15301 3.75 4.375 5.32924 4.375 6.71326C4.375 8.9242 6.35056 9.47713 7.33835 9.47723C8.39198 9.54293 10.1041 7.89977 10.1041 6.12099Z"
        fill="transparent"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M12.5 3.125C13.2902 3.125 15.6609 3.71721 15.6609 5.49599C15.6609 7.27477 13.9487 8.91793 12.8951 8.85223"
        fill="transparent"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};
