import useTranslation from "../../hooks/useTranslation";
import SurveyDispatch from "../../models/surveyDispatch";
import SurveyDispatchManager from "../../models/surveyDispatchManager";
import { SurveyDispatchAccordion } from "../SurveyDispatchAccordion/SurveyDispatchAccordion";
import { SurveyLaunchButton } from "../SurveyLaunchButton/SurveyLaunchButton";
import { Typography } from "../ui/Typography/Typography";
import "./SurveyDispatchOverview.scss";

interface SurveyDispatchOverviewProps {
  dispatchManager: SurveyDispatchManager;
}
export const SurveyDispatchOverview = ({
  dispatchManager,
}: SurveyDispatchOverviewProps) => {
  const { t } = useTranslation("workplaceCulturePage");

  let activeDispatches: SurveyDispatch[] = [];
  let completedDispatches: SurveyDispatch[] = [];
  let draftDispatches: SurveyDispatch[] = [];

  if (dispatchManager) {
    ({ activeDispatches, completedDispatches, draftDispatches } =
      dispatchManager.getDispatchesByStatus());
  }

  return (
    <div className="SurveyDispatchOverview">
      <div className="SurveyDispatchOverview__header">
        <SurveyLaunchButton
          color="secondary"
          canLaunchNewDispatch={dispatchManager.canLaunchNewDispatch()}
        />
      </div>
      {activeDispatches.length > 0 && (
        <div className="SurveyDispatchOverview__group">
          <Typography
            className="SurveyDispatchOverview__group__title"
            desktop="h2"
          >
            {t("dispatches.active.title")}
          </Typography>
          {activeDispatches.map((dispatch, id) => {
            return (
              <div key={id}>
                <SurveyDispatchAccordion key={id} dispatch={dispatch} />
              </div>
            );
          })}
        </div>
      )}
      {draftDispatches.length > 0 && (
        <div className="SurveyDispatchOverview__group">
          <Typography
            className="SurveyDispatchOverview__group__title"
            desktop="h2"
          >
            {t("dispatches.draft.title")}
          </Typography>
          {draftDispatches.map((dispatch, id) => {
            return (
              <div key={id}>
                <SurveyDispatchAccordion key={id} dispatch={dispatch} />
              </div>
            );
          })}
        </div>
      )}

      {completedDispatches.length > 0 && (
        <div className="SurveyDispatchOverview__group">
          <Typography
            className="SurveyDispatchOverview__group__title"
            desktop="h2"
          >
            {t("dispatches.past.title")}
          </Typography>
          {completedDispatches.map((dispatch, id) => {
            return (
              <div key={id}>
                <SurveyDispatchAccordion key={id} dispatch={dispatch} />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
