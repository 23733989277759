import { ArrowIcon } from "./ArrowIcon";
import { AudienceIcon } from "./AudienceIcon";
import { BoxIcon } from "./BoxIcon";
import { CalendarIcon } from "./CalendarIcon";
import { ChatPersonIcon } from "./ChatPersonIcon";
import { CheckIcon } from "./CheckIcon";
import { ClockIcon } from "./ClockIcon";
import { CloseIcon } from "./CloseIcon";
import { CompanyIcon } from "./CompanyIcon";
import { DownloadIcon } from "./DownloadIcon";
import { EditIcon } from "./EditIcon";
import { GoogleG } from "./GoogleG";
import { GraduationHatIcon } from "./GraduationHatIcon";
import { HamburgerIcon } from "./HamburgerIcon";
import { HelpIcon } from "./HelpIcon";
import { InfoIcon } from "./InfoIcon";
import { LockIcon } from "./LockIcon";
import { MailIcon } from "./MailIcon";
import { ManyPeopleIcon } from "./ManyPeopleIcon";
import { MessageIcon } from "./MessageIcon";
import { Microsoft } from "./Microsoft";
import { MinusIcon } from "./MinusIcon";
import { NavArrow } from "./NavArrow";
import { PersonStandingIcon } from "./PersonStanding";
import { PlusIcon } from "./PlusIcon";
import { PreviewIcon } from "./PreviewIcon";
import { RedArrowIcon } from "./RedArrowIcon";
import { ShareIcon } from "./ShareIcon";
import { ShuttleIcon } from "./ShuttleIcon";
import { ThumbIcon } from "./ThumbIcon";
import { TouchpointIcon } from "./TouchpointIcon";
import { TrashIcon } from "./TrashIcon";
import { TrophyIcon } from "./TrophyIcon";
import { UploadIcon } from "./UploadIcon";
import { UserIcon } from "./UserIcon";
import { WarningIcon } from "./WarningIcon";

export enum IconTypes {
  "Arrow" = "Arrow",
  "Audience" = "Audience",
  "Box" = "Box",
  "Calendar" = "Calendar",
  "ChatPerson" = "ChatPerson",
  "Check" = "Check",
  "Clock" = "Clock",
  "Close" = "Close",
  "Company" = "Company",
  "Download" = "Download",
  "Edit" = "Edit",
  "GraduationHat" = "GraduationHat",
  "GoogleG" = "GoogleG",
  "Hamburger" = "Hamburger",
  "Help" = "Help",
  "Info" = "Info",
  "Lock" = "Lock",
  "Mail" = "Mail",
  "ManyPeople" = "ManyPeople",
  "Message" = "Message",
  "Microsoft" = "Microsoft",
  "Minus" = "Minus",
  "NavArrow" = "NavArrow",
  "PersonStanding" = "PersonStanding",
  "Plus" = "Plus",
  "Preview" = "Preview",
  "RedArrow" = "RedArrow",
  "Share" = "Share",
  "Shuttle" = "Shuttle",
  "Thumb" = "Thumb",
  "Touchpoint" = "Touchpoint",
  "Trash" = "Trash",
  "Trophy" = "Trophy",
  "Upload" = "Upload",
  "User" = "User",
  "Warning" = "Warning",
}

export enum Direction {
  "LEFT" = "LEFT",
  "RIGHT" = "RIGHT",
  "UP" = "UP",
  "DOWN" = "DOWN",
}

export interface IconProps {
  direction?: Direction;
  onClick?: (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
  className?: string;
  size?: number; // size in px
  small?: boolean;
  width?: number;
  height?: number;
  color?: string; // Can be a hex value, secondary or white
  onMouseEnter?: (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
  isChecked?: boolean;
}

type IAllIcons = {
  [key in IconTypes]: (props: IconProps) => JSX.Element;
};

const allIcons: IAllIcons = {
  [IconTypes.Arrow]: ArrowIcon,
  [IconTypes.Audience]: AudienceIcon,
  [IconTypes.Box]: BoxIcon,
  [IconTypes.Calendar]: CalendarIcon,
  [IconTypes.ChatPerson]: ChatPersonIcon,
  [IconTypes.Check]: CheckIcon,
  [IconTypes.Clock]: ClockIcon,
  [IconTypes.Close]: CloseIcon,
  [IconTypes.Company]: CompanyIcon,
  [IconTypes.Download]: DownloadIcon,
  [IconTypes.Edit]: EditIcon,
  [IconTypes.GraduationHat]: GraduationHatIcon,
  [IconTypes.GoogleG]: GoogleG,
  [IconTypes.Hamburger]: HamburgerIcon,
  [IconTypes.Help]: HelpIcon,
  [IconTypes.Info]: InfoIcon,
  [IconTypes.Lock]: LockIcon,
  [IconTypes.Mail]: MailIcon,
  [IconTypes.ManyPeople]: ManyPeopleIcon,
  [IconTypes.Message]: MessageIcon,
  [IconTypes.Microsoft]: Microsoft,
  [IconTypes.Minus]: MinusIcon,
  [IconTypes.NavArrow]: NavArrow,
  [IconTypes.PersonStanding]: PersonStandingIcon,
  [IconTypes.Plus]: PlusIcon,
  [IconTypes.Preview]: PreviewIcon,
  [IconTypes.RedArrow]: RedArrowIcon,
  [IconTypes.Share]: ShareIcon,
  [IconTypes.Shuttle]: ShuttleIcon,
  [IconTypes.Thumb]: ThumbIcon,
  [IconTypes.Touchpoint]: TouchpointIcon,
  [IconTypes.Trash]: TrashIcon,
  [IconTypes.Trophy]: TrophyIcon,
  [IconTypes.Upload]: UploadIcon,
  [IconTypes.User]: UserIcon,
  [IconTypes.Warning]: WarningIcon,
};

export default allIcons;
