// TODO: update when real data from backend is ready
export enum TrainingAudience {
  "All" = "ALL_EMPLOYEES",
  "Management" = "MANAGEMENT",
}

export interface TrainingModuleResponse {
  assets: AssetResponse[];
  audience: string;
  can_download: boolean;
  can_view: boolean;
  description: string;
  duration: string;
  is_ready: boolean;
  languages: string[];
  reference_id: string;
  suggested_order?: number;
  tags: string[];
  title: string;
  touchpoints: TouchpointResponse[];
}

export interface AssetResponse {
  language: string;
  id: string;
  type: string;
}

export interface TouchpointResponse {
  description: string;
  duration: string;
  order: number;
  preview_link: string;
  reference_id: string;
  thumbnail: string;
  title: string;
}

export enum AssetTypeEnum {
  Toolbox = "Toolbox",
  Thumbnail = "Thumbnail",
}
