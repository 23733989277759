import { SubscriptionType } from "./company";

export interface IUser {
  name: string;
  email: string;
  language: string;
  company: {
    name: string;
    subscriptions: SubscriptionType[]; // TO BE deprecated
    subscription_access_level: SubscriptionType[];
  };
}

export interface UserResponse {
  name: string;
  email: string;
  language: string;
  company: {
    name: string;
    // If a company has a trial subscription that's expired then the access level will be PREVIEW but the subscription will still be TRIAL
    subscriptions: SubscriptionType[]; // TO BE deprecated
    subscription_access_level: SubscriptionType[];
  };
}

export enum SubscriptionActions {
  "LAUNCH_SURVEY" = "LAUNCH_SURVEY",
  "HR_INTEGRATION" = "HR_INTEGRATION",
  "TEAM_MEMBERS" = "TEAM_MEMBERS",
}

export const AvailableStandardActions: SubscriptionActions[] = [
  SubscriptionActions.LAUNCH_SURVEY,
  SubscriptionActions.HR_INTEGRATION,
  SubscriptionActions.TEAM_MEMBERS,
];

export const AvailablePreviewActions: SubscriptionActions[] = [];

export enum InviteErrorCodes {
  EMAIL_MISMATCH = "EMAIL_MISMATCH",
  INVALID = "INVITE_TOKEN_INVALID",
}
