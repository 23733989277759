import { useState } from "react";
import { DOWNLOAD_ALL_ASSETS_VALUE } from "../utils/Training.utils";
import { useTrainingService } from "./useTrainingService";

export const useAssetHandler = (trainingModuleId: string) => {
  const { downloadAsset, downloadAllAssets } =
    useTrainingService(trainingModuleId);
  const [assetDownloadError, setAssetDownloadError] = useState(false);

  const handleAssetSelect = async (event: any) => {
    setAssetDownloadError(false);
    const assetId = event.target.value;

    try {
      if (assetId === DOWNLOAD_ALL_ASSETS_VALUE) {
        await downloadAllAssets();
      } else {
        await downloadAsset(assetId);
      }
    } catch {
      setAssetDownloadError(true);
    }
  };

  return { handleAssetSelect, assetDownloadError };
};
