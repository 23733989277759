import { Skeleton } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { SurveyService } from "../api/SurveyService";
import { SurveyDispatchOverview } from "../components/SurveyDispatchOverview/SurveyDispatchOverview";
import useTranslation from "../hooks/useTranslation";
import SurveyDispatchManager from "../models/surveyDispatchManager";
import { GetSurveyDispatchesResponse } from "../types/survey";

export const SurveyDispatchOverviewPage = () => {
  const { t } = useTranslation("workplaceCulturePage");
  const surveyService = new SurveyService();
  const [dispatchManager, setDispatchManager] =
    useState<SurveyDispatchManager>();
  const {
    data: surveyDispatchesData,
    isLoading: surveyDispatchesLoading,
    error: surveyDispatchesError,
  } = useQuery<GetSurveyDispatchesResponse[], AxiosError>(
    ["surveyDispatches"],
    () => surveyService.getDispatches()
  );

  useEffect(() => {
    if (surveyDispatchesData) {
      setDispatchManager(new SurveyDispatchManager(surveyDispatchesData, t));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surveyDispatchesData]);

  if (surveyDispatchesLoading) {
    return (
      <div>
        <Skeleton />
        <Skeleton width={200} />
      </div>
    );
  }

  if (surveyDispatchesError || !dispatchManager) {
    return <div>{t("error")}</div>;
  }

  return <SurveyDispatchOverview dispatchManager={dispatchManager} />;
};
