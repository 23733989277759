import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import classNames from "classnames";
import { SurveyLaunchButton } from "../SurveyLaunchButton/SurveyLaunchButton";
import { Button } from "../ui/Button/Button";
import { Typography } from "../ui/Typography/Typography";
import "./EmptyState.scss";

export interface EmptyStateProps {
  title: string;
  href: string;
  actionInfo: string;
  info?: string;
  isWidget?: boolean;
  action?: "launchSurvey";
}

export const EmptyState = ({
  title,
  href,
  actionInfo,
  isWidget = false,
  info,
  action,
}: EmptyStateProps) => {
  const classes = classNames("EmptyState", { "EmptyState--widget": isWidget });

  const renderButton = () => {
    const isSurveyAction = action === "launchSurvey";
    const ButtonComponent = isSurveyAction ? SurveyLaunchButton : Button;

    return (
      <ButtonComponent
        className="EmptyState__actions"
        {...(!isSurveyAction && {
          href,
          variant: "contained",
        })}
      >
        <Typography tagVariant="h3" desktop="h3">
          {actionInfo}
        </Typography>
        {isWidget && <KeyboardArrowRightIcon color="primary" />}
      </ButtonComponent>
    );
  };

  return (
    <div className={classes}>
      <div className="EmptyState__text">
        <Typography tagVariant="h1" desktop="h1">
          {title}
        </Typography>
        {info && (
          <Typography tagVariant="h3" desktop="body1">
            {info}
          </Typography>
        )}
      </div>

      {renderButton()}
    </div>
  );
};
