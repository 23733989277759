import { Checkbox, Grid, Skeleton } from "@mui/material";
import { Fragment, useState } from "react";
import useTranslation from "../../hooks/useTranslation";
import { CustomQuestion } from "../../types/survey";
import { getCustomQuestionsNotInDispatch } from "../../utils/customQuestions.utils";
import { Button } from "../ui/Button/Button";
import { Modal } from "../ui/Modal/Modal";
import { Paper } from "../ui/Paper/Paper";
import { Typography } from "../ui/Typography/Typography";
import "./CustomQuestionsModal.scss";

interface CustomQuestionsModalProps {
  open: boolean;
  onClose: () => void;
  onSave: (questions: CustomQuestion[]) => Promise<void>;
  tenantQuestions?: CustomQuestion[];
  dispatchQuestions?: CustomQuestion[];
  dispatchId?: string;
  error?: boolean;
  isLoading?: boolean;
}

export const CustomQuestionsModal = ({
  open,
  onClose,
  onSave,
  tenantQuestions,
  dispatchQuestions,
  dispatchId,
  error,
  isLoading,
}: CustomQuestionsModalProps) => {
  const { t } = useTranslation("surveyLaunchPage");
  const [checkedQuestions, setCheckedQuestions] = useState<CustomQuestion[]>(
    []
  );
  const [saving, setSaving] = useState(false);
  const [saveError, setSaveError] = useState<string>();

  const handleCheckboxChange = (question: CustomQuestion) => {
    setCheckedQuestions((prev) => {
      const isAlreadyChecked = prev.some((cq) => cq.id === question.id);
      return isAlreadyChecked
        ? prev.filter((cq) => cq.id !== question.id)
        : [...prev, question];
    });
  };

  const handleSave = async () => {
    setSaving(true);
    setSaveError(undefined);
    try {
      await onSave(checkedQuestions);
      onClose();
    } catch (err) {
      console.error("Error saving custom questions:", err);
      setSaveError(t("customQuestions.errors.submit") as string);
    } finally {
      setSaving(false);
    }
  };

  const filteredCustomQuestion = tenantQuestions
    ? getCustomQuestionsNotInDispatch(tenantQuestions, dispatchId)
    : [];

  return (
    <Modal
      title={t("customQuestions.modalTitle")}
      onClose={onClose}
      open={open}
      containerClassName="CustomQuestionsModal"
    >
      <Paper className="CustomQuestionsModal__paper" secondary>
        <Typography desktop="body2">
          {t("customQuestions.tenantQuestionsInfo")}
        </Typography>
      </Paper>

      {isLoading && <Skeleton variant="rectangular" height={200} />}
      {tenantQuestions && (
        <Grid container spacing={1} data-testid="cq-modal-table">
          <Grid
            container
            item
            xs={12}
            spacing={1}
            className="CustomQuestionsModal__table"
            alignItems="center"
          >
            <Grid
              item
              xs={4}
              style={{ fontWeight: "bold" }}
              className="CustomQuestionsModal__table__header"
              data-testid="cq-modal-table-header-text"
            >
              {t("customQuestions.table.text")}
            </Grid>

            <Grid
              item
              xs={5}
              style={{ fontWeight: "bold" }}
              className="CustomQuestionsModal__table__header"
              data-testid="cq-modal-table-header-options"
            >
              {t("customQuestions.table.options")}
            </Grid>

            <Grid
              item
              xs={3}
              style={{ fontWeight: "bold" }}
              className="CustomQuestionsModal__table__header"
              data-testid="cq-modal-table-header-reuse"
            >
              {t("customQuestions.table.reuse")}
            </Grid>

            {filteredCustomQuestion.map((question) => {
              const isChecked = checkedQuestions.some(
                (cq) => cq.id === question.id
              );
              const isInDispatch = dispatchQuestions?.some(
                (cq) => cq.id === question.id
              );

              return (
                <Fragment key={question.id}>
                  <Grid
                    item
                    xs={4}
                    className="CustomQuestionsModal__table__row"
                    data-testid={`cq-modal-row-text-${question.id}`}
                  >
                    {question.text}
                  </Grid>
                  <Grid
                    item
                    xs={5}
                    className="CustomQuestionsModal__table__row"
                    data-testid={`cq-modal-row-options-${question.id}`}
                  >
                    {question.options.map((option, i) => (
                      <Typography key={option.id} desktop="caption">
                        {option.text}
                        {i < question.options.length - 1 && " - "}
                      </Typography>
                    ))}
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    className="CustomQuestionsModal__table__row"
                  >
                    <Checkbox
                      data-testid={`cq-modal-checkbox-${question.id}`}
                      checked={isChecked || isInDispatch}
                      onChange={() => handleCheckboxChange(question)}
                      disabled={isInDispatch}
                    />
                    {isInDispatch && (
                      <Typography desktop="caption">
                        {t("customQuestions.table.inDispatch")}
                      </Typography>
                    )}
                  </Grid>
                </Fragment>
              );
            })}
          </Grid>
        </Grid>
      )}

      {(saveError || error) && (
        <Typography desktop="body1" color="error">
          {saveError ? saveError : t("customQuestions.errors.tenantError")}
        </Typography>
      )}

      <Button
        data-testid="cq-modal-save-button"
        variant="contained"
        loading={saving}
        onClick={handleSave}
      >
        {t("actions.save")}
      </Button>
    </Modal>
  );
};
