import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Link, Breadcrumbs as MUIBreadcrumbs, Typography } from "@mui/material";
import classnames from "classnames";
import { ReactNode } from "react";
import { useLocation } from "react-router-dom";
import useTranslation from "../../hooks/useTranslation";
import { DiversityIllustration } from "../../styles/illustrations/DiversityIllustration";
import { HomeIllustration } from "../../styles/illustrations/HomeIllustration";
import { PayEquityIllustration } from "../../styles/illustrations/PayEquityIllustration";
import { RecruitmentIllustration } from "../../styles/illustrations/RecruitmentIllustration";
import { TrainingIllustration } from "../../styles/illustrations/TrainingIllustration";
import { WorkplaceCultureIllustration } from "../../styles/illustrations/WorkplaceCultureIllustration";
import { parseBreadcrumbPath } from "./Breadcrumbs.utils";
import "./Breadcrumbs.scss";

export const Breadcrumbs = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const pathnames = parseBreadcrumbPath(pathname);
  const iconSize = 32;
  const breadcrumbIcons = new Map<string, ReactNode>([
    ["home", <HomeIllustration size={iconSize} />],
    [
      "workplace-culture",
      <WorkplaceCultureIllustration iconOnly size={iconSize} />,
    ],
    [
      "workplace-culture/id",
      <WorkplaceCultureIllustration iconOnly size={iconSize} />,
    ],
    ["chapter/id", <WorkplaceCultureIllustration iconOnly size={iconSize} />],
    ["diversity-ratio", <DiversityIllustration iconOnly size={iconSize} />],
    ["pay-equity", <PayEquityIllustration iconOnly size={iconSize} />],
    ["pipeline", <RecruitmentIllustration iconOnly size={iconSize} />],
    ["training", <TrainingIllustration iconOnly size={iconSize} />],
  ]);

  const classes = classnames("Breadcrumbs", {
    "Breadcrumbs--hide": pathnames.length === 0,
  });
  return (
    <MUIBreadcrumbs className={classes} separator={<KeyboardArrowRightIcon />}>
      {pathnames.length && (
        <Link className="Breadcrumbs__item" href="/" color="inherit">
          {breadcrumbIcons.get("home")}
          <Typography className="Breadcrumbs__item__title" variant="h5">
            {t("breadcrumbs.home")}
          </Typography>
        </Link>
      )}

      {pathnames.map((pathname, i) => {
        const isLast = i === pathnames.length - 1;

        return (
          <Link
            key={pathname.route + pathname.label}
            className="Breadcrumbs__item"
            href={pathname.route}
            color="inherit"
            style={isLast ? { textDecoration: "underline" } : undefined}
          >
            {breadcrumbIcons.get(pathname.label)}
            <Typography className="Breadcrumbs__item__title" variant="h5">
              {t(`breadcrumbs.${pathname.label}`)}
            </Typography>
          </Link>
        );
      })}
    </MUIBreadcrumbs>
  );
};
