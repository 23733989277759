import { Accordion, AccordionSummary, Grid } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import classNames from "classnames";
import { MouseEvent, useState } from "react";
import { WorkplaceCultureService } from "../../api/WorkplaceCultureService";
import SurveyDispatch from "../../models/surveyDispatch";
import { SurveyDispatchStatus } from "../../types/survey";
import {
  GetSurveyChapterInsightResponse,
  GetSurveyChapterScoringResponse,
} from "../../types/workplaceCulture";
import { InsightsCarousel } from "../InsightsCarousel/InsightsCarousel";
import { SurveyDispatchCard } from "../SurveyDispatchCard/SurveyDispatchCard";
import { SurveyScoreCard } from "../SurveyScoreCard/SurveyScoreCard";
import { IconTypes } from "../ui/icons";
import { Icon } from "../ui/icons/Icon";
import "./SurveyDispatchAccordion.scss";

interface SurveyDispatchAccordionProps {
  dispatch?: SurveyDispatch;
  isLoading?: boolean;
  error?: boolean;
}
export const SurveyDispatchAccordion = ({
  dispatch,
  isLoading,
  error,
}: SurveyDispatchAccordionProps) => {
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const workplaceCultureService = new WorkplaceCultureService();

  const {
    data: chapterScoringData,
    isLoading: chapterScoringIsLoading,
    error: chapterScoringError,
  } = useQuery<GetSurveyChapterScoringResponse, AxiosError>(
    [`surveyChapterScoring-${dispatch?.id}`],
    () => workplaceCultureService.getSurveyChapterScoring(dispatch?.id || ""),
    {
      enabled: !!dispatch?.id,
    }
  );

  const {
    data: insightsData,
    isLoading: insightsLoading,
    error: insightsError,
  } = useQuery<GetSurveyChapterInsightResponse, AxiosError>(
    [`surveyChapterInsights-${dispatch?.id}`],
    () => workplaceCultureService.getSurveyChapterInsights(dispatch?.id || ""),
    {
      enabled: !!dispatch?.id,
    }
  );

  const shouldOpenAccordion = dispatch?.isClosed();
  const hasInsights = dispatch?.hasData();

  const handleAccordionClick = (event: MouseEvent<HTMLDivElement>) => {
    if (shouldOpenAccordion) {
      setIsAccordionOpen(!isAccordionOpen);
    }
  };

  const classes = classNames("SurveyDispatchAccordion", {
    "SurveyDispatchAccordion--disabled": !shouldOpenAccordion,
  });

  const summaryClasses = classNames("SurveyDispatchAccordion__summary", {
    "SurveyDispatchAccordion__summary--disabled": !shouldOpenAccordion,
  });

  return (
    <Accordion
      className={classes}
      square={true}
      onClick={handleAccordionClick}
      expanded={isAccordionOpen}
    >
      <AccordionSummary
        className={summaryClasses}
        expandIcon={
          dispatch?.isClosed() ? <Icon type={IconTypes.NavArrow} /> : undefined
        }
      >
        <SurveyDispatchCard
          surveyDispatch={dispatch}
          isLoading={isLoading}
          error={error}
        />
      </AccordionSummary>
      <AccordionSummary className="SurveyDispatchAccordion__details">
        <Grid
          container
          spacing={5}
          className="SurveyDispatchAccordion__details__content"
          onClick={(e) => e.stopPropagation()}
        >
          <Grid
            item
            md={hasInsights ? 7 : 12}
            className="SurveyDispatchAccordion__details__content__scores"
          >
            <SurveyScoreCard
              chapterScoringError={!!chapterScoringError}
              chapters={chapterScoringData?.chapters}
              isLoading={chapterScoringIsLoading || !!isLoading}
              score={dispatch?.score || 0}
              scoringError={!!error}
              surveyDueDate={dispatch?.dueDate}
              surveyLaunchDate={dispatch?.launchDate}
              surveyStatus={dispatch?.status || SurveyDispatchStatus.Latest}
              dispatchId={dispatch?.id}
            />
          </Grid>
          {hasInsights && (
            <Grid item md={5}>
              <InsightsCarousel
                className="SurveyDispatchAccordion__details__content__insights"
                insightsData={insightsData}
                error={!!insightsError}
                isLoading={insightsLoading}
                noInsights={insightsData?.length === 0}
                dispatchId={dispatch?.id || ""}
              />
            </Grid>
          )}
        </Grid>
      </AccordionSummary>
    </Accordion>
  );
};
