import { Grid } from "@mui/material";
import { useAuth } from "../../hooks/useAuth";
import useTranslation from "../../hooks/useTranslation";
import SurveyDispatch from "../../models/surveyDispatch";
import { WorkplaceCultureIllustration } from "../../styles/illustrations/WorkplaceCultureIllustration";
import { SurveyDispatchStatus } from "../../types/survey";
import { SubscriptionActions } from "../../types/user";
import {
  GetSurveyScoringDemographicResponse,
  SurveyChapterScoring,
} from "../../types/workplaceCulture";
import { ProtectedRoutes } from "../../utils/routes.utils";
import { EmptyState } from "../EmptyState/EmptyState";
import { NoDataState } from "../NoDataState/NoDataState";
import { SurveyDispatchStatusCard } from "../SurveyDispatchStatusCard/SurveyDispatchStatusCard";
import { SurveyScoreCard } from "../SurveyScoreCard/SurveyScoreCard";
import { scoringChapters } from "../SurveyScoreCard/SurveyScoreCard.data";
import { scoringResponse as dummyScoringResponse } from "../WorkplaceCultureOverview/WorkplaceCultureOverview.data";
import { DashboardWidget } from "../ui/DashboardWidget/DashboardWidget";
import "./WorkplaceCultureWidget.scss";

interface WorkplaceCultureWidgetProps {
  chapters?: SurveyChapterScoring[];
  chapterScoringError?: boolean;
  onSurveyUpdate: () => void;
  preview?: boolean;
  scoringData?: GetSurveyScoringDemographicResponse;
  scoringError?: boolean;
  scoringIsLoading?: boolean;
  surveyDispatch?: SurveyDispatch;
  surveyDispatchError?: boolean;
  surveyDispatchLoading?: boolean;
  hasDispatch?: boolean;
  hasOlderDispatches?: boolean;
}

export const WorkplaceCultureWidget = ({
  chapters,
  chapterScoringError = false,
  onSurveyUpdate,
  preview = false,
  scoringData,
  scoringError,
  scoringIsLoading,
  surveyDispatch,
  surveyDispatchError = false,
  surveyDispatchLoading = true,
  hasDispatch = false, // Done to avoid flashing in UI while SurveyDispatch model is generated
  hasOlderDispatches = false,
}: WorkplaceCultureWidgetProps) => {
  const { t } = useTranslation("workplaceCulturePage");
  const { authInfo } = useAuth();

  let scoring: GetSurveyScoringDemographicResponse | undefined = undefined;
  let chapterScoring: SurveyChapterScoring[] | undefined = undefined;

  if (preview) {
    scoring = dummyScoringResponse;
    chapterScoring = scoringChapters;
  } else if (scoringData) {
    scoring = scoringData;
    chapterScoring = chapters;
  }

  return (
    <DashboardWidget
      title={t("dashboardPage:workplaceCultureWidget.title")}
      icon={WorkplaceCultureIllustration}
      href={
        authInfo.user?.hasAccess(SubscriptionActions.LAUNCH_SURVEY)
          ? ProtectedRoutes.WORKPLACE_CULTURE
          : undefined
      }
      preview={preview}
    >
      {!surveyDispatchError &&
        !hasDispatch &&
        !preview &&
        !surveyDispatchLoading && (
          <EmptyState
            title={t("status.noSurvey")}
            href={ProtectedRoutes.LAUNCH}
            action="launchSurvey"
            actionInfo={t("status.sendSurvey")}
            isWidget
          />
        )}
      {surveyDispatchError && !preview && (
        <NoDataState info={t("dashboardPage:workplaceCultureWidget.error")} />
      )}
      {(!!hasDispatch || preview || surveyDispatchLoading) && (
        <Grid container className="WorkplaceCultureWidget" columnSpacing={2}>
          <Grid
            item
            md={preview ? 12 : 7}
            sm={preview ? 12 : 7}
            xs={preview ? 12 : 6}
            className="WorkplaceCultureWidget__item"
          >
            <SurveyScoreCard
              chapters={chapterScoring}
              chapterScoringError={chapterScoringError && !preview}
              isLoading={scoringIsLoading && !preview}
              score={scoring?.scores.total_score}
              scoringError={scoringError && !preview}
              surveyDueDate={surveyDispatch?.dueDate}
              surveyLaunchDate={surveyDispatch?.launchDate}
              surveyStatus={
                surveyDispatch?.status || SurveyDispatchStatus.Latest
              }
              className="WorkplaceCultureWidget__card"
              hasOlderDispatches={hasOlderDispatches}
              dispatchId={surveyDispatch?.id}
            />
          </Grid>
          {!preview && (
            <Grid item md={5} sm={5} xs={6}>
              <SurveyDispatchStatusCard
                isLoading={surveyDispatchLoading}
                onSurveyUpdate={onSurveyUpdate}
                surveyDispatch={surveyDispatch}
                className="WorkplaceCultureWidget__card"
              />
            </Grid>
          )}
        </Grid>
      )}
    </DashboardWidget>
  );
};
