import classnames from "classnames";
import useTranslation from "../../hooks/useTranslation";
import SurveyDispatch from "../../models/surveyDispatch";
import { Tooltip } from "../ui/Tooltip/Tooltip";
import { Typography } from "../ui/Typography/Typography";
import { IconTypes } from "../ui/icons";
import { CheckIcon } from "../ui/icons/CheckIcon";
import { Icon } from "../ui/icons/Icon";
import "./SurveyReadyStatus.scss";

interface SurveyReadyStatuProps {
  className?: string;
  surveyDispatch?: SurveyDispatch;
  small?: boolean;
}

export const SurveyReadyStatus = ({
  className,
  surveyDispatch,
  small = false,
}: SurveyReadyStatuProps) => {
  const { t } = useTranslation("workplaceCulturePage");

  const classes = classnames("SurveyReadyStatus", className);

  const renderChecklist = () => {
    return (
      <div className="SurveyReadyStatus__checklist">
        {!small && (
          <Typography desktop="caption" weight="bold">
            {t("info.setup.status")}
          </Typography>
        )}
        <div className="SurveyReadyStatus__checklist__item">
          <CheckIcon isChecked={surveyDispatch?.isJobRolesMapped} />
          <Typography desktop="caption">{t("info.setup.jobRoles")}</Typography>
        </div>
        <div className="SurveyReadyStatus__checklist__item">
          <CheckIcon
            isChecked={
              !!surveyDispatch?.dueDate && !!surveyDispatch?.launchDate
            }
          />
          <Typography desktop="caption">{t("info.setup.dates")}</Typography>
        </div>
        {!surveyDispatch?.isPublic && (
          <div className="SurveyReadyStatus__checklist__item">
            <CheckIcon isChecked={(surveyDispatch?.recipientsCount ?? 0) > 0} />
            <Typography desktop="caption">
              {t("info.setup.recipients")}
            </Typography>
          </div>
        )}
      </div>
    );
  };

  const showTooltip = small || surveyDispatch?.isReady();

  return (
    <div className={classes}>
      {showTooltip && (
        <>
          <Tooltip
            title=""
            cardContent={renderChecklist()}
            placement="top"
            arrow
          >
            <div>
              {surveyDispatch?.isInProgress() ? (
                <Icon type={IconTypes.Warning} />
              ) : (
                <Icon type={IconTypes.Check} size={small ? 16 : 24} isChecked />
              )}
            </div>
          </Tooltip>
          <Typography desktop="caption" color="secondary">
            {t(`info.setup.status`)}
          </Typography>
        </>
      )}
      {!showTooltip && renderChecklist()}
    </div>
  );
};
