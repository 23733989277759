import { IconProps } from ".";

export const TrophyIcon = ({ className, size = 32 }: IconProps) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.1713 16.9008C7.32181 15.0947 7.68103 7.54771 8.46682 4H23.9581C24.0703 5.82761 24.0254 10.3214 22.9477 13.6756C21.6007 17.8683 18.2331 19.1584 12.1713 16.9008Z"
        stroke="#25262C"
        strokeWidth="2"
      />
      <path
        d="M14.9091 18C14.803 18.8205 14.2727 20.7692 13 22M18.0909 18C17.8788 18.8205 17.9636 20.7692 20 22"
        stroke="#25262C"
        strokeWidth="2"
      />
      <path
        d="M23.9736 5.15174C25.2844 4.91726 28.6269 4.87038 28.8891 6.55864C29.2168 8.66897 29.2168 13.9448 22.9903 14.6483M8.5711 5.15176C6.1679 4.9173 3.00001 4.44838 3 8.3173C2.99998 12.6678 5.62184 14.6483 9.55435 15"
        stroke="#25262C"
        strokeWidth="2"
      />
      <path
        d="M23 22H9.34146L9 27.6667L23 28V22Z"
        stroke="#25262C"
        strokeWidth="2"
      />
    </svg>
  );
};
