import classNames from "classnames";
import { ReactNode } from "react";
import "./Typography.scss";

type Colors = "primary" | "secondary" | "error" | "coal" | "light";
export type TypographyVariant =
  | "display1"
  | "display2"
  | "h1"
  | "h2"
  | "h3"
  | "handwrittenH1"
  | "handwrittenH2"
  | "body1"
  | "body2"
  | "button1" // button medium
  | "button2" // button small
  | "label1"
  | "label2"
  | "label3"
  | "caption";

export interface TypographyProps {
  children: string | ReactNode;
  desktop: TypographyVariant;
  tagVariant?: "h1" | "h2" | "h3" | "h4" | "h5" | "p" | "span";
  className?: string;
  mobile?: TypographyVariant;
  weight?: "bold" | "regular";
  fontStyle?: "normal" | "italic";
  color?: Colors;
}

export const Typography = ({
  children,
  className,
  tagVariant = "p",
  weight,
  desktop,
  mobile,
  color = "primary",
  fontStyle = "normal",
  ...props
}: TypographyProps) => {
  const Tag = tagVariant;
  const classes = classNames(
    className,
    `Typography Typography__desktop--${desktop} Typography__mobile--${
      mobile ? mobile : desktop
    }`,
    {
      "Typography--regular": weight === "regular",
      "Typography--bold": weight === "bold",
      "Typography--secondary": color === "secondary",
      "Typography--coal": color === "coal",
      "Typography--error": color === "error",
      "Typography--light": color === "light",
      "Typography--italic": fontStyle === "italic",
    }
  );
  return (
    <Tag className={classes} {...props}>
      {children}
    </Tag>
  );
};
