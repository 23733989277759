import { MoreVert } from "@mui/icons-material";
import { Grid } from "@mui/material";
import { Box, Skeleton } from "@mui/material";
import classNames from "classnames";
import { useState } from "react";
import useTranslation from "../../hooks/useTranslation";
import SurveyDispatch from "../../models/surveyDispatch";
import { WorkplaceCultureIllustration } from "../../styles/illustrations/WorkplaceCultureIllustration";
import { grey4 } from "../../styles/theme";
import { getFormattedDate } from "../../utils/formatters";
import { ProtectedRoutes } from "../../utils/routes.utils";
import { ScoreArc } from "../ScoreArc/ScoreArc";
import { SurveyDispatchEngagement } from "../SurveyDispatchEngagement/SurveyDispatchEngagement";
import { SurveyPreviewCard } from "../SurveyPreviewCard/SurveyPreviewCard";
import { SurveyPublicLink } from "../SurveyPublicLink/SurveyPublicLink";
import { SurveyReadyStatus } from "../SurveyReadyStatus/SurveyReadyStatus";
import { SurveyRecipients } from "../SurveyRecipients/SurveyRecipients";
import { SurveySettings } from "../SurveySettings/SurveySettings";
import { Button } from "../ui/Button/Button";
import { Card } from "../ui/Card/Card";
import { IMenuItem, Menu } from "../ui/Menu/Menu";
import { Modal } from "../ui/Modal/Modal";
import { Typography } from "../ui/Typography/Typography";
import { EditIcon } from "../ui/icons/EditIcon";
import { PreviewIcon } from "../ui/icons/PreviewIcon";
import { ShareIcon } from "../ui/icons/ShareIcon";
import "./SurveyDispatchCard.scss";

interface SurveyDispatchCardProps {
  surveyDispatch?: SurveyDispatch;
  isLoading?: boolean;
  error?: boolean;
  isWidget?: boolean;
  onClick?: () => void;
  onSurveyUpdate?: () => void;
}

export const SurveyDispatchCard = ({
  surveyDispatch,
  isLoading = false,
  error = false,
  onClick,
  onSurveyUpdate,
}: SurveyDispatchCardProps) => {
  const { t, i18n } = useTranslation("workplaceCulturePage");
  const [isModuleOpen, setIsModuleOpen] = useState(false);
  const [anchor, setAnchor] = useState<HTMLElement>();
  const [isSurveySetting, setIsSurveySetting] = useState(false);
  const [isPreviewSurvey, setIsPreviewSurvey] = useState(false);
  const [isShareSurvey, setIsShareSurvey] = useState(false);
  const surveyIsClickable = surveyDispatch && surveyDispatch.hasNotLaunched();

  const shouldShowEngagement =
    !error && !isLoading && !surveyDispatch?.hasNotLaunched();
  const shouldShowScore = !!surveyDispatch?.score && surveyDispatch?.score > 0;
  const shouldShowDates =
    !!surveyDispatch?.dueDate && !!surveyDispatch?.launchDate;
  const shouldShowActionButton =
    !error && !isLoading && !surveyDispatch?.isActive();

  const menuItems: IMenuItem[] = [
    {
      text: t("settings.dueDate"),
      icon: <EditIcon size={24} color={grey4} />,
      onClick: () => {
        setIsModuleOpen(true);
        setIsSurveySetting(true);
      },
    },
    {
      text: surveyDispatch?.isPublic
        ? t("settings.share")
        : t("settings.addRecipients"),
      icon: <ShareIcon size={24} color={grey4} />,
      onClick: () => {
        setIsModuleOpen(true);
        setIsShareSurvey(true);
      },
    },
    {
      text: t("settings.preview"),
      icon: <PreviewIcon size={24} color={grey4} />,
      onClick: () => {
        setIsModuleOpen(true);
        setIsPreviewSurvey(true);
      },
    },
  ];

  const handleOpenMenu = (e: React.MouseEvent<HTMLElement>) => {
    setAnchor(e.currentTarget);
  };

  const handleModalClose = () => {
    setIsModuleOpen(false);
    setIsPreviewSurvey(false);
    setIsShareSurvey(false);
    setIsSurveySetting(false);
  };

  const renderIcon = () => {
    if (isLoading) {
      return <Skeleton className="SurveyDispatchCard__card__title__icon" />;
    }

    return <WorkplaceCultureIllustration />;
  };

  const renderTitle = () => {
    if (isLoading) {
      return <Skeleton variant="text" width="150px" />;
    }

    if (error) {
      return <Typography desktop="h3">{t(`status.error`)}</Typography>;
    }

    return (
      <Typography desktop="body1" weight="bold">
        {surveyDispatch?.name}
      </Typography>
    );
  };

  const renderDates = () => {
    if (isLoading) {
      return <Skeleton variant="text" />;
    }

    if (!surveyDispatch || !shouldShowDates) {
      return null;
    }

    const formattedLaunchDate = getFormattedDate(
      surveyDispatch.launchDate || "",
      t,
      i18n.language
    );

    const formattedDueDate = getFormattedDate(
      surveyDispatch.dueDate || "",
      t,
      i18n.language
    );

    return (
      <div className="SurveyDispatchCard__card__info__dates">
        <Typography desktop="body2" color="secondary">
          {t(
            surveyDispatch.hasNotLaunched()
              ? "info.launchDate"
              : "info.launchDatePast",
            { date: formattedLaunchDate }
          )}
        </Typography>
        <Typography desktop="body2" color="secondary">
          {t(surveyDispatch.isClosed() ? "info.dueDatePast" : "info.dueDate", {
            date: formattedDueDate,
          })}
        </Typography>
      </div>
    );
  };

  const renderMenu = () => (
    <Box>
      <div className="SurveyDispatchCard__card__actions">
        <Button
          size="small"
          aria-label="survey status edit"
          aria-controls="survey-status-edit"
          aria-haspopup="true"
          onClick={handleOpenMenu}
          icon
          variant="outlined"
        >
          <MoreVert className="SurveyDispatchCard__card__actions__edit__icon" />
        </Button>
      </div>
      <Menu
        id="survey-status-edit"
        anchorEl={anchor}
        onClose={() => setAnchor(undefined)}
        menuItems={menuItems}
        open={!!anchor}
        sx={{ display: "block" }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: 57,
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        light
      />
    </Box>
  );

  const renderShareSurvey = () => {
    if (surveyDispatch?.isPublic) {
      return <SurveyPublicLink surveyDispatch={surveyDispatch} />;
    }
    return (
      <SurveyRecipients
        onSurveyUpdate={onSurveyUpdate}
        surveyDispatch={surveyDispatch}
        isLoading={isLoading}
        error={error}
      />
    );
  };

  const classes = classNames("SurveyDispatchCard__card", {
    "SurveyDispatchCard__card--clickable": surveyIsClickable,
  });

  return (
    <Card className={classes} containerClassName="SurveyDispatchCard">
      <Grid container justifyContent="space-between" alignItems={"center"}>
        <Grid item md={1} sm={1} xs={2}>
          {renderIcon()}
        </Grid>
        <Grid item md={3} sm={11} xs={10}>
          {renderTitle()}
        </Grid>
        <Grid
          item
          md={surveyDispatch?.isActive() ? 7 : 6}
          sm={9}
          xs={9}
          className="SurveyDispatchCard__card__info"
        >
          {isLoading && <Skeleton variant="text" />}
          {renderDates()}
          {shouldShowEngagement && surveyDispatch && (
            <SurveyDispatchEngagement surveyDispatch={surveyDispatch} />
          )}
          {shouldShowScore && (
            <ScoreArc
              score={surveyDispatch?.score || 0}
              size="xSmall"
              label={t("status.score")}
            />
          )}
          {surveyDispatch?.hasNotLaunched() && (
            <SurveyReadyStatus
              className="SurveyDispatchCard__card__info__readyStatus"
              surveyDispatch={surveyDispatch}
              small
            />
          )}
        </Grid>
        <Grid
          item
          md={surveyDispatch?.isActive() ? 1 : 2}
          sm={3}
          xs={3}
          className="SurveyDispatchCard__card__actions"
        >
          {surveyDispatch?.isActive() && renderMenu()}
          {shouldShowActionButton && (
            <Button
              variant="contained"
              color="secondary"
              small
              href={
                surveyDispatch?.hasNotLaunched()
                  ? `${ProtectedRoutes.LAUNCH}/${surveyDispatch?.id}`
                  : `${ProtectedRoutes.WORKPLACE_CULTURE}/${surveyDispatch?.id}`
              }
            >
              {surveyDispatch?.hasNotLaunched()
                ? t("actions.edit")
                : t("actions.details")}
            </Button>
          )}
        </Grid>
      </Grid>

      <Modal
        open={isModuleOpen}
        onClose={handleModalClose}
        light
        className="SurveyDispatchCard__modal"
      >
        {isSurveySetting && surveyDispatch && (
          <SurveySettings dispatch={surveyDispatch} />
        )}
        {isPreviewSurvey && (
          <SurveyPreviewCard
            dispatchId={surveyDispatch?.id}
            isLoading={isLoading}
          />
        )}
        {isShareSurvey && renderShareSurvey()}
      </Modal>
    </Card>
  );
};
