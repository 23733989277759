import { CustomQuestion, ICustomQuestionOption } from "../types/survey";

export const isDuplicateOptions = (options: ICustomQuestionOption[]) => {
  const optionTexts: string[] = [];
  const optionLabels: string[] = [];
  return options.some((option) => {
    if (
      optionTexts.includes(option.text.trim()) ||
      optionLabels.includes(option.label.trim())
    ) {
      return true;
    }
    optionTexts.push(option.text);
    optionLabels.push(option.label);
    return false;
  });
};

export const getCustomQuestionsNotInDispatch = (
  questions: CustomQuestion[],
  dispatchId: string | undefined
): CustomQuestion[] => {
  return (
    questions.filter((cq) => {
      if (!dispatchId) return true;

      // Otherwise, exclude any job roles with exactly one dispatchId equal to the dispatchId.
      return !(
        cq.dispatch_ids?.length === 1 && cq.dispatch_ids.includes(dispatchId)
      );
    }) || []
  );
};
