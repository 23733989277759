import { roseOrange } from "../../../styles/theme";
import { IconProps } from "./";

export const WarningIcon = ({
  className,
  onClick,
  color = roseOrange,
  size = 24,
}: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <path
        d="M3.01608 18.1244C3.3908 21.7161 21.0317 20.8108 21.0161 18.8428C21.0005 16.8748 19.1255 13.4998 17.6255 10.1248C16.1255 6.7498 12.9345 3.5786 12.0161 3.7572C11.0309 3.94879 7.87527 8.9999 6.75027 11.6249C5.62527 14.2499 2.83661 16.4042 3.01608 18.1244Z"
        stroke={color}
        strokeWidth="2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.9487 9.31675C13.1235 8.79285 12.8405 8.22645 12.3166 8.05167C11.7927 7.87689 11.2263 8.1599 11.0515 8.6838C10.7951 9.45227 10.9033 10.3294 10.9855 10.9952L11.0026 11.1354C11.0961 11.9107 11.1649 12.6021 11.0207 13.2971C10.9085 13.8378 11.2559 14.3672 11.7966 14.4794C12.3374 14.5916 12.8667 14.2442 12.979 13.7035C13.198 12.648 13.0793 11.6519 12.9883 10.8961C12.8856 10.0444 12.8482 9.61805 12.9487 9.31675ZM12 15.5003C11.1716 15.5003 10.5 16.1719 10.5 17.0003C10.5 17.8288 11.1716 18.5003 12 18.5003H12.01C12.8384 18.5003 13.51 17.8288 13.51 17.0003C13.51 16.1719 12.8384 15.5003 12.01 15.5003H12Z"
        fill={color}
      />
    </svg>
  );
};
