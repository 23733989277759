import {
  Checkbox,
  Chip,
  FormControl,
  IconButton,
  InputLabel,
  ListSubheader,
  Select as MUISelect,
  SelectProps as MUISelectProps,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import classNames from "classnames";
import { ReactNode, useState } from "react";
import { grey4 } from "../../../styles/theme";
import { Typography } from "../Typography/Typography";
import { IconTypes } from "../icons";
import { CloseIcon } from "../icons/CloseIcon";
import { Icon } from "../icons/Icon";
import "./Select.scss";

export interface SelectItem {
  label: string;
  value: any;
}

export interface SelectProps extends MUISelectProps {
  closeOnClick?: boolean;
  icon?: IconTypes;
  itemClassName?: (itemValue: any) => string;
  items?: string[] | SelectItem[];
  renderAsChips?: boolean;
  showValueOrder?: boolean;
}

export const Select = ({
  children,
  className,
  closeOnClick = true,
  defaultOpen = false,
  icon,
  id,
  items,
  itemClassName,
  label,
  labelId,
  multiple,
  onChange,
  renderAsChips = false,
  renderValue,
  showValueOrder = false,
  size = "small",
  value,
  ...rest
}: SelectProps) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  // check if items have key values or are a simple array of strings
  const hasKeyValues =
    items?.[0]?.hasOwnProperty("value") && items?.[0]?.hasOwnProperty("label");

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleChange = (
    event: SelectChangeEvent<unknown>,
    child: ReactNode
  ) => {
    if (closeOnClick) {
      handleClose();
    }

    onChange?.(event, child);
  };

  const renderMultipleValue = (selectedValues: unknown) => {
    if (!Array.isArray(selectedValues)) {
      return null;
    }

    if (selectedValues.length > 2) {
      return (
        <Typography desktop="body2">
          {`${selectedValues.length} values selected`}
        </Typography>
      );
    }

    if (hasKeyValues) {
      // find the selected labels in the items array
      selectedValues = (selectedValues as string[]).map((selectedValue) => {
        const selectedItem = (items as SelectItem[]).find(
          (item) => item.value === selectedValue
        );
        return selectedItem ? selectedItem.label : selectedValue;
      });
    }

    return (
      <Typography desktop="body2">
        {(selectedValues as string[]).join(", ")}
      </Typography>
    );
  };

  const renderChipValue = (selectedValues: unknown) => {
    if (!Array.isArray(selectedValues)) return null;

    return (
      <div className="Select__chips">
        {selectedValues.map((selectedValue) => {
          const selectedItem = (items as SelectItem[]).find(
            (item) => item.value === selectedValue
          );
          const chipLabel = selectedItem ? selectedItem.label : selectedValue;

          return (
            <Chip
              key={selectedValue}
              label={chipLabel}
              className="Select__chip"
              size="small"
              color="info"
            />
          );
        })}
      </div>
    );
  };

  const renderCheckbox = (
    isChecked: boolean,
    item: string | SelectItem,
    itemValue: any
  ) => {
    if (!multiple) {
      return undefined;
    }

    if (!showValueOrder || !isChecked) {
      return <Checkbox checked={isChecked} value={itemValue} />;
    }

    const valueOrder = (value as string[]).findIndex((val) =>
      hasKeyValues ? val === (item as SelectItem).value : val === item
    );

    return <div className="Select__valueOrder">{valueOrder + 1}</div>;
  };

  const handleClear = () => {
    const emptyValue = multiple ? [] : "";
    onChange?.(
      {
        target: { value: emptyValue },
      } as SelectChangeEvent<unknown>,
      null
    );
  };

  const renderClearButton = () => {
    if (!multiple || !(value as any[])?.length) return null;
    return (
      <IconButton
        size="small"
        data-testid="clear-button"
        onClick={(event) => {
          event.stopPropagation(); // Prevent dropdown from opening
          handleClear();
        }}
        sx={{
          position: "absolute",
          right: 32,
          top: "50%",
          transform: "translateY(-50%)",
          padding: 0,
        }}
      >
        <CloseIcon size={16} color={grey4} />
      </IconButton>
    );
  };

  const inputClasses = classNames("Select__input", {
    "Select__input--open": isOpen,
  });

  const containerClasses = classNames("Select", className, {
    "Select--noLabel": !label,
  });

  return (
    <FormControl className={containerClasses} fullWidth size={size}>
      <InputLabel className="Select__label" id={labelId || id}>
        {label}
      </InputLabel>
      <MUISelect
        className={inputClasses}
        defaultOpen={defaultOpen}
        id={id}
        labelId={labelId || id}
        multiple={multiple}
        onChange={handleChange}
        onClose={handleClose}
        onOpen={handleOpen}
        renderValue={
          multiple
            ? renderAsChips
              ? renderChipValue
              : renderMultipleValue
            : undefined
        }
        value={value}
        {...rest}
      >
        {items?.map((item, index) => {
          const key = `${item}${index}`;
          const itemValue = hasKeyValues
            ? (item as SelectItem).value
            : (item as string);
          const itemLabel = hasKeyValues
            ? (item as SelectItem).label
            : (item as string);
          const isChecked =
            (multiple && Array.isArray(value) && value.includes(itemValue)) ||
            false;
          if (itemValue === "category") {
            return (
              <ListSubheader
                className="Select__label__category"
                key={itemLabel}
              >
                {itemLabel}
              </ListSubheader>
            );
          }

          return (
            <MenuItem
              value={itemValue}
              key={key}
              className={classNames("Select__item", itemClassName?.(itemValue))}
            >
              {!renderAsChips && renderCheckbox(isChecked, item, itemValue)}
              <span>{itemLabel}</span>
              {icon && <Icon type={IconTypes[icon]} size={8} color="info" />}
            </MenuItem>
          );
        })}
        {!items && children}
      </MUISelect>
      {renderClearButton()}
    </FormControl>
  );
};
