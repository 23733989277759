import { AssetResponse, TrainingModuleResponse } from "../types/training";
import TrainingTouchpoint from "./trainingTouchpoint";

class TrainingModule {
  assets: AssetResponse[];
  audience: string;
  canDownload: boolean;
  canView: boolean;
  description: string;
  duration: string;
  isReady: boolean;
  languages: string[];
  referenceId: string;
  suggested_order?: number;
  tags: string[];
  title: string;
  touchPoints: TrainingTouchpoint[];
  gradient?: string;

  constructor(parameters: TrainingModuleResponse) {
    this.assets = parameters.assets;
    this.audience = parameters.audience;
    this.canDownload = parameters.can_download; // Has the subscription to download scorm files or toolboxes
    this.canView = parameters.can_view; // Has the subscription to view the module and download material
    this.description = parameters.description;
    this.duration = parameters.duration;
    this.isReady = parameters.is_ready;
    this.languages = parameters.languages;
    this.referenceId = parameters.reference_id;
    this.suggested_order = parameters.suggested_order;
    this.tags = parameters.tags;
    this.title = parameters.title;
    this.touchPoints = parameters.touchpoints?.map(
      (touchPointResponse) => new TrainingTouchpoint(touchPointResponse)
    );
  }

  isAccessible = () => {
    return this.isReady && this.canView;
  };

  isComingSoon = () => {
    return !this.isReady && this.canView;
  };

  isNotAvailable = () => {
    return !this.canView;
  };
}

export default TrainingModule;
