export const scoringChapters = [
  {
    id: "S.CHA.002",
    name: "Inclusive Leadership",
    description: "Employee experiences: How inclusive is the leadership?",
    image:
      "http://localhost:4567/alda-api-local-bucket/content/icons/InclusiveLeadership.png",
    demographic: {
      id: "",
      name: "",
      scores: {
        total_score: 2.16,
        filters: [],
      },
    },
  },
  {
    id: "S.CHA.003",
    name: "Workplace Dynamics & Psychological Safety",
    description:
      "Communications, attitudes, sexual harassment, bullying, discrimination and more.",
    image:
      "http://localhost:4567/alda-api-local-bucket/content/icons/WorkplaceDynamics.png",
    demographic: {
      id: "",
      name: "",
      scores: {
        total_score: 1.7,
        filters: [],
      },
    },
  },
  {
    id: "S.CHA.004",
    name: "Authenticity & Masking",
    description:
      "To what extent employees feel that they can be their authentic selves at work.",
    image:
      "http://localhost:4567/alda-api-local-bucket/content/icons/AuthenticityAndMasking.png",
    demographic: {
      id: "",
      name: "",
      scores: {
        total_score: 4.1,
        filters: [],
      },
    },
  },
];

export const emptyScoringChapters = [
  {
    id: "S.CHA.002",
    name: "Inclusive Leadership",
    description: "Employee experiences: How inclusive is the leadership?",
    image:
      "http://localhost:4567/alda-api-local-bucket/content/icons/InclusiveLeadership.png",
    demographic: {
      id: "",
      name: "",
      scores: {
        total_score: undefined,
        filters: [],
      },
    },
  },
  {
    id: "S.CHA.003",
    name: "Workplace Dynamics & Psychological Safety",
    description:
      "Communications, attitudes, sexual harassment, bullying, discrimination and more.",
    image:
      "http://localhost:4567/alda-api-local-bucket/content/icons/WorkplaceDynamics.png",
    demographic: {
      id: "",
      name: "",
      scores: {
        total_score: undefined,
        filters: [],
      },
    },
  },
  {
    id: "S.CHA.004",
    name: "Authenticity & Masking",
    description:
      "To what extent employees feel that they can be their authentic selves at work.",
    image:
      "http://localhost:4567/alda-api-local-bucket/content/icons/AuthenticityAndMasking.png",
    demographic: {
      id: "",
      name: "",
      scores: {
        total_score: undefined,
        filters: [],
      },
    },
  },
];
