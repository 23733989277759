import { Grid } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SurveyService } from "../api/SurveyService";
import { WorkplaceCultureService } from "../api/WorkplaceCultureService";
import { InsightsCard } from "../components/InsightsCard/InsightsCard";
import { QuestionBreakdown } from "../components/QuestionBreakdown/QuestionBreakdown";
import { WorkplaceCultureOverview } from "../components/WorkplaceCultureOverview/WorkplaceCultureOverview";
import { GetSurveyDispatchResponse } from "../types/survey";
import {
  GetSurveyChapterInsightResponse,
  GetSurveyChapterScoringResponse,
  GetSurveyQuestionsBreakdownResponse,
  SurveyChapterInsight,
  SurveyChapterScoring,
  SurveyQuestionResponseBreakdown,
} from "../types/workplaceCulture";
import {
  OVERVIEW_FILTER_ID,
  isSurveyClosed,
} from "../utils/WorkplaceCulture.utils";

export const WorkplaceCultureChapterPage = () => {
  const surveyService = new SurveyService();
  const workplaceCultureService = new WorkplaceCultureService();
  const [chapterScoring, setChapterScoring] = useState<SurveyChapterScoring>();
  const [chapterQuestionResponses, setChapterQuestionResponses] = useState<
    SurveyQuestionResponseBreakdown[]
  >([]);
  const [selectedDemographicFilter, setSelectedDemographicFilter] =
    useState<string>(OVERVIEW_FILTER_ID);
  const [totalEngagement, setTotalEngagement] = useState<number>(0);
  const [chapterInsights, setChapterInsights] = useState<
    SurveyChapterInsight[]
  >([]);

  const { dispatchId, chapterId } = useParams();
  const navigate = useNavigate();

  const {
    data: surveyDispatchData,
    isLoading: surveyDispatchLoading,
    error: surveyDispatchError,
  } = useQuery<GetSurveyDispatchResponse, AxiosError>(
    [`surveyDispatch-${dispatchId}`],
    () => surveyService.getDispatch(dispatchId as string)
  );

  const {
    data: surveyChapterScoringData,
    isLoading: surveyChapterScoringLoading,
    error: surveyChapterScoringError,
    refetch: surveyChapterScoringRefetch,
  } = useQuery<GetSurveyChapterScoringResponse, AxiosError>(
    [
      `surveyChapterScoring-${surveyDispatchData?.id}`,
      { filterId: selectedDemographicFilter },
    ],
    () =>
      workplaceCultureService.getSurveyChapterScoring(
        surveyDispatchData?.id || "",
        selectedDemographicFilter
      ),
    {
      enabled: !!surveyDispatchData?.id,
    }
  );

  const {
    data: surveyQuestionsScoring,
    isLoading: surveyQuestionsScoringLoading,
    error: surveyQuestionsScoringError,
  } = useQuery<GetSurveyQuestionsBreakdownResponse, AxiosError>(
    [`surveyQuestionsChapterScoring-${surveyDispatchData?.id}`],
    () =>
      workplaceCultureService.getSurveyQuestionsScoring(
        surveyDispatchData?.id || ""
      ),
    {
      enabled: !!surveyDispatchData?.id,
    }
  );

  const {
    data: surveyChapterInsightsData,
    isLoading: chapterInsightsLoading,
    error: chapterInsightsError,
  } = useQuery<GetSurveyChapterInsightResponse, AxiosError>(
    [`surveyChapterInsights-${surveyDispatchData?.id}`],
    () =>
      workplaceCultureService.getSurveyChapterInsights(
        surveyDispatchData?.id || ""
      ),
    {
      enabled: !!surveyDispatchData?.id,
    }
  );

  useEffect(() => {
    if (surveyChapterInsightsData) {
      const surveyChapterInsights = surveyChapterInsightsData.filter(
        (insight) => insight.chapter_id === chapterId
      );
      setChapterInsights(surveyChapterInsights);
    }
  }, [surveyChapterInsightsData, chapterId]);

  useEffect(() => {
    if (surveyChapterScoringData) {
      const chapter = surveyChapterScoringData.chapters.find(
        (chapter) => chapter.id === chapterId
      );

      if (chapter) {
        setChapterScoring(chapter);
      } else {
        navigate("/404");
      }
    }
  }, [surveyChapterScoringData, chapterId, navigate]);

  useEffect(() => {
    if (surveyQuestionsScoring) {
      setTotalEngagement(surveyQuestionsScoring.total_engagement);
      const chapterResponses = surveyQuestionsScoring.chapters.find(
        (chapter) => chapter.id === chapterId
      );

      if (chapterResponses?.questions) {
        setChapterQuestionResponses(chapterResponses.questions);
      } else {
        navigate("/404");
      }
    }
  }, [surveyQuestionsScoring, chapterId, navigate]);

  const handleSurveyScoringRefetch = (filterId: string) => {
    setSelectedDemographicFilter(filterId);
    surveyChapterScoringRefetch();
  };

  return (
    <Grid container spacing={3} className="WorkplaceCultureChapterPage">
      <Grid item xs={12}>
        <WorkplaceCultureOverview
          title={chapterScoring?.name || ""}
          scoringData={chapterScoring?.demographic}
          isLoading={
            !!surveyDispatchError
              ? surveyDispatchLoading
              : surveyChapterScoringLoading
          }
          error={!!surveyDispatchError || !!surveyChapterScoringError}
          hasEnoughAnswers={
            chapterScoring?.demographic?.scores.total_score !== null
          }
          onScoringRefetch={handleSurveyScoringRefetch}
          selectedDemographicFilter={selectedDemographicFilter}
          isInChapter={true}
        />
      </Grid>
      <Grid item xs={12}>
        <InsightsCard
          insights={chapterInsights}
          isLoading={chapterInsightsLoading}
          error={!!chapterInsightsError}
          surveyClosed={isSurveyClosed(surveyDispatchData?.due_date)}
        />
      </Grid>
      <Grid item xs={12}>
        <QuestionBreakdown
          chapterQuestionResponses={chapterQuestionResponses}
          totalEngagement={totalEngagement}
          isLoading={surveyQuestionsScoringLoading}
          error={!!surveyQuestionsScoringError}
        />
      </Grid>
    </Grid>
  );
};
