import SyncOutlinedIcon from "@mui/icons-material/SyncOutlined";
import { Skeleton } from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { IntegrationService } from "../../api/IntegrationService";
import { SurveyService } from "../../api/SurveyService";
import useTranslation from "../../hooks/useTranslation";
import SurveyDispatch from "../../models/surveyDispatch";
import { IntegrationResponse } from "../../types/integration";
import { AddressBookResponse } from "../../types/survey";
import { getFormattedDate } from "../../utils/formatters";
import { validateEmails } from "../../utils/validation";
import { SurveyEmailInput } from "../SurveyEmailInput/SurveyEmailInput";
import { SurveyEmailList } from "../SurveyEmailList/SurveyEmailList";
import { UploadFile } from "../UploadFile/UploadFile";
import { Button } from "../ui/Button/Button";
import { Card } from "../ui/Card/Card";
import { Modal } from "../ui/Modal/Modal";
import { Tab } from "../ui/Tabs/Tab";
import { Tabs } from "../ui/Tabs/Tabs";
import { Typography } from "../ui/Typography/Typography";
import { MailIcon } from "../ui/icons/MailIcon";
import "./SurveyRecipients.scss";

interface SurveyRecipientsProps {
  className?: string;
  onSurveyUpdate?: () => void;
  surveyDispatch?: SurveyDispatch;
  isLoading?: boolean;
  error?: boolean;
}

export const SurveyRecipients = ({
  className,
  onSurveyUpdate,
  surveyDispatch,
  isLoading,
  error,
}: SurveyRecipientsProps) => {
  const { i18n, t } = useTranslation("surveyLaunchPage");
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [emailList, setEmailList] = useState<string[]>([]);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [submitError, setSubmitError] = useState<string>("");
  const [submitSuccess, setSubmitSuccess] = useState<string>("");
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [recipients, setRecipients] = useState<string[]>([]);

  const surveyService = new SurveyService();
  const integrationService = new IntegrationService();

  const { data: hrEmailsData, error: hrEmailsError } = useQuery<
    AddressBookResponse,
    AxiosError
  >(["addressBook"], () => surveyService.getHREmails());

  const { error: integrationError } = useQuery<IntegrationResponse, AxiosError>(
    ["integrations"],
    () => integrationService.getIntegration()
  );
  useEffect(() => {
    if (surveyDispatch) {
      const emails = surveyDispatch.recipients;
      if (emails) {
        setRecipients(emails);
      }
    } else {
      setErrorMsg(t("shareLink.errors.fetchError") || "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surveyDispatch, error]);

  const editDispatchMutation = useMutation({
    mutationFn: () =>
      surveyService.editDispatch(
        surveyDispatch?.id || "",
        undefined,
        undefined,
        undefined,
        emailList ? emailList : []
      ),
  });

  const handleHRFetch = () => {
    if (hrEmailsData) {
      handleAddEmails(hrEmailsData.emails);
    }
    if (hrEmailsError) {
      setErrorMsg(t("shareLink.errors.fetchError") || "");
    }
  };

  const handleAddEmails = (emails: string[]) => {
    let { duplicates, toBeAdded, invalidEmails } = validateEmails(
      emailList,
      emails
    );
    if (duplicates.length > 0) {
      setErrorMsg(
        t("shareLink.errors.pastedAlreadyInList", {
          value: duplicates.join(", "),
        }) || ""
      );
    }
    if (invalidEmails.length > 0) {
      setErrorMsg(
        t("shareLink.errors.invalidEmails", {
          emails: invalidEmails.join(", "),
        }) || ""
      );
    }
    setEmailList([...emailList, ...toBeAdded]);
  };

  const handleSave = async () => {
    setErrorMsg("");
    await editDispatchMutation.mutateAsync(undefined, {
      onSuccess: (data) => {
        const invalid_emails = data.invalid_emails;
        if (invalid_emails.length > 0) {
          setSubmitError(
            t("shareLink.errors.invalidEmails", {
              emails: invalid_emails.join(", "),
            }) || ""
          );
        } else {
          setSubmitError("");
          setSubmitSuccess(t("shareLink.submitSuccess") || "");
          setRecipients(data.survey_dispatch.recipients);
        }
        setEmailList([]);
        onSurveyUpdate && onSurveyUpdate();
      },
      onError: () => {
        setSubmitSuccess("");
        setSubmitError(t("shareLink.errors.submit") || "");
      },
    });
  };

  const handleChangeTab = (newValue: number) => {
    setErrorMsg("");
    setSelectedTabIndex(newValue);
  };

  useEffect(() => {
    if (integrationError?.response?.status === 404 && !hrEmailsData) {
      setErrorMsg(t("shareLink.errors.noIntegration") || "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [integrationError]);

  const renderError = () => {
    return (
      <Typography tagVariant="h5" desktop="body1">
        {errorMsg || t("shareLink.error")}
      </Typography>
    );
  };

  const renderTabs = () => {
    return (
      <>
        {selectedTabIndex === 0 && (
          <>
            <div className="SurveyRecipients__card__panel__text">
              <Typography tagVariant="p" desktop="body1" weight="bold">
                {t("shareLink.fetch.title")}
              </Typography>
            </div>

            <Button
              variant="contained"
              color="secondary"
              small
              className="SurveyRecipients__card__panel__fetchButton"
              onClick={handleHRFetch}
              disabled={!hrEmailsData?.emails}
            >
              <SyncOutlinedIcon />
              {t("shareLink.fetch.label")}
            </Button>
          </>
        )}
        {selectedTabIndex === 1 && (
          <>
            <div className="SurveyRecipients__card__panel__text">
              <Typography tagVariant="p" desktop="body1" weight="bold">
                {t("shareLink.upload.title")}
              </Typography>

              <Typography tagVariant="p" desktop="body2" color="secondary">
                {t("shareLink.upload.info")}
              </Typography>
            </div>
            <UploadFile
              label={t("shareLink.upload.label")}
              onFileUpload={handleAddEmails}
              fileUploaded={!!submitSuccess}
            />
          </>
        )}
        {selectedTabIndex === 2 && (
          <>
            <div className="SurveyRecipients__card__panel__text">
              <Typography tagVariant="p" desktop="body1" weight="bold">
                {t("shareLink.type.title")}
              </Typography>
            </div>

            <SurveyEmailInput
              emailList={emailList || []}
              setEmailList={setEmailList}
              handleAddEmails={handleAddEmails}
              setError={setErrorMsg}
              submitSuccess={submitSuccess}
              setSubmitSuccess={setSubmitSuccess}
            />
          </>
        )}
      </>
    );
  };

  const classes = classNames("SurveyRecipients", className);
  return (
    <div>
      <div className={classes}>
        <Card className="SurveyRecipients__card">
          <>
            {isLoading && (
              <Skeleton variant="rounded" width={"100%"} height={"2rem"} />
            )}
            {error && renderError()}
            {!isLoading && !error && (
              <>
                <Tabs
                  className="SurveyRecipients__card__tabs"
                  selectedTabIndex={selectedTabIndex}
                  label="Survey-invite"
                >
                  <Tab
                    key={"sendOutSurveyTab0"}
                    selected={selectedTabIndex === 0}
                    label={t("shareLink.fetch.label")}
                    index={0}
                    onClick={handleChangeTab}
                    withIndicator
                  />
                  <Tab
                    key={"sendOutSurveyTab1"}
                    selected={selectedTabIndex === 1}
                    label={t("shareLink.upload.label")}
                    index={1}
                    onClick={handleChangeTab}
                    withIndicator
                  />
                  <Tab
                    key={"sendOutSurveyTab2"}
                    selected={selectedTabIndex === 2}
                    label={t("shareLink.type.label")}
                    index={2}
                    onClick={handleChangeTab}
                    withIndicator
                  />
                </Tabs>
                <div className="SurveyRecipients__card__panel">
                  {renderTabs()}
                  {errorMsg && (
                    <Typography
                      desktop="caption"
                      color="error"
                      className="SurveyRecipients__card__panel__error"
                    >
                      {errorMsg}
                    </Typography>
                  )}
                </div>
                {recipients && recipients.length > 0 && (
                  <Card
                    variant="outlined"
                    className="SurveyRecipients__card__status"
                    onClick={() => setModalOpen(true)}
                  >
                    <div className="SurveyRecipients__card__status__title">
                      <MailIcon className="SurveyRecipients__card__status__title__icon" />
                      <Typography tagVariant="p" desktop="body2">
                        {t("shareLink.addedEmails", {
                          count: recipients?.length,
                        })}
                      </Typography>
                    </div>
                  </Card>
                )}
                {emailList.length > 0 && (
                  <SurveyEmailList
                    emailList={emailList || []}
                    setEmailList={setEmailList}
                  />
                )}
                {emailList.length > 0 && (
                  <div className="SurveyRecipients__card__action">
                    <Button
                      variant="contained"
                      onClick={handleSave}
                      disabled={editDispatchMutation.isLoading}
                      loading={editDispatchMutation.isLoading}
                    >
                      {t("shareLink.save")}
                    </Button>
                    {emailList.length > 0 && !submitSuccess && !submitError && (
                      <Typography
                        tagVariant="p"
                        desktop="caption"
                        mobile="caption"
                      >
                        {t(
                          surveyDispatch?.launchDate
                            ? "shareLink.employeeNr"
                            : "shareLink.employeeNrNoDate",
                          {
                            count: emailList.length,
                            date: surveyDispatch?.launchDate
                              ? getFormattedDate(
                                  surveyDispatch.launchDate,
                                  t,
                                  i18n.language
                                )
                              : "",
                          }
                        )}
                      </Typography>
                    )}
                  </div>
                )}
                {submitSuccess && (
                  <Typography
                    tagVariant="p"
                    desktop="caption"
                    className="SurveyRecipients__text"
                  >
                    {submitSuccess}
                  </Typography>
                )}
                {submitError && (
                  <Typography
                    tagVariant="p"
                    desktop="caption"
                    color="error"
                    className="SurveyRecipients__text"
                  >
                    {submitError}
                  </Typography>
                )}
              </>
            )}
          </>
        </Card>
      </div>

      <Modal
        className="SurveyRecipients__modal"
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        title={
          <Typography tagVariant="h3" desktop="h3" weight="bold">
            {t("shareLink.recipients")}
          </Typography>
        }
      >
        <div>
          <ul className="SurveyRecipients__modal__list">
            {recipients &&
              recipients.sort().map((recipient, index) => (
                <li className="SurveyRecipients__modal__list__item" key={index}>
                  {recipient}
                </li>
              ))}
          </ul>
        </div>
      </Modal>
    </div>
  );
};
