import { isBefore } from "date-fns";
import { GetSurveyDispatchesResponse } from "../types/survey";
import SurveyDispatch from "./surveyDispatch";

export type DispatchStatus = "completed" | "active" | "draft";

export interface DispatchWithComputedStatus {
  dispatch: SurveyDispatch;
  computedStatus: DispatchStatus;
}

class SurveyDispatchManager {
  dispatches: SurveyDispatch[] = [];

  constructor(
    dispatchResponses: GetSurveyDispatchesResponse[],
    t: (str: string, options: any) => string
  ) {
    this.dispatches = dispatchResponses
      .sort((a, b) =>
        isBefore(
          a.launch_date ? new Date(a.launch_date) : new Date(),
          b.launch_date ? new Date(b.launch_date) : new Date()
        )
          ? -1
          : 1
      )
      .map((response) => {
        const dispatch = new SurveyDispatch(response);
        return dispatch;
      });
  }

  getDispatchesByStatus(): {
    activeDispatches: SurveyDispatch[];
    completedDispatches: SurveyDispatch[];
    draftDispatches: SurveyDispatch[];
  } {
    const activeDispatches: SurveyDispatch[] = [];
    const completedDispatches: SurveyDispatch[] = [];
    const draftDispatches: SurveyDispatch[] = [];

    this.dispatches.forEach((dispatch) => {
      if (dispatch.isClosed()) {
        completedDispatches.push(dispatch);
      } else if (dispatch.isInProgress() || dispatch.isReady()) {
        draftDispatches.push(dispatch);
      } else {
        activeDispatches.push(dispatch);
      }
    });

    const sortedActiveDispatches = activeDispatches.sort(
      (a, b) => (a.dueDate?.getTime() ?? 0) - (b.dueDate?.getTime() ?? 0)
    );

    const sortedCompletedDispatches = completedDispatches.sort(
      (a, b) => (b.dueDate?.getTime() ?? 0) - (a.dueDate?.getTime() ?? 0)
    );

    return {
      activeDispatches: sortedActiveDispatches,
      completedDispatches: sortedCompletedDispatches,
      draftDispatches,
    };
  }

  getLatest(): SurveyDispatch | undefined {
    return this.dispatches.length > 0
      ? this.dispatches[this.dispatches.length - 1]
      : undefined;
  }

  hasActiveDispatches(): boolean {
    return this.dispatches.some((dispatch) => dispatch.isActive());
  }

  hasDraftDispatches(): boolean {
    return this.dispatches.some(
      (dispatch) => dispatch.isReady() || dispatch.isInProgress()
    );
  }

  addDispatch(dispatchData: GetSurveyDispatchesResponse) {
    const dispatch = new SurveyDispatch(dispatchData);
    this.dispatches.push(dispatch);
  }

  canLaunchNewDispatch(): boolean {
    return !this.hasActiveDispatches() && !this.hasDraftDispatches();
  }
}

export default SurveyDispatchManager;
