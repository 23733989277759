import { AxiosInstance } from "axios";
import {
  AddressBookResponse,
  CreateSurveyDispatchResponse,
  CustomQuestionAddBody,
  CustomQuestionUpdateBody,
  GetJobRolesResponse,
  GetSurveyDispatchResponse,
  GetSurveyDispatchesResponse,
  GetSurveyJobLevels,
  GetSurveyResponse,
  PreviewSurveyResponse,
  SubmitSurveyBody,
  SurveyDispatchReminder,
  SurveyEmailBody,
  SurveyEmailLanguage,
  SurveyEmailResponse,
  UpdateJobRoleBody,
} from "../types/survey";
import { getApiClient } from "../utils/api.utils";

// TODO: get survey id from api
export const SURVEY_ID = "SUR.001";

export class SurveyService {
  client: AxiosInstance;

  constructor(useAuth = true) {
    this.client = getApiClient(useAuth);
  }

  /** SURVEY API CALLS */
  getSurvey = async (dispatchToken: string) => {
    const response = await this.client.get<GetSurveyResponse>(
      `/surveys/${dispatchToken}/start/`
    );

    return response.data;
  };

  previewSurvey = async (dispatchId?: string) => {
    if (!dispatchId || dispatchId === SURVEY_ID) {
      const response = await this.client.get<PreviewSurveyResponse>(
        `/surveys/${SURVEY_ID}/preview/`
      );

      return response.data;
    }

    const response = await this.client.get<PreviewSurveyResponse>(
      `/surveys/${SURVEY_ID}/dispatches/${dispatchId}/preview/`
    );

    return response.data;
  };

  submitSurvey = async (dispatchToken: string, body: SubmitSurveyBody) => {
    if (!body.answers) {
      throw Error("No answers in submitSurvey body");
    }

    const response = await this.client.post(
      `/surveys/${dispatchToken}/submit/`,
      body
    );

    return response.data;
  };

  /** DISPATCH API CALLS */

  getDispatches = async () => {
    const response = await this.client.get<GetSurveyDispatchesResponse[]>(
      `/surveys/${SURVEY_ID}/dispatches/`
    );

    return response.data;
  };

  getDispatch = async (dispatchId: string) => {
    const response = await this.client.get<GetSurveyDispatchResponse>(
      `/surveys/${SURVEY_ID}/dispatches/${dispatchId}`
    );

    return response.data;
  };

  createDispatch = async () => {
    const response = await this.client.post<CreateSurveyDispatchResponse>(
      `/surveys/${SURVEY_ID}/dispatches/`
    );

    return response.data;
  };

  editDispatch = async (
    dispatchId: string,
    launchDate?: Date,
    reminders?: SurveyDispatchReminder[],
    dueDate?: Date,
    recipients?: string[]
  ) => {
    const response = await this.client.put<CreateSurveyDispatchResponse>(
      `/surveys/${SURVEY_ID}/dispatches/${dispatchId}`,
      {
        launch_date: launchDate,
        reminders: reminders,
        due_date: dueDate,
        recipients: recipients,
      }
    );

    return response.data;
  };

  /** CUSTOM QUESTIONS API CALLS */

  getAllCustomQuestions = async () => {
    const response = await this.client.get(
      `/surveys/${SURVEY_ID}/custom-questions/`
    );

    return response.data;
  };

  getDispatchCustomQuestions = async (dispatchId: string) => {
    const response = await this.client.get(
      `/surveys/${SURVEY_ID}/dispatches/${dispatchId}/custom-questions/`
    );

    return response.data;
  };

  addCustomQuestion = async (
    dispatchId: string,
    body: CustomQuestionAddBody
  ) => {
    const response = await this.client.post(
      `/surveys/${SURVEY_ID}/dispatches/${dispatchId}/custom-questions/`,
      body
    );

    return response.data;
  };

  updateCustomQuestion = async (
    dispatchId: string,
    questionId: string,
    body: CustomQuestionUpdateBody
  ) => {
    const response = await this.client.put(
      `/surveys/${SURVEY_ID}/dispatches/${dispatchId}/custom-questions/${questionId}`,
      body
    );

    return response.data;
  };

  deleteCustomQuestion = async (questionId: string, dispatchId: string) => {
    const response = await this.client.delete(
      `/surveys/${SURVEY_ID}/dispatches/${dispatchId}/custom-questions/${questionId}`
    );

    return response.data;
  };

  /** JOB ROLES API CALLS */

  getSurveyJobLevels = async () => {
    const response = await this.client.get<GetSurveyJobLevels>(
      `/surveys/${SURVEY_ID}/job-levels/`
    );

    return response.data;
  };

  getTenantJobRoles = async () => {
    const response = await this.client.get<GetJobRolesResponse>(
      `/surveys/${SURVEY_ID}/job-roles/`
    );

    return response.data;
  };

  getDispatchJobRoles = async (dispatchId: string) => {
    const response = await this.client.get<GetJobRolesResponse>(
      `/surveys/${SURVEY_ID}/dispatches/${dispatchId}/job-roles/`
    );

    return response.data;
  };

  addDispatchJobRole = async (
    dispatchId: string,
    jobRole: UpdateJobRoleBody
  ) => {
    const response = await this.client.post(
      `/surveys/${SURVEY_ID}/dispatches/${dispatchId}/job-roles/`,
      jobRole
    );

    return response.data;
  };

  updateDispatchJobRole = async (
    dispatchId: string,
    jobRole: UpdateJobRoleBody
  ) => {
    const response = await this.client.put<UpdateJobRoleBody>(
      `/surveys/${SURVEY_ID}/dispatches/${dispatchId}/job-roles/${jobRole.id}`,
      jobRole
    );
    return response.data;
  };

  deleteJobRole = async (dispatchId: string, jobRoleId: string) => {
    const response = await this.client.delete(
      `/surveys/${SURVEY_ID}/dispatches/${dispatchId}/job-roles/${jobRoleId}`
    );

    return response.data;
  };

  /** OTHER API CALLS */

  getSupportedSurveyEmailLanguages = async () => {
    const response = await this.client.get<SurveyEmailLanguage[]>(
      `/surveys/survey-emails/languages/`
    );

    return response.data;
  };

  getSurveyEmail = async (dispatchId: string) => {
    const response = await this.client.get<SurveyEmailResponse>(
      `surveys/${SURVEY_ID}/dispatches/${dispatchId}/survey-emails/`
    );

    return response.data;
  };

  updateSurveyEmail = async (
    dispatchId: string,
    surveyEmailBody: SurveyEmailBody
  ) => {
    const response = await this.client.post(
      `surveys/${SURVEY_ID}/dispatches/${dispatchId}/survey-emails/`,
      surveyEmailBody
    );

    return response.data;
  };

  getHREmails = async () => {
    const response = await this.client.get<AddressBookResponse>(
      "/surveys/address-book/"
    );

    return response.data;
  };
}
