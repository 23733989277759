import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { TrainingService } from "../api/TrainingService";

export const useTrainingService = (trainingModuleId: string) => {
  const trainingService = new TrainingService();

  const { error: downloadError, refetch: refetchTrainingModule } = useQuery<
    Blob,
    AxiosError
  >(
    ["trainingDownload", trainingModuleId],
    () => trainingService.downloadTrainingModule(trainingModuleId),
    {
      refetchOnWindowFocus: false,
      enabled: false,
      retry: false,
    }
  );

  const downloadAsset = async (assetId: string) => {
    await trainingService.downloadAsset(trainingModuleId, assetId);
  };

  const downloadAllAssets = async () => {
    await trainingService.downloadAllAssets(trainingModuleId);
  };

  return {
    downloadAsset,
    downloadAllAssets,
    downloadError,
    refetchTrainingModule,
  };
};
