import { Grid, Skeleton } from "@mui/material";
import classNames from "classnames";
import { useState } from "react";
import { useAuth } from "../../hooks/useAuth";
import useTranslation from "../../hooks/useTranslation";
import SurveyDispatch from "../../models/surveyDispatch";
import { SubscriptionActions } from "../../types/user";
import { getFormattedDate } from "../../utils/formatters";
import { ProtectedRoutes } from "../../utils/routes.utils";
import { SurveyDispatchEngagement } from "../SurveyDispatchEngagement/SurveyDispatchEngagement";
import { SurveyLaunchButton } from "../SurveyLaunchButton/SurveyLaunchButton";
import { SurveyPreviewCard } from "../SurveyPreviewCard/SurveyPreviewCard";
import { SurveyPublicLink } from "../SurveyPublicLink/SurveyPublicLink";
import { SurveyReadyStatus } from "../SurveyReadyStatus/SurveyReadyStatus";
import { SurveyRecipients } from "../SurveyRecipients/SurveyRecipients";
import { SurveySettings } from "../SurveySettings/SurveySettings";
import { Button } from "../ui/Button/Button";
import { CircularProgressBar } from "../ui/CircularProgressBar/CircularProgressBar";
import { Modal } from "../ui/Modal/Modal";
import { Paper } from "../ui/Paper/Paper";
import { Tooltip } from "../ui/Tooltip/Tooltip";
import { Typography, TypographyVariant } from "../ui/Typography/Typography";
import { IconTypes } from "../ui/icons";
import { Icon } from "../ui/icons/Icon";
import "./SurveyDispatchStatusCard.scss";

interface SurveyDispatchStatusCardProps {
  className?: string;
  surveyDispatch?: SurveyDispatch;
  isLoading: boolean;
  onSurveyUpdate?: () => void;
}

export const SurveyDispatchStatusCard = ({
  className,
  surveyDispatch,
  isLoading,
  onSurveyUpdate,
}: SurveyDispatchStatusCardProps) => {
  const { t, i18n } = useTranslation("dashboardPage");
  const { authInfo } = useAuth();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSurveySetting, setIsSurveySetting] = useState(false);
  const [isPreviewSurvey, setIsPreviewSurvey] = useState(false);
  const [isShareSurvey, setIsShareSurvey] = useState(false);

  const handleModalClose = () => {
    setIsModalOpen(false);
    setIsPreviewSurvey(false);
    setIsShareSurvey(false);
    setIsSurveySetting(false);
  };

  const renderHeader = (
    title: string,
    actionTitle: string,
    actionId: string,
    action: () => void,
    icon: IconTypes,
    isMain: boolean = false,
    showAction: boolean = true
  ) => {
    const titleSizeDesktop = isMain ? "h3" : "body1";
    const titleSizeMobile = isMain ? "body1" : "body2";
    const classes = classNames("SurveyDispatchStatusCard__header", {
      "SurveyDispatchStatusCard__header--main": isMain,
    });

    return (
      <Grid container className={classes}>
        <Grid item xs={showAction ? 11 : 12}>
          {isLoading && <Skeleton height={20} width={50} />}
          <Typography
            desktop={titleSizeDesktop as TypographyVariant}
            mobile={titleSizeMobile as TypographyVariant}
            weight="bold"
          >
            {title}
          </Typography>
        </Grid>
        <Grid item xs={showAction ? 1 : 0}>
          {showAction && (
            <Tooltip title={actionTitle} arrow>
              <Button
                onClick={() => action()}
                data-testid={`action-${actionId}`}
                aria-label={actionTitle}
                aria-controls={`action-${actionId}`}
                aria-haspopup="true"
                variant="contained"
                color="secondary"
                xSmall
                icon
              >
                {<Icon type={icon} size={14} />}
              </Button>
            </Tooltip>
          )}
        </Grid>
      </Grid>
    );
  };

  const renderNotLaunched = () => {
    if (!surveyDispatch || !surveyDispatch.hasNotLaunched()) return null;

    const msg = surveyDispatch.isInProgress()
      ? t("workplaceCultureWidget.latestCard.inProgressMsg")
      : t("workplaceCultureWidget.latestCard.readyMsg", {
          launchDate: getFormattedDate(
            surveyDispatch.launchDate || "",
            t,
            i18n.language
          ),
        });

    return (
      <div>
        <div className="SurveyDispatchStatusCard__notLaunched">
          <Typography desktop="body2" mobile="caption">
            {msg}
          </Typography>
          <SurveyReadyStatus
            className="SurveyDispatchStatusCard__notLaunched__status"
            surveyDispatch={surveyDispatch}
          />
        </div>
      </div>
    );
  };

  const renderActions = () => {
    if (!surveyDispatch) return null;

    if (
      surveyDispatch.isActive() ||
      !authInfo.user?.hasAccess(SubscriptionActions.LAUNCH_SURVEY)
    ) {
      return <div></div>;
    }

    const actionDefinitions = {
      editSurvey: {
        id: "edit-survey",
        title: t("workplaceCulturePage:settings.editSurvey"),
        link: `${ProtectedRoutes.LAUNCH}/${surveyDispatch.id}`,
      },
      newSurvey: {
        id: "new-survey",
        title: t("workplaceCulturePage:settings.newSurvey"),
        link: undefined,
      },
    };

    const action = surveyDispatch.hasNotLaunched()
      ? actionDefinitions.editSurvey
      : actionDefinitions.newSurvey;

    const classes = classNames("SurveyDispatchStatusCard__actions", {
      "SurveyDispatchStatusCard__actions--notLaunched":
        surveyDispatch.hasNotLaunched(),
    });

    const ButtonElement = surveyDispatch.hasNotLaunched()
      ? Button
      : SurveyLaunchButton;

    return (
      <div className={classes}>
        <ButtonElement
          key={action.id}
          data-testid={`action-${action.id}`}
          aria-label={action.title}
          aria-controls={`action-${action.id}`}
          aria-haspopup="true"
          variant="contained"
          color={surveyDispatch.hasNotLaunched() ? "info" : "secondary"}
          small={!surveyDispatch.hasNotLaunched()}
          {...(action.link && { href: action.link })}
          {...(!surveyDispatch.hasNotLaunched() && {
            hasActiveDispatches: surveyDispatch.isActive(),
          })}
        >
          {action.title}
        </ButtonElement>
      </div>
    );
  };

  const renderSurveyPeriod = () => {
    if (!surveyDispatch || surveyDispatch.hasNotLaunched()) return null;

    const formattedLaunchDate = surveyDispatch.launchDate
      ? getFormattedDate(surveyDispatch.launchDate, t, i18n.language)
      : "";
    const formattedDueDate = surveyDispatch.dueDate
      ? getFormattedDate(surveyDispatch.dueDate, t, i18n.language)
      : "";

    const dueDateText = surveyDispatch.isClosed() ? "closed" : "dueDate";

    return (
      <Paper secondary className="SurveyDispatchStatusCard__period" compact>
        {renderHeader(
          t("workplaceCultureWidget.latestCard.activityPeriod"),
          t("workplaceCulturePage:settings.dueDate"),
          "due-date",
          () => {
            setIsSurveySetting(true);
            setIsModalOpen(true);
          },
          IconTypes.Edit,
          false,
          surveyDispatch.isActive()
        )}
        <div className="SurveyDispatchStatusCard__period__progress">
          <Grid container>
            <Grid
              item
              md={3}
              xs={12}
              className="SurveyDispatchStatusCard__period__progress__bar"
            >
              <CircularProgressBar
                progress={surveyDispatch.getProgress()}
                color="warning"
              />
            </Grid>
            <Grid item md={9} xs={12}>
              <Grid container>
                <Grid item xs={12}>
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <Typography desktop="caption">
                        {formattedLaunchDate}
                      </Typography>
                      <Typography
                        desktop="caption"
                        className="SurveyDispatchStatusCard__period__progress__dates"
                      >
                        {t("workplaceCultureWidget.latestCard.launchDate")}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography desktop="caption">
                        {formattedDueDate}
                      </Typography>
                      <Typography
                        desktop="caption"
                        className="SurveyDispatchStatusCard__period__progress__dates"
                      >
                        {t(`workplaceCultureWidget.latestCard.${dueDateText}`)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  md={12}
                  sx={{
                    display: {
                      xs: "none",
                      md: surveyDispatch.isClosed() ? "none" : "block",
                    },
                  }}
                >
                  <Typography
                    desktop="caption"
                    className="SurveyDispatchStatusCard__period__progress__reminder"
                  >
                    {t("workplaceCultureWidget.latestCard.reminders", {
                      count: surveyDispatch?.reminders.length,
                    })}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Paper>
    );
  };

  const renderEngagement = () => {
    if (!surveyDispatch || surveyDispatch.hasNotLaunched()) return null;

    const actionTitle = surveyDispatch.isPublic
      ? t("workplaceCulturePage:settings.share")
      : t("workplaceCulturePage:settings.addRecipients");

    return (
      <Paper secondary className="SurveyDispatchStatusCard__engagement" compact>
        {renderHeader(
          t("workplaceCultureWidget.latestCard.engagement"),
          actionTitle,
          "engagement",
          () => {
            setIsShareSurvey(true);
            setIsModalOpen(true);
          },
          IconTypes.Share,
          false,
          surveyDispatch.isActive()
        )}

        <SurveyDispatchEngagement surveyDispatch={surveyDispatch} />
      </Paper>
    );
  };

  const renderShareSurvey = () => {
    if (surveyDispatch?.isPublic) {
      return <SurveyPublicLink surveyDispatch={surveyDispatch} />;
    }
    return (
      <SurveyRecipients
        onSurveyUpdate={onSurveyUpdate}
        surveyDispatch={surveyDispatch}
        isLoading={isLoading}
      />
    );
  };

  const classes = classNames("SurveyDispatchStatusCard", className, {
    "SurveyDispatchStatusCard--noSurvey": !surveyDispatch,
  });

  return (
    <Paper className={classes}>
      {renderHeader(
        isLoading
          ? ""
          : t(
              `workplaceCultureWidget.latestCard.title${
                surveyDispatch?.getStatusLabel(false) || ""
              }`
            ),
        t("workplaceCulturePage:settings.preview"),
        "preview",
        () => {
          setIsPreviewSurvey(true);
          setIsModalOpen(true);
        },
        IconTypes.Preview,
        true,
        !isLoading &&
          authInfo.user?.hasAccess(SubscriptionActions.LAUNCH_SURVEY)
      )}

      <div className="SurveyDispatchStatusCard__content">
        {isLoading && <Skeleton height={200} />}
        {renderNotLaunched()}
        {renderActions()}
        {renderSurveyPeriod()}
        {renderEngagement()}
      </div>
      <Modal
        open={isModalOpen}
        onClose={handleModalClose}
        light
        className="SurveyDispatchCard__modal"
      >
        {isSurveySetting && surveyDispatch && (
          <SurveySettings
            dispatch={surveyDispatch}
            onSurveyUpdate={() => {
              onSurveyUpdate?.();
              handleModalClose();
            }}
          />
        )}
        {isPreviewSurvey && (
          <SurveyPreviewCard
            dispatchId={surveyDispatch?.id}
            isLoading={isLoading}
          />
        )}
        {isShareSurvey && renderShareSurvey()}
      </Modal>
    </Paper>
  );
};
