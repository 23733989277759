import classNames from "classnames";
import useTranslation from "../../hooks/useTranslation";
import SurveyDispatch from "../../models/surveyDispatch";
import { orangeColor, roseOrange } from "../../styles/theme";
import { ProgressBar } from "../ui/ProgressBar/ProgressBar";
import { Typography } from "../ui/Typography/Typography";
import { IconTypes } from "../ui/icons";
import { Icon } from "../ui/icons/Icon";
import "./SurveyDispatchEngagement.scss";

interface SurveyDispatchEngagementProps {
  surveyDispatch: SurveyDispatch;
}

export const SurveyDispatchEngagement = ({
  surveyDispatch,
}: SurveyDispatchEngagementProps) => {
  const { t } = useTranslation("dashboardPage");

  if (!surveyDispatch || surveyDispatch.hasNotLaunched()) return null;

  const engagementPercentage = surveyDispatch.getEngagementPercentage();
  const engagementText = surveyDispatch.isPublic
    ? t("workplaceCultureWidget.latestCard.engagementPublic", {
        engagement: surveyDispatch.engagement,
      })
    : t("workplaceCultureWidget.latestCard.engagementPrivate", {
        engagement: surveyDispatch.engagement,
        recipientsCount: surveyDispatch.recipientsCount,
      });

  const engagementClasses = classNames("SurveyDispatchEngagement__progress", {
    "SurveyDispatchEngagement__progress--public": surveyDispatch.isPublic,
  });

  return (
    <div className="SurveyDispatchEngagement">
      {engagementPercentage <= 100 && !surveyDispatch.isPublic && (
        <ProgressBar
          progress={surveyDispatch.getEngagementPercentage()}
          labelPosition={"center"}
          color={orangeColor}
        />
      )}
      <Typography
        className={engagementClasses}
        desktop={surveyDispatch.isPublic ? "body1" : "body2"}
        mobile={surveyDispatch.isPublic ? "body2" : "caption"}
        weight={surveyDispatch.isPublic ? "bold" : "regular"}
        color={surveyDispatch.isPublic ? "error" : "primary"}
      >
        {surveyDispatch.isPublic && (
          <Icon type={IconTypes.Audience} size={16} color={roseOrange} />
        )}
        {engagementText}
      </Typography>
    </div>
  );
};
