import { ProtectedRoutes } from "../../utils/routes.utils";

const isID = (val: string | undefined): boolean => {
  return !!val && /^[A-Za-z0-9._-]+$/.test(val);
};

/**
 * Returns an array of crumbs, where each crumb is { route: string, label: string }.
 */
export const parseBreadcrumbPath = (pathname: string) => {
  const segments = pathname
    .split("/")
    .filter((seg) => seg && `/${seg}` !== ProtectedRoutes.DASHBOARD);

  const crumbs: Array<{ route: string; label: string }> = [];
  const pathStack: string[] = [];

  for (let i = 0; i < segments.length; i++) {
    const current = segments[i];
    const next = segments[i + 1];

    // 1. WORKPLACE_CULTURE => always one crumb, plus an optional second if next is ID
    if (current === ProtectedRoutes.WORKPLACE_CULTURE.replace("/", "")) {
      pathStack.push(current);
      crumbs.push({
        route: "/" + pathStack.join("/"),
        label: ProtectedRoutes.WORKPLACE_CULTURE.replace("/", ""),
      });

      // If there's an ID next, create a second crumb
      if (isID(next)) {
        i++;
        pathStack.push(next);
        crumbs.push({
          route: "/" + pathStack.join("/"),
          label: `${ProtectedRoutes.WORKPLACE_CULTURE.replace("/", "")}/id`,
        });
      }
      continue;
    }

    // 2. CHAPTER or LAUNCH => only valid with an ID immediately after
    if (
      current === ProtectedRoutes.CHAPTER.replace("/", "") ||
      current === ProtectedRoutes.LAUNCH.replace("/", "")
    ) {
      if (isID(next)) {
        i++; // consume the ID, too
        pathStack.push(current, next);
        crumbs.push({
          route: "/" + pathStack.join("/"),
          label: `${current}/id`,
        });
      }
      continue;
    }

    // 3. Anything else => single crumb
    pathStack.push(current);
    crumbs.push({
      route: "/" + pathStack.join("/"),
      label: current,
    });
  }

  return crumbs;
};
