import { Paper as MUIPaper, PaperProps } from "@mui/material";
import classNames from "classnames";
import { ReactNode } from "react";
import "./Paper.scss";

export interface IPaperProps extends PaperProps {
  className?: string;
  children: ReactNode;
  showError?: boolean;
  secondary?: boolean;
  compact?: boolean;
}

export const Paper = ({
  className,
  children,
  showError = false,
  secondary = false,
  compact = false,
  ...rest
}: IPaperProps) => {
  const classes = classNames("Paper", className, {
    "Paper--error": showError,
    "Paper--secondary": secondary,
    "Paper--compact": compact,
  });
  return (
    <MUIPaper className={classes} {...rest}>
      {children}
    </MUIPaper>
  );
};
