import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { KeyboardEvent, useRef, useState } from "react";
import { CompanyService } from "../../api/CompanyService";
import useTranslation from "../../hooks/useTranslation";
import { TeamErrorCodes } from "../../types/company";
import { ErrorResponse } from "../../types/error";
import {
  EmailValidationError,
  filterEmptyEmails,
  validateEmail,
} from "../../utils/validation";
import { Button } from "../ui/Button/Button";
import { Input } from "../ui/Input/Input";
import { Snackbar } from "../ui/Snackbar/Snackbar";
import { Typography } from "../ui/Typography/Typography";
import { TrashIcon } from "../ui/icons/TrashIcon";
import "./TeamMemberInviteForm.scss";

interface TeamMemberInviteFormProps {
  onUserInviteUpdate: () => void;
  members: string[];
}
export const TeamMemberInviteForm = ({
  onUserInviteUpdate,
  members,
}: TeamMemberInviteFormProps) => {
  const { t } = useTranslation("settingsPage");
  const companyService = new CompanyService();
  const [emailList, setEmailList] = useState<string[]>([]);
  const [errors, setErrors] = useState<string[]>([]);
  const [focusId, setFocusId] = useState<number | null>(null);
  const inputRefs = useRef<{ [key: number]: HTMLInputElement | null }>({});
  const [submitSuccess, setSubmitSuccess] = useState<string>("");
  const [submitError, setSubmitError] = useState<string>("");
  const canSaveEmails =
    filterEmptyEmails(emailList).length > 0 &&
    !errors.some((error) => error !== "");

  const userInviteMutation = useMutation({
    mutationFn: () =>
      companyService.inviteMembers(filterEmptyEmails(emailList)),
  });

  const handleChange = (newValue: string, index: number) => {
    setSubmitError("");
    const newEmailList = [...emailList];
    newEmailList[index] = newValue;
    setEmailList(newEmailList);

    const newErrors = [...errors];
    newErrors[index] = "";
    setErrors(newErrors);
  };

  const isEmailValid = (email: string, index?: number) => {
    // Skip validation if the input is empty
    if (email === "") {
      if (index !== undefined) {
        const newErrors = [...errors];
        newErrors[index] = "";
        setErrors(newErrors);
      }
      return true;
    }

    const validationResult = validateEmail(email, emailList.concat(members));
    let errorMsg = "";

    if (validationResult === EmailValidationError.InvalidEmail) {
      errorMsg = t("members.errors.invalidEmail", { value: email }) || "";
    } else if (validationResult === EmailValidationError.AlreadyInList) {
      errorMsg = t("members.errors.alreadyInList", { value: email }) || "";
    }

    if (index !== undefined) {
      const newErrors = [...errors];
      newErrors[index] = errorMsg;
      setErrors(newErrors);
    }

    return !errorMsg;
  };

  const handleAdd = () => {
    const newIndex = emailList.length;
    const hasEmptyInput = emailList.some((email) => email === "");
    if (!hasEmptyInput) {
      setEmailList([...emailList, ""]);
      setFocusId(newIndex);
    } else {
      setFocusId(emailList.findIndex((email) => email === ""));
    }
  };

  const handleDelete = (index: number) => {
    const newEmailList = emailList.filter((_, i) => i !== index);
    setEmailList(newEmailList);

    const newErrors = [...errors];
    newErrors.splice(index, 1);
    setErrors(newErrors);

    delete inputRefs.current[index];
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleAdd();
    }
  };

  const handleSave = async () => {
    setSubmitError("");
    await userInviteMutation.mutateAsync(undefined, {
      onSuccess: (data) => {
        const invalidEmails = data.invalid_emails;
        if (invalidEmails.length > 0) {
          setSubmitError(
            t("members.errors.invalidEmails", {
              emails: invalidEmails.join(", "),
            }) || ""
          );
        } else {
          setSubmitSuccess(t("members.success") || "");
          setSubmitError("");
        }
        onUserInviteUpdate();
        setEmailList([]);
      },
      onError: (error) => {
        const errorCode = (error as AxiosError).response
          ?.data as ErrorResponse<TeamErrorCodes>;
        if (errorCode.extra?.code === TeamErrorCodes.EMAIL_ALREADY_EXISTS) {
          setSubmitError(t("members.errors.emailExists") || "");
        } else {
          setSubmitError(t("members.errors.submit") || "");
        }
      },
    });
  };

  return (
    <div className="TeamMemberInviteForm">
      {emailList.map((email, index) => (
        <div
          className="TeamMemberInviteForm__inputContainer"
          key={`input-${index}`}
        >
          <Input
            ref={(el) => {
              if (el) {
                inputRefs.current[index] = el;
                if (focusId === index) {
                  el.focus();
                  setFocusId(null);
                }
              } else {
                delete inputRefs.current[index];
              }
            }}
            value={email}
            onChange={(e) => handleChange(e.target.value, index)}
            onKeyDown={handleKeyDown}
            onBlur={() => isEmailValid(email, index)}
            fullWidth
            helperText={errors[index]}
            error={!!errors[index]}
            placeholder={t("members.emailPlaceholder")}
          />
          <Button
            className="TeamMemberInviteForm__inputContainer__action"
            variant="contained"
            color="secondary"
            icon
            onClick={() => handleDelete(index)}
          >
            <TrashIcon />
          </Button>
        </div>
      ))}
      <Button
        variant="contained"
        color="secondary"
        fullWidth
        small
        onClick={handleAdd}
      >
        {t("members.actions.add")}
      </Button>

      <div className="TeamMemberInviteForm__buttonContainer">
        <Button
          variant="contained"
          color="info"
          onClick={handleSave}
          disabled={!canSaveEmails}
        >
          {t("members.actions.submit")}
        </Button>
        {submitError && (
          <Typography tagVariant="p" desktop="caption" color="error">
            {submitError}
          </Typography>
        )}
        <Snackbar
          open={!!submitSuccess}
          message={submitSuccess}
          onClose={() => setSubmitSuccess("")}
        />
      </div>
    </div>
  );
};
