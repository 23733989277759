import {
  Logout as LogoutIcon,
  Menu as MenuIcon,
  Settings as SettingsIcon,
} from "@mui/icons-material";
import {
  AppBar,
  Box,
  CircularProgress,
  Container,
  IconButton,
  Button as MUIButton,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import { MouseEvent, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import useTranslation from "../../hooks/useTranslation";
import { DiversityIllustration } from "../../styles/illustrations/DiversityIllustration";
import { RecruitmentIllustration } from "../../styles/illustrations/RecruitmentIllustration";
import { WorkplaceCultureIllustration } from "../../styles/illustrations/WorkplaceCultureIllustration";
import { grey4 } from "../../styles/theme";
import { SubscriptionActions } from "../../types/user";
import { ProtectedRoutes, PublicRoutes } from "../../utils/routes.utils";
import { LanguagePicker } from "../LanguagePicker/LanguagePicker";
import { Button } from "../ui/Button/Button";
import { Logo } from "../ui/Logo";
import { IMenuItem, Menu } from "../ui/Menu/Menu";
import { Direction, IconTypes } from "../ui/icons";
import { Icon } from "../ui/icons/Icon";
import { NavArrow } from "../ui/icons/NavArrow";
import "./Navigation.scss";

export const Navigation = () => {
  const { t } = useTranslation();
  const { authInfo, onLogout, isLoggedIn } = useAuth();
  const [loggingOut, setLoggingOut] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const activePath =
    location.pathname === "/" ? "/dashboard" : location.pathname;

  const [navAnchor, setNavAnchor] = useState<HTMLElement>();
  const [userAnchor, setUserAnchor] = useState<HTMLElement>();
  const [insightsAnchor, setInsightsAnchor] = useState<HTMLElement>();
  const [arrowDirection, setArrowDirection] = useState<Direction>(
    Direction.DOWN
  );

  const pagesMenuItems: IMenuItem[] = [
    {
      text: t("navigation.home"),
      onClick: () => handleCloseNavMenu(),
      selected: "/dashboard" === activePath,
      path: "/dashboard",
    },
    {
      id: "insights",
      icon: (
        <NavArrow
          direction={arrowDirection}
          color={grey4}
          size={16}
          className="Navigation__icon"
        />
      ),
      text: t("navigation.insights.title"),
      onClick: (e: MouseEvent<HTMLElement>) => handleOpenInsightsMenu(e),
      selected:
        activePath.includes(ProtectedRoutes.WORKPLACE_CULTURE) ||
        "/pay-equity" === activePath ||
        "/diversity-ratio" === activePath,
    },
    {
      text: t("navigation.training"),
      onClick: () => handleCloseNavMenu(),
      selected: "/training" === activePath,
      path: "/training",
    },
  ];

  const userMenuItems: IMenuItem[] = [
    {
      text: t("navigation.settings"),
      icon: <SettingsIcon color="secondary" />,
      onClick: () => handleCloseUserMenu(),
      selected: "/settings" === activePath,
      path: "/settings",
    },
    {
      text: loggingOut ? "" : t("navigation.logout"),
      icon: loggingOut ? (
        <CircularProgress size={20} color="secondary" />
      ) : (
        <LogoutIcon color="secondary" />
      ),
      onClick: (e: MouseEvent<HTMLLIElement>) => handleLogout(e),
    },
  ];

  const insightsMenuHeaderItems: IMenuItem[] = [
    {
      text: t("navigation.insights.workplaceCulture"),
      icon: <WorkplaceCultureIllustration />,
      onClick: () => handleCloseInsightsMenu(),
      path: ProtectedRoutes.WORKPLACE_CULTURE,
      restricted:
        !authInfo.user?.hasAccess(SubscriptionActions.LAUNCH_SURVEY) || false,
    },
    {
      text: t("navigation.insights.diversityRatio"),
      icon: <DiversityIllustration />,
      onClick: () => handleCloseInsightsMenu(),
      path: "/diversity-ratio",
      restricted:
        !authInfo.user?.hasAccess(SubscriptionActions.HR_INTEGRATION) || false,
    },
    {
      text: t("navigation.insights.pipeline"),
      icon: <RecruitmentIllustration />,
      onClick: () => handleCloseInsightsMenu(),
      path: "/pipeline",
      restricted:
        !authInfo.user?.hasAccess(SubscriptionActions.HR_INTEGRATION) || false,
    },
  ];

  const userMenuHeaderItems: IMenuItem[] = [
    {
      text: (
        <div>
          <span>{authInfo.user?.name}</span>
          <Typography color="secondary" sx={{ display: "inline" }}>
            {" • "}
            {authInfo.user?.getCompanyName()}
          </Typography>
        </div>
      ),
      icon: <Icon type={IconTypes.User} />,
    },
  ];

  const handleOpenNavMenu = (event: MouseEvent<HTMLElement>) => {
    setNavAnchor(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setNavAnchor(undefined);
  };

  const handleOpenUserMenu = (event: MouseEvent<HTMLElement>) => {
    setUserAnchor(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    // the setTimeout is a hack needed when a menu item is clicked and the
    // user is navigated to a different page for the first time. Then the render cycle
    // to remove the menu from the DOM isn't finished and the menu doesn't close (some bug in MUI Menu)
    setTimeout(() => setUserAnchor(undefined), 100);
  };

  const handleOpenInsightsMenu = (event: MouseEvent<HTMLElement>) => {
    setInsightsAnchor(event.currentTarget);
    setArrowDirection(Direction.UP);
  };

  const handleCloseInsightsMenu = () => {
    // the setTimeout is a hack needed when a menu item is clicked and the
    // user is navigated to a different page for the first time. Then the render cycle
    // to remove the menu from the DOM isn't finished and the menu doesn't close (some bug in MUI Menu)
    setTimeout(() => {
      setInsightsAnchor(undefined);
      setArrowDirection(Direction.DOWN);
    }, 100);
    handleCloseNavMenu();
  };

  const handleLogout = (e: MouseEvent<HTMLElement>) => {
    setLoggingOut(true);
    onLogout().finally(() => {
      setLoggingOut(false);
      handleCloseUserMenu();
      navigate(PublicRoutes.LOGIN);
    });
  };

  if (!isLoggedIn()) {
    return <></>;
  }

  const renderPagesXS = () => {
    let smallerScreenMenuItems: IMenuItem[] = [
      pagesMenuItems[0],
      ...insightsMenuHeaderItems,
      pagesMenuItems[2],
    ];

    smallerScreenMenuItems = smallerScreenMenuItems.map((item) => {
      return { ...item, icon: undefined };
    });

    return (
      <Box sx={{ display: { xs: "flex", md: "none" } }} className="MenuBox">
        <IconButton
          size="large"
          aria-label="available pages"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleOpenNavMenu}
          color="secondary"
        >
          <MenuIcon />
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={navAnchor}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: -12,
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          open={!!navAnchor}
          onClose={handleCloseNavMenu}
          sx={{ display: { xs: "block", md: "none" } }}
          menuItems={smallerScreenMenuItems}
        />
      </Box>
    );
  };

  const renderPagesMD = () => (
    <Box className="MenuBox" sx={{ display: { xs: "none", md: "flex" } }}>
      {pagesMenuItems.map((page, i) => (
        <span key={i}>
          <MUIButton
            key={i}
            onClick={(e: MouseEvent<HTMLButtonElement>) => page.onClick?.(e)}
            className={page.selected ? "MenuItem MenuItem--active" : "MenuItem"}
            color="secondary"
            href={page.path}
            disableRipple
          >
            <Typography variant="caption" fontWeight="bold">
              {page.text}
            </Typography>
            {page.icon && page.icon}
          </MUIButton>
          {page.id === "insights" && (
            <Menu
              className="Insights__menu"
              id="insights-menu-appbar"
              anchorEl={insightsAnchor}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              open={!!insightsAnchor}
              onClose={handleCloseInsightsMenu}
              headerItems={insightsMenuHeaderItems}
              menuItems={[]}
              skipDividers
            />
          )}
        </span>
      ))}
    </Box>
  );

  return (
    <AppBar position="static" color="inherit" className="Navigation">
      <Container maxWidth="xl" className="Navigation__container">
        <Toolbar disableGutters>
          <NavLink to="/">
            <Logo />
          </NavLink>
          {renderPagesXS()}
          {renderPagesMD()}
          <LanguagePicker className="Navigation__language" />
          <Box className="UserSettingsBox">
            <Tooltip title={t("navigation.openSettings")}>
              <>
                <Button
                  onClick={handleOpenUserMenu}
                  data-testid="menu-dropdown"
                  className="UserSettingsBox__button"
                  color="secondary"
                  variant="outlined"
                  aria-label="available user settings"
                  aria-controls="usermenu-appbar"
                  aria-haspopup="true"
                  small
                >
                  <Icon type={IconTypes.Hamburger} />
                  <Icon type={IconTypes.User} />
                </Button>
              </>
            </Tooltip>
            <Menu
              className="UserSettingsBox__menu"
              id="usermenu-appbar"
              anchorEl={userAnchor}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: 70,
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={!!userAnchor}
              onClose={handleCloseUserMenu}
              menuItems={userMenuItems}
              headerItems={userMenuHeaderItems}
            />
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
