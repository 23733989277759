import { LinkProps, createTheme } from "@mui/material";
import "@mui/material/styles/createPalette";
import React from "react";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from "react-router-dom";

declare module "@mui/material/styles" {
  interface TypographyVariants {
    handwrittenH1: React.CSSProperties;
    handwrittenH2: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    handwrittenH1?: React.CSSProperties;
    handwrittenH2?: React.CSSProperties;
  }

  interface Palette {
    borderGrey: Palette["primary"];
  }
  interface PaletteOptions {
    borderGrey?: PaletteOptions["primary"];
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    handwrittenH1: true;
    handwrittenH2: true;
  }
}

declare module "@mui/material/styles/createPalette" {
  interface CommonColors {
    borderGrey: string;
  }
}

declare module "@mui/material/CircularProgress" {
  interface CircularProgressPropsColorOverrides {
    borderGrey: true;
  }
}

const LinkBehavior = React.forwardRef<
  HTMLAnchorElement,
  Omit<RouterLinkProps, "to"> & { href: RouterLinkProps["to"] }
>((props, ref) => {
  const { href, ...other } = props;
  // Map href (MUI) -> to (react-router)
  return <RouterLink ref={ref} to={href} {...other} />;
});

export const pennBlueColor = "#111341";
export const eggplantColor = "#5a4a53";
export const dampCyanColor = "#9ab6bd";
export const vistaBlueColor = "#daf4f4";
export const orangeColor = "#f2ab50";
export const roseOrange = "#e37c5c";
export const keyLimeColor = "#e2ea5b";
export const darkVistaBlue = "#3f7fbe";
export const olivineColor = "#8cba80";
export const stoneBlue = "#5d768c";
export const darkStoneBlue = "#435869";
export const softBlue = "#eaf0f1";
export const grey4 = "#656f78";
export const grey2 = "#e5eaef";
export const grey1 = "#f5f5f5";
export const coalColor = "#1b1919";
export const extraSoftBlue = "#dbecfd";
export const extraSoftGreen = "#d9fecf";
export const extraSoftMint = "#e1fef6";
export const extraSoftPink = "#fae3eb";
export const extraSoftPurple = "#d9d4fc";
export const extraSoftYellow = "#fffda0";

export const primaryColor = stoneBlue;
export const secondaryColor = grey4;
export const errorColor = roseOrange;
export const infoColor = keyLimeColor;
export const successColor = keyLimeColor;
export const warningColor = orangeColor;

// Overwriting Material UI colors
export const theme = createTheme({
  palette: {
    primary: {
      main: primaryColor,
    },
    secondary: {
      main: secondaryColor,
    },
    error: {
      main: errorColor,
    },
    info: {
      main: infoColor,
    },
    success: {
      main: successColor,
    },
    warning: {
      main: warningColor,
    },
    common: {
      black: coalColor,
      borderGrey: grey2,
    },
    borderGrey: {
      main: "grey2",
    },
  },
  typography: {
    fontFamily: "studiofeixen",
    htmlFontSize: 18.4,
    h1: {
      fontFamily: "studiofeixen",
      fontWeight: 700,
      fontSize: "2rem",
    },
    h2: {
      fontFamily: "studiofeixen",
      fontWeight: 600,
      fontSize: "1.4rem", // 20px
    },
    h3: {
      fontFamily: "studiofeixen",
      fontWeight: 600,
      fontSize: "1.3rem",
    },
    h4: {
      fontFamily: "studiofeixen",
      fontWeight: 700,
      fontSize: "1rem", // 16px
    },
    h5: {
      fontFamily: "studiofeixen",
      fontWeight: 700,
      fontSize: "0.9rem", // 14px
    },
    h6: {
      fontWeight: 400,
      fontSize: "0.75rem", // 12px
    },
    handwrittenH1: {
      fontFamily: "Lore",
      fontSize: "2rem",
    },
    handwrittenH2: {
      fontFamily: "Lore",
      fontSize: "1.5rem",
    },
  },
  components: {
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      } as LinkProps,
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
    },
  },
});
