import { Skeleton } from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { SurveyService } from "../../api/SurveyService";
import useTranslation from "../../hooks/useTranslation";
import {
  GetJobRolesResponse,
  GetSurveyJobLevels,
  JobRole,
} from "../../types/survey";
import {
  addJobRole,
  deleteJobRole,
  getJobRolesNotInDispatch,
  getValidJobRoles,
  hasDuplicateJobRoles,
  hasJobRoleChanges,
  setJobRoleName,
  sortJobRolesByOrder,
  transformResponseToJobRoles,
} from "../../utils/jobRoles.utils";
import { Button } from "../ui/Button/Button";
import { Snackbar } from "../ui/Snackbar/Snackbar";
import { Typography } from "../ui/Typography/Typography";
import { SurveyLaunchJobRolesItem } from "./SurveyLaunchJobRolesItem";
import { SurveyLaunchJobRolesModal } from "./SurveyLaunchJobRolesModal";
import "./SurveyLaunchJobRoles.scss";

interface SurveyLaunchJobRolesProps {
  dispatchId?: string;
  dispatchJobRoles?: GetJobRolesResponse;
  error?: boolean;
  isLoading?: boolean;
  jobLevels?: GetSurveyJobLevels;
  refetch?: () => void;
}

export const SurveyLaunchJobRoles = ({
  dispatchId,
  dispatchJobRoles,
  error,
  isLoading,
  jobLevels,
  refetch,
}: SurveyLaunchJobRolesProps) => {
  const { t } = useTranslation("surveyLaunchPage");
  const surveyService = new SurveyService();
  const [jobRoles, setJobRoles] = useState<JobRole[]>([]);
  const [showTenantJobRoles, setShowTenantJobRoles] = useState(false);
  const [hasReusedTenantJobRoles, setHasReusedTenantJobRoles] = useState(false);
  const [hasMultipleDispatches, setHasMultipleDispatches] = useState(false);
  const [submitError, setSubmitError] = useState("");
  const [submitSuccess, setSubmitSuccess] = useState("");

  const canSaveJobRoles =
    !hasDuplicateJobRoles(jobRoles) &&
    hasJobRoleChanges(jobRoles, dispatchJobRoles) &&
    !isLoading;

  const {
    data: tenantJobRolesData,
    isLoading: tenantJobRolesLoading,
    error: tenantJobRolesError,
    refetch: refetchTenantJobRoles,
  } = useQuery<GetJobRolesResponse, AxiosError>(
    ["tenantJobRoles"],
    () => surveyService.getTenantJobRoles(),
    {
      retry: false,
    }
  );

  useEffect(() => {
    if (tenantJobRolesData) {
      const filteredTenantJobRoles = getJobRolesNotInDispatch(
        transformResponseToJobRoles(tenantJobRolesData),
        dispatchId
      );

      if (
        filteredTenantJobRoles.some((jr) =>
          jr.dispatchIds?.includes(dispatchId || "")
        )
      ) {
        setHasReusedTenantJobRoles(true);
      }

      if (
        filteredTenantJobRoles.some((jr) => jr.dispatchIds?.length || 0 >= 1)
      ) {
        setHasMultipleDispatches(true);
      }
    }
  }, [tenantJobRolesData, dispatchId, jobRoles]);

  useEffect(() => {
    if (dispatchJobRoles && dispatchJobRoles.length > 0) {
      const responseJobRoles = transformResponseToJobRoles(dispatchJobRoles);
      setJobRoles(sortJobRolesByOrder(responseJobRoles));
    }
  }, [dispatchJobRoles, isLoading]);

  const addOrUpdateJobRoles = async (roles: JobRole[]) => {
    const apiCalls = roles.map((role) => {
      if (role.id) {
        return surveyService.updateDispatchJobRole(dispatchId || "", {
          id: role.id,
          name: role.name,
          job_level: role.jobLevel,
          order: role.order,
        });
      } else {
        return surveyService.addDispatchJobRole(dispatchId || "", {
          name: role.name,
          job_level: role.jobLevel,
          order: role.order,
        });
      }
    });
    const results = await Promise.allSettled(apiCalls);
    const anyRejected = results.some((r) => r.status === "rejected");
    if (anyRejected) {
      throw new Error("Some job role updates failed.");
    }
    return results;
  };

  const addOrUpdateJobRolesMutation = useMutation<
    PromiseSettledResult<JobRole>[],
    AxiosError,
    JobRole[]
  >((jobRoles) => addOrUpdateJobRoles(jobRoles), {
    onSuccess: () => {
      refetch?.();
      setSubmitSuccess(
        t("jobRoles.success") || "Successfully saved job roles."
      );
      setSubmitError("");
    },
    onError: () => {
      setSubmitError(
        t("jobRoles.errors.submit") || "Failed to save job roles."
      );
    },
  });

  const deleteJobRoleMutation = useMutation({
    mutationFn: (jobRoleId: string) =>
      surveyService.deleteJobRole(dispatchId || "", jobRoleId),
    onSuccess: () => {
      refetch?.();
      refetchTenantJobRoles();
    },
    onError: () => {
      setSubmitError(
        t("jobRoles.errors.delete") || "Failed to delete job role."
      );
    },
  });

  const handleAddJobRoleClick = (jobLevel: string) => {
    setJobRoles((prev) => addJobRole(prev, jobLevel, jobLevels));
  };

  const handleDeleteOption = (jobRole: JobRole) => {
    if (jobRole.id) {
      deleteJobRoleMutation.mutateAsync(jobRole.id).then(() => {
        setJobRoles((prev) => deleteJobRole(prev, jobRole.id ?? ""));
      });
    } else {
      setJobRoles((prev) => deleteJobRole(prev, jobRole.tempId ?? ""));
    }
  };

  const handleChangeOption = (value: string, id: string) => {
    setJobRoles((prev) => setJobRoleName(prev, id, value));
  };

  const handleAddJobRole = async () => {
    setSubmitError("");
    setSubmitSuccess("");
    try {
      await addOrUpdateJobRolesMutation.mutateAsync(getValidJobRoles(jobRoles));
    } catch (err) {}
  };

  const handleAddTenantJobRoles = async (roles: JobRole[]) => {
    return addOrUpdateJobRolesMutation.mutateAsync(roles).then(() => {
      refetchTenantJobRoles();
    });
  };

  const buttonClasses = classNames("SurveyLaunchJobRoles__tenantJobRoles", {
    "SurveyLaunchJobRoles__tenantJobRoles--big":
      !hasReusedTenantJobRoles && !jobRoles.length,
  });

  return (
    <div className="SurveyLaunchJobRoles" data-testid="survey-launch-job-roles">
      <Typography
        className="SurveyLaunchJobRoles__subtitle"
        desktop="body1"
        data-testid="job-roles-subtitle"
      >
        {t("jobRoles.subtitle")}
      </Typography>

      {hasMultipleDispatches && (
        <div className={buttonClasses}>
          <Button
            data-testid="open-tenant-roles-btn"
            variant={hasReusedTenantJobRoles ? "outlined" : "contained"}
            onClick={() => setShowTenantJobRoles(true)}
            color={
              !hasReusedTenantJobRoles && !jobRoles.length ? "info" : "primary"
            }
            small={hasReusedTenantJobRoles || jobRoles.length > 0}
          >
            {t("jobRoles.openTenantJobRoles")}
          </Button>
        </div>
      )}

      {isLoading && (
        <Skeleton
          data-testid="job-roles-loading"
          variant="rounded"
          width="100%"
          height={100}
        />
      )}
      {error && (
        <Typography desktop="h3" data-testid="job-roles-error">
          {t("jobRoles.errors.noData")}
        </Typography>
      )}

      {!isLoading &&
        !error &&
        jobLevels?.map((jobLevel, index) => (
          <SurveyLaunchJobRolesItem
            key={index}
            jobRoles={jobRoles}
            jobLevel={jobLevel.job_level}
            jobLevelName={jobLevel.name}
            optionMappingIndex={index}
            onAddOption={handleAddJobRoleClick}
            onDeleteOption={handleDeleteOption}
            onChangeOption={handleChangeOption}
            deleteInProgress={deleteJobRoleMutation.isLoading}
          />
        ))}

      <div className="SurveyLaunchJobRoles__actions">
        <Button
          data-testid="save-button"
          disabled={!canSaveJobRoles || addOrUpdateJobRolesMutation.isLoading}
          variant="contained"
          onClick={handleAddJobRole}
        >
          {t("actions.save")}
        </Button>
        {hasDuplicateJobRoles(jobRoles) && (
          <Typography
            desktop="caption"
            color="error"
            data-testid="duplicate-roles-error"
          >
            {t("jobRoles.errors.duplicate")}
          </Typography>
        )}
      </div>

      {showTenantJobRoles && (
        <SurveyLaunchJobRolesModal
          open={showTenantJobRoles}
          onClose={() => setShowTenantJobRoles(false)}
          onSave={handleAddTenantJobRoles}
          tenantJobRoles={transformResponseToJobRoles(tenantJobRolesData)}
          dispatchJobRoles={jobRoles}
          dispatchId={dispatchId}
          isLoading={tenantJobRolesLoading}
          error={!!tenantJobRolesError}
          jobLevels={jobLevels}
        />
      )}

      <Snackbar
        open={!!submitError}
        message={submitError}
        onClose={() => {
          setSubmitError("");
          addOrUpdateJobRolesMutation.reset();
          deleteJobRoleMutation.reset();
        }}
        data-testid="submit-error-snackbar"
      />
      <Snackbar
        open={!!submitSuccess}
        message={submitSuccess}
        onClose={() => setSubmitSuccess("")}
        data-testid="submit-success-snackbar"
      />
    </div>
  );
};
