import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { SurveyService } from "../../api/SurveyService";
import useTranslation from "../../hooks/useTranslation";
import { ProtectedRoutes } from "../../utils/routes.utils";
import { Button, ButtonProps } from "../ui/Button/Button";
import { Modal } from "../ui/Modal/Modal";
import { Snackbar } from "../ui/Snackbar/Snackbar";
import { Typography } from "../ui/Typography/Typography";
import "./SurveyLaunchButton.scss";

interface SurveyLaunchButtonProps extends ButtonProps {
  canLaunchNewDispatch?: boolean;
}

export const SurveyLaunchButton = ({
  children,
  className,
  color,
  canLaunchNewDispatch = true,
}: SurveyLaunchButtonProps) => {
  const { t } = useTranslation("workplaceCulturePage");
  const navigate = useNavigate();
  const surveyService = new SurveyService();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const launchSurveyMutation = useMutation({
    mutationFn: () => surveyService.createDispatch(),
  });

  const handleLaunchDispatch = () => {
    launchSurveyMutation.mutateAsync(undefined, {
      onSuccess: (data) => {
        setIsModalOpen(false);
        navigate(`${ProtectedRoutes.LAUNCH}/${data.survey_dispatch.id}`);
      },
      onError: () => {
        setIsModalOpen(false);
      },
    });
  };

  if (!canLaunchNewDispatch) {
    return null;
  }

  return (
    <>
      <Button
        variant="contained"
        color={color}
        small
        onClick={() => setIsModalOpen(true)}
        className={className}
      >
        {children || t("dispatches.create")}
      </Button>
      <Modal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        className="SurveyLaunchButton__modal"
        title={
          <Typography desktop="h3">
            {t("dispatches.confirmation.msg")}
          </Typography>
        }
      >
        <Button
          variant="contained"
          color="primary"
          onClick={handleLaunchDispatch}
          disabled={launchSurveyMutation.isLoading}
        >
          {t("dispatches.confirmation.yes")}
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => setIsModalOpen(false)}
          disabled={launchSurveyMutation.isLoading}
        >
          {t("dispatches.confirmation.no")}
        </Button>
      </Modal>
      <Snackbar
        open={launchSurveyMutation.isError}
        onClose={() => launchSurveyMutation.reset()}
        message={t("dispatches.createError")}
      />
    </>
  );
};
