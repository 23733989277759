import { Skeleton } from "@mui/material";
import useTranslation from "../../hooks/useTranslation";
import { ICompany } from "../../types/company";
import { getFormattedDate } from "../../utils/formatters";
import { Card } from "../ui/Card/Card";
import { Typography } from "../ui/Typography/Typography";
import { IconTypes } from "../ui/icons";
import { Icon } from "../ui/icons/Icon";
import "./Company.scss";

interface CompanyProps {
  company?: ICompany;
  isLoading?: boolean;
  error?: boolean;
}

export const Company = ({
  company,
  isLoading = false,
  error = false,
}: CompanyProps) => {
  const { i18n, t } = useTranslation("settingsPage");

  const renderIcon = () => {
    if (isLoading) {
      return <Skeleton variant="rectangular" className="Company__icon" />;
    }
    if (!error) {
      return <Icon className="company__icon" type={IconTypes.Company} />;
    }
  };

  const subscriptions = company?.subscriptions
    .map((item) => item.charAt(0) + item.slice(1).toLowerCase())
    .join(", ");

  const isSubscriptionExpired =
    company?.subscriptionExpiresAt &&
    new Date(company.subscriptionExpiresAt) < new Date();

  return (
    <Card className="Company">
      {renderIcon()}
      {!isLoading && !error && (
        <div className="Company__info">
          <Typography
            tagVariant="p"
            desktop="body2"
            className="Company__info__name"
          >
            {company?.name}
          </Typography>

          <div className="Company__info--secondary">
            <Typography tagVariant="p" desktop="caption">
              {company?.industry} • {company?.numberOfEmployees}{" "}
              {t("company.employees")} • {subscriptions}{" "}
              {t("company.subscription", {
                count: company?.subscriptions.length,
              })}
              {company?.subscriptionExpiresAt &&
                !isSubscriptionExpired &&
                `• ${t("company.subscriptionExpires", {
                  date: getFormattedDate(
                    company.subscriptionExpiresAt,
                    t,
                    i18n.language
                  ),
                })}`}
              {company?.subscriptionExpiresAt &&
                isSubscriptionExpired &&
                ` • ${t("company.subscriptionExpired", {
                  date: getFormattedDate(
                    company.subscriptionExpiresAt,
                    t,
                    i18n.language
                  ),
                })}`}
            </Typography>
          </div>
        </div>
      )}
      {isLoading && (
        <div className="Company__info">
          <Skeleton variant="text" className="Company__info__name" />
          <Skeleton variant="text" className="Company__info--secondary" />
        </div>
      )}
      {error && (
        <Typography tagVariant="p" desktop="body1" weight="bold">
          {t(`company.error`)}
        </Typography>
      )}
    </Card>
  );
};
